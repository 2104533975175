<template>

    <div>

        <div class="">
            <div class="panel">
                <div class="header">
                        {{__("current month sales chart")}}
                </div>
                <div class="panel_content">
                        <highcharts :options="chartOptions" v-if="render"></highcharts>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import myMixin from "@/myMixin";
import {Chart} from 'highcharts-vue'
export default {
name: "MonthSalesStatistics",
    data(){
        const app=this;
        return {
            render:false,
            showLoading: false,
            account_status: 'active',
            BreadCrumbList: [],
            server_error: false,
            show_alert_message: false,
            alert_message: '',
            default_year: '',
            chartOptions: {
                series: [{
                    name: '',
                    data: [],
                    color: 'red'
                }, {
                    name: '',
                    data: [],
                    marker: {
                        symbol: 'circle'
                    }
                }],
                legend: {
                    verticalAlign: 'top',
                    y: 0
                },
                title: {
                    text: '',
                    update: true
                },
                subtitle: {
                    text: '',

                },
                chart: {
                    type: 'line',
                    style: {
                        fontFamily: 'iranyekanR'
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    labels: {
                        useHtml: true,
                        formatter: function () {
                            let value = this.value;
                            if (value > 1000) {
                                value = app.priceFormat({}, value);
                            }else
                                value = app.priceFormat({}, value);
                            return '<div style="direction: ltr"><span>' + value + '</span></div>';
                        },
                        style: {
                            fontSize: '15px'
                        }
                    }
                },
                xAxis: {
                    categories: [],
                    step:5
                },
                tooltip: {
                    formatter: function () {
                        if (this.series.name == app.__("sales amount")) {
                            return this.x + '<br/><div style="padding:5px;padding-bottom:5px">' + (this.series.name) + ' : ' + app.priceFormat({}, this.y) + "</div>";
                        } else {
                            return this.x + '<br/><div style="padding:5px;padding-bottom:5px">' + (this.series.name) + ' : ' + this.y + "</div>";
                        }
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth:300
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                }
            },
        }
    },
    components:{
        highcharts:Chart
    },
    mixins:[myMixin],
    mounted() {
        this.getData();
        this.account_status=localStorage.getItem('account_status')
    },
    methods:{
        getData(){
            const url=this.host+'api/orders/get_month_sales_statistics';
            const app=this;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    app.render=false;
                    this.chartOptions['series'][0]['data']=[];
                    this.chartOptions['series'][1]['data']=[];
                    let price=response.data.price_array;
                    let count=response.data.count_array;
                    price.forEach(function (row){
                        app.chartOptions['series'][0]['data'].push(row);
                    })
                    count.forEach(function (row){
                        app.chartOptions['series'][1]['data'].push(row);
                    })
                    app.chartOptions.series[0].name=app.__("sales amount")
                    app.chartOptions.series[1].name=app.__("number of transactions")
                    this.chartOptions['xAxis']['categories']=response.data.date_list;
                    app.$nextTick(() => {
                        // Add the component back in
                        app.render = true;
                    });

                }
                    this.$emit('show_account_status')
            }).catch(err=>{
                app.showLoading=false;
                app.setCatch(err);
                app.show_server_error();
            })
        }
    }
}
</script>

<style scoped>

</style>
