<template>
    <div class="alert alert-warning" v-if="account_status!='active'">
        <span v-if="account_status=='inactive'">{{__("your account disable")}}</span>
        <span v-else-if="account_status=='awaiting_approval'">{{__("account pending")}}</span>
        <span v-else>{{__("account error")}}</span>
    </div>
</template>

<script>
export default {
    name: "AccountStatus",
    props:['account_status']
}
</script>

<style scoped>

</style>
