import { render, staticRenderFns } from "./MessageList.vue?vue&type=template&id=48cabf12&scoped=true&"
import script from "./MessageList.vue?vue&type=script&lang=js&"
export * from "./MessageList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48cabf12",
  null
  
)

export default component.exports