<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>
        <div class="panel">
            <div class="header">
                {{__('edit :name',{'name':this.__('profile')})}}
            </div>
            <div class="panel_content">

                    <div class="form-group my-1">
                        <label>{{__("name")}} :</label>
                        <input type="text" v-model="fname" :disabled="account_status=='reject'?false:true" class="form-control">
                        <span class="has_error" v-if="fname_error">{{fname_error}}</span>
                    </div>

                    <div class="form-group my-1">
                        <label>{{__("family")}} :</label>
                        <input type="text" v-model="lname" :disabled="account_status=='reject'?false:true" class="form-control">
                        <span class="has_error" v-if="lname_error">{{lname_error}}</span>
                    </div>

                    <div class="form-group my-1">
                        <label>{{__(":name name",{name:__('shop')})}} :</label>
                        <input type="text" v-model="brand_name" :disabled="account_status=='reject'?false:true" class="form-control">
                        <span class="has_error" v-if="brand_name_error">{{brand_name_error}}</span>
                    </div>

                    <div class="form-group my-1">
                        <label>{{__("mobile")}} :</label>
                        <input type="text" v-model="mobile" class="form-control">
                        <span class="has_error" v-if="mobile_error">{{mobile_error}}</span>
                    </div>

                    <div class="form-group my-1">
                        <label>{{__("country")}}</label>
                        <v-select class="form-control none_child_border" v-model="country" label="name" :options="countries" index="id" ></v-select>
                        <span class="has_error" v-if="country_id_error">{{country_id_error}}</span>
                    </div>

                    <div class="form-group my-1">
                        <label>{{__("city")}}</label>
                        <v-select class="form-control none_child_border" v-model="city" label="name" :options="getCities" index="id" >
                            <span class="has_error" v-if="city_id_error">{{city_id_error}}</span>

                        </v-select>
                    </div>

                <div class="form-group my-1">
                    <label>{{__("town")}} :</label>
                    <input type="text" v-model="town" class="form-control">
                    <span class="has_error" v-if="town_error">{{town_error}}</span>
                </div>

                <div class="form-group my-1">
                    <label>{{__("zip code")}} :</label>
                    <input type="text" v-model="postal_code" class="form-control">
                    <span class="has_error" v-if="postal_code_error">{{postal_code_error}}</span>
                </div>

                <div class="form-group my-1 align-items-start d-lg-flex my-1">
                    <label>{{__("address")}} :</label>
                    <textarea type="text" rows="5" v-model="address"  class="form-control h-auto"> </textarea>
                    <span class="has_error" v-if="address_error">{{address_error}}</span>
                </div>

                <div class="form-group my-1">
                    <label>{{__("income tax code")}} :</label>
                    <input type="text" v-model="tax_code"  class="form-control">
                    <span class="has_error" v-if="tax_code_error">{{tax_code_error}}</span>
                </div>
                <div class="form-group my-1">
                    <label>{{__("description")}} :</label>
                    <textarea type="text" rows="5" v-model="description"  class="form-control h-auto"></textarea>
                    <span class="has_error" v-if="description_error">{{description_error}}</span>
                </div>
                <div class="form-group col-sm-12 col-md-8 clo-lg-4 my-2 align-items-start d-lg-flex" >
                    <label class="mt-xl-1" for="">{{__("logo")}}:</label>
                    <input class="d-none" type="file" ref="file_input3" v-on:change="set_file(3)">
                    <div class="select_file_div text-center justify-content-center d-inline-block" style="max-width: 300px" v-on:click="$refs.file_input3.click()">
                        <img :src="logoPath" class="img-thumbnail mt-1" style="max-width:150px">
                        <div class="w-100">
                            <div class="title w-100">{{__("choose :name",{'name':__('logo')})}}</div>
                            <div class="file_name w-100 text-truncate">{{this.file3_name}}</div>
                        </div>
                    </div>
                </div>

                <div v-if="account_status=='reject'">

                    <div class="form-group col-sm-12 col-md-8 clo-lg-4 my-2" v-if="account_type==1">
                        <label for=""></label>
                        <input class="d-none" type="file" ref="file_input1" v-on:change="set_file(1)">
                        <div class="select_file_div text-center justify-content-center d-inline-block" style="max-width: 300px" v-on:click="$refs.file_input1.click()">
                            <img :src="cartPath" class="imgSellerCart img-thumbnail" style="max-width:150px">
                            <div class="w-100">
                                <div class="title w-100">{{__("choose :name",{'name':__('scan :field',{'field':__('national cart')})})}}</div>
                                <div class="file_name w-100 text-truncate">{{this.file1_name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-12 col-md-8 clo-lg-4 my-2" v-if="account_type==1">
                        <label for=""></label>
                        <input class="d-none" type="file" ref="file_input2" v-on:change="set_file(2)">
                        <div class="select_file_div text-center justify-content-center d-inline-block" style="max-width: 300px" v-on:click="$refs.file_input2.click()">
                            <img :src="backCartPath" class="imgSellerBackCart img-thumbnail" style="max-width:150px">
                            <div class="w-100">
                                <div class="title w-100">{{__("choose :name",{'name':__('scan :field',{'field':__('back national cart')})})}}</div>
                                <div class="file_name w-100 text-truncate">{{this.file2_name}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-sm-12 col-md-8 clo-lg-4 my-2" v-if="account_type==2">
                        <label for=""></label>
                        <input class="d-none" type="file" ref="file_input1" v-on:change="set_file(1)">
                        <div class="select_file_div text-center justify-content-center d-inline-block" style="max-width: 300px" v-on:click="$refs.file_input1.click()">
                            <img :src="cartPath" class="imgSellerCart img-thumbnail" style="max-width:150px">
                            <div class="w-100">
                                <div class="title w-100">{{__("choose :name",{'name':__('scan :field',{'field':__('company registration certificate')})})}}</div>
                                <div class="file_name w-100 text-truncate">{{this.file1_name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group my-1 d-flex">
                        <label class="px-1">{{__("register type")}}</label>

                        <div class="d-flex w-auto">
                            <input value="1" id="personal"  v-model="account_type" class="form-check-input" type="radio" name="account_type">
                            <label for="personal" class="form-check-label register_label" >{{__("personal")}}</label>
                        </div>
                        <div class="d-flex w-auto">
                            <input value="2" id="commerce" v-model="account_type" class="form-check-input" type="radio" name="account_type">
                            <label for="commerce" class="form-check-label register_label" >{{__("commerce")}}</label>
                        </div>
                    </div>

                </div>

                <div v-else class="my-2">
                    <img v-if="seller.doc&&seller.doc.cart!=''" class="imgSellerCart img-thumbnail rounded img-fluid mx-2" style="max-width:150px;max-height:120px" >
                    <img v-if="seller.doc&&seller.doc.back_cart!=''" class="imgSellerBackCart img-thumbnail rounded img-fluid mx-2" style="max-width:150px;max-height:120px" >
                </div>

                <div class="alert alert-danger my-3" v-if="errors.length>0">
                    <p class="" v-for="(error,key) in errors" :key="key">{{key+1}}- {{error}} </p>
                </div>

                    <button class="btn btn-success" v-on:click="send_data()">{{__("submit info")}}</button>
            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>

    </div>
</template>

<script>
import myMixin from "@/myMixin";

export default {
name: "Profile",
    mixins:[myMixin],
    data(){
        return{
            showLoading:false,
            account_status:'active',
            server_error:false,
            BreadCrumbList:[],
            brand_name:'',
            cartPath:'',
            backCartPath:'',
            fname:'',
            lname:'',
            countries:[],
            country:{name:'',id:''},
            country_id:'',
            fname_error:false,
            lname_error:false,
            brand_name_error:false,
            mobile:'',
            mobile_error:false,
            city:{name:'',id:''},
            cities:[],
            city_id:'',
            seller_error:'',
            account_type:1,
            file1_name:null,
            file2_name:null,
            file3_name:null,
            country_id_error:false,
            city_id_error:false,
            file1:'',
            file2:'',
            file3:'',
            errors:[],
            seller:{},
            tax_code:'',
            tax_code_error:false,
            address:'',
            address_error:false,
            town:'',
            town_error:false,
            postal_code:'',
            postal_code_error:false,
            description:'',
            description_error:false,
            logoPath:'',
            logo_error:'',

        }
    },mounted() {
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/setting/profile',title:this.__('manage :name',{'name':this.__('profile')})},
        ];
        this.account_status = localStorage.getItem('account_status');
        this.$emit('setSideBarItem','setting');
        this.getData();
        }
    ,methods:{
        getData(){
            const app=this;
            this.showLoading=true;
            const url=this.host+"api/seller/profile";
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                    this.seller=response.data.seller;
                    this.brand_name=this.seller.brand_name
                    this.fname=this.seller.fname
                    this.lname=this.seller.lname
                    this.mobile=this.seller.mobile
                    this.country_id=this.seller.country_id
                    this.city_id=this.seller.city_id;
                    this.seller_error=this.seller.error;
                    this.tax_code=this.seller.tax_code
                    this.countries=response.data.countries;
                    this.cities=response.data.cities;
                    this.account_status=this.seller.account_status;
                    this.account_type=this.seller.account_type;
                    this.cartPath=this.seller.doc.cartPath;
                    this.backCartPath=this.seller.doc.backCartPath;
                    this.address=this.seller.address;
                    this.postal_code=this.seller.postal_code;
                    this.town=this.seller.town;
                    this.description=this.seller.description;
                    this.logoPath=this.seller.logoPath;

                this.getCart(this.seller.doc.cart,'imgSellerCart')
                this.getCart(this.seller.doc.back_cart,'imgSellerBackCart');

            }).catch(err=>{
                app.setCatch(err)
            })
        },
        set_file(n){
            const name="file"+n;
            const file_input_name="file_input"+n;
            this[name]=this.$refs[file_input_name].files[0];
            this["file"+n+"_name"]=this[name].name;
            if(n==1)
            this.cartPath=window.URL.createObjectURL(this[name]);
            else if(n==2)
            this.backCartPath=window.URL.createObjectURL(this[name]);
            else if(n==3)
            this.logoPath=window.URL.createObjectURL(this[name]);

        },
        send_data(){
            const app=this;
            let check_fname=true,check_lname=true,check_brand_name=true;
            if(this.account_status=='reject'){
                check_fname=this.validate_field(this.__('first_name'),this.fname,'fname_error',3);
                check_lname=this.validate_field(this.__('last_name'),this.lname,'lname_error',3);
                check_brand_name=this.validate_field(this.__(':name name',{'name':this.__('brand')}),this.brand_name,'brand_name_error',3);
            }
            const check_mobile=this.validate_field(this.__('mobile'),this.mobile,'mobile_error',9);
            const check_country=this.validate_field(this.__('country'),this.country_id,'country_id_error',1);
            const check_city=this.validate_field(this.__('city'),this.city_id,'city_id_error',1);
            const check_tax_code=this.validate_field(this.__('tax code'),this.tax_code,'tax_code_error',3);
            const check_town=this.validate_field(this.__('town'),this.town,'town_error',2);
            const check_address=this.validate_field(this.__('address'),this.address,'address_error',5);
            const check_postal_code=this.validate_field(this.__('zip code'),this.postal_code,'postal_code_error',3);

            let hasFile1=this.has_file('file1',this.account_type==1?'national cart':'company registration certificate');
            if(hasFile1==false&&(this.seller.doc.cart+"").length>3)
                hasFile1=true;

            let hasFile2=this.account_type==1?this.has_file('file2','back national cart'):true;
            if(this.account_type==1&&hasFile2==false&&(this.seller.doc.back_cart+"").length>3)
                hasFile2=true;

            if(check_brand_name && check_fname && check_lname &&check_country && check_city && check_mobile&&check_tax_code&&hasFile1&&hasFile2&&check_town &&check_address &&check_postal_code ) {
               this.errors=[];
               this.showLoading=true;
               const formData=new FormData();
                if(this.account_status=='reject'){
                    formData.append('fname',this.fname)
                    formData.append('lname',this.lname)
                    formData.append('brand_name',this.brand_name)
                }
                formData.append('mobile',this.mobile.replace("+",'00'))
                formData.append('city_id',this.city_id)
                formData.append('country_id',this.country_id)
                formData.append('account_type',this.account_type)
                formData.append('tax_code',this.tax_code)
                formData.append('address',this.address)
                formData.append('postal_code',this.postal_code)
                formData.append('town',this.town)
                formData.append('description',this.description)
                if(this.file1_name!=null)
                    formData.append('cart',this.file1)
                if(this.file2_name!=null)
                    formData.append('back_cart',this.file2)
                if(this.file3_name!=null)
                    formData.append('logo',this.file3)
                const url=this.host+"api/seller/profile/edit"
                this.axios.post(url,formData,this.setHeader(true)).then(response=>{
                    this.showLoading=false;
                    if(response.data.status=="error"){
                       const errors=response.data.errors;
                        const keys=Object.keys(errors);
                        for (let i = 0; i < keys.length; i++) {
                            this.errors.push(errors[keys[i]][0])
                        }
                    }else{
                        this.account_status=response.data.account_status;
                        localStorage.setItem('account_status', response.data.account_status);
                        localStorage.setItem('brand_name', response.data.brand_name);
                        this.brand_name = response.data.brand_name;
                        this.$emit('updateInfo');
                        window.location.reload();
                    }
                }).catch(err=>{
                    app.showLoading=false;
                    app.setCatch(err)
                })

            }
        },
        has_file(input,field_name){
        if(this.account_status!=='reject')
            return false;
        if(this[input].type==undefined){
            this.errors.push(this.__('v.required',{attribute:this.__(field_name)}))
            return false;
        }else if(!(this[input].type+"").includes("image")){
            this.errors.push(this.__('v.image',{attribute:this.__(field_name)}))
            return false;
        }
        if(this[input].size==undefined||this[input].size>655360){
            this.errors.push(this.__('v.lte.file.mg',{attribute:this.__(field_name),value:5}))
            return false;
        }
        return true;
    },
        getCart(fileName,id){
            const app=this;
            const url=this.host+"api/getSellerDocument/"+fileName;
            this.axios.get(url,this.setHeader()).then(response=>{
                /* global $ */
                $('.'+id).attr('src',response.data);
            }).catch(err=>{
                app.setCatch(err)
            })
        }
    },
    watch:{
        countries(val){
            this.country=this.findNestedObj(val,"id",this.country_id);
        },
        country(val){
            this.country_id=val.id;
            this.city={name:'',id:''}
        },
        cities(val){
            this.city=this.findNestedObj(val,"id",this.city_id);
        },city(val){
            this.city_id=val.id
        },
    },
    computed:{
        getCities(){
            const app=this;
            let obj= this.cities.filter(function (item){
               if(item.country_id!=undefined){
                   if(item.country_id==app.country_id)
                       return item;
               }
            });
            return obj;
        }
    }
}
</script>

<style scoped>

</style>
