<template>
    <div>
        <loading v-if="showLoading"></loading>
        <account-status :account_status="account_status"></account-status>


        <MonthSalesStatistics @show_account_status="show_account_status"></MonthSalesStatistics>

        <div class="panel">
            <div class="header">
                {{__("last :name",{'name':__('orders')})}}
            </div>
            <div class="panel_content">
                <order-table :orderList="orderList" :page="page" :perPage="perPage"></order-table>
            </div>
        </div>

    </div>
</template>

<script>
import MonthSalesStatistics from "@/components/MonthSalesStatistics";
import OrderTable from "@/components/OrderTable";
import myMixin from "@/myMixin";
export default {
name: "Index",
    mixins:[myMixin],
    data(){
    return{
        account_status:'active',
        showLoading:false,
        page:1,
        perPage:10,
        orderList:{data:[]}
    }
    },
    mounted() {
        this.account_status=localStorage.getItem('account_status')
        this.getOrders(1);
    },
    components:{
    OrderTable,
    MonthSalesStatistics
    },
    methods:{
        show_account_status(){
            this.account_status=localStorage.getItem('account_status')
        },
        getOrders(page=1){
            const app=this;
            this.showLoading=true;
            this.page=page;
            const url=this.host+"api/seller/orders?page="+page;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    this.orderList=response.data;
                    this.perPage=response.data.per_page
                }
            }).catch(err=>{
                app.showLoading=false;
                app.setCatch(err);
            })

        },
    }
}
</script>

<style scoped>

</style>
