<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>

        <div class="panel">
            <div class="header">
                {{__('manage :name',{'name':__('orders')})}}
            </div>

            <div class="panel_content">
                <div v-if="account_status=='active'" class="row">
                    <div class="panel_content">

                        <div  class="row search_form form-inline col-md-8 col-lg-5 col-sm-12 col-12" >
                            <h6>{{__('search')}}</h6>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" :placeholder="__('recipient name')" v-model="search_title" >
                                <input type="datetime-local" class="form-control" v-model="date" >
                                <button class="btn btn-primary rounded-0" type="button" id="button-addon1" v-on:click="getOrders(1)">{{__("search")}}</button>
                            </div>
                        </div>

                        <order-table :orderList="orderList" :page="page" :perPage="perPage" noneBg=""></order-table>

                        <pagination :data="orderList" @pagination-change-page="getOrders"></pagination>

                    </div>

                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{ __("403 text") }}
                    </p>
                </div>
            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
    </div>
</template>

<script>
import myMixin from "@/myMixin";


import 'bootstrap/dist/js/bootstrap.min';
import OrderTable from "@/components/OrderTable";
export default {
    name: "Orders",
    components: {OrderTable},
    data(){
        return{
            account_status:'active',
            showLoading:false,
            BreadCrumbList:[],
            server_error:false,
            orderList:{data:[]},
            page:1,
            perPage:10,
            search_title:'',
            date:'',
            noneBg:''

        }
    },
    mixins:[myMixin],
    mounted() {
        this.account_status = localStorage.getItem('account_status');
        this.$emit('setSideBarItem','orders');
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/orders',title:this.__('manage :name',{'name':this.__('orders')})},
        ];
        this.getOrders(1);
    },
    methods:{
        getOrders(page=1){
            const app=this;
            this.showLoading=true;
            this.page=page;
            const url=this.host+"api/seller/orders?page="+page+"&title="+this.search_title+"&date="+this.date;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    this.orderList=response.data;
                    this.perPage=response.data.per_page
                }
            }).catch(err=>{
                app.showLoading=false;
                app.setCatch(err);
            })

        },

    }
}
</script>

<style scoped>

</style>
