<template>
    <div>
        <div class="breadcrumb">

            <ul class="list-inline">
                <li>
                    <router-link class="text-decoration-none" to="/">
                        <i class="fal fa-dashboard mx-1"></i>
                        <span>{{__("dashboard")}}</span>
                        <span class="fal fa-angle-left mx-1" v-if="data.length>1"></span>
                    </router-link>
                </li>
                <li v-for="(item,key) in data" :key="key">
                    <router-link :to="item.path">
                        <span>{{item.title}}</span>
                        <i class="fal fa-angle-left" v-if="key!=(data.length-1)"></i>

                    </router-link>
                </li>
                <li class="date_li">
                    <i class="fal fa-calendar"></i>
                    <span class="mx-1">{{__("date")}}</span>
                    <span class="mx-1"><span>{{day}}</span>/<span>{{month}}</span>/<span>{{year}}</span></span>
                </li>
            </ul>

        </div>
    </div>
</template>

<script>
export default {
name: "Breadcrumb",
    props:['data'],
    data(){
    return{
        day:'',
        month:'',
        year:''
    }
    },
    mounted() {
    this.set_data();
    },
    methods:{
        set_data(){
            const date=new Date();
            this.day=date.getDay();
            this.month=date.getMonth();
            this.year=date.getFullYear();
        }
    }
}
</script>

<style scoped>

</style>
