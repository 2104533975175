<template>
   <div class="container-fluid">
       <div class="row">
           <div class="col-md-4 col-12 right_box login_right_box">

               <h5>
                   <i class="fal fa-store fa-2x" style="position:relative;margin:auto 5px;top:5px;" />
                   {{__("login to :name",{'name':__('seller panel')})}}
               </h5>
           </div>
           <div class="col-md-8  col-12">



               <div class="register_form_box px-1">
                   <h6 class="text-center iranyekanB py-2 border-bottom">
                       <i class="fal fa-lock-keyhole"></i>
                       {{__("login to :name",{'name':__('seller panel')})}}
                   </h6>
                   <div class="alert alert-success" v-if="$route.params.msg!=undefined">
                       <p class="p-0 m-0">
                           <i class="fal fa-check"></i>
                           <small v-html="$route.params.msg"> </small>
                       </p>
                   </div>
                   <div class="first_step">
                       <div class="form-group d-flex justify-content-center text-center">
                           <div>
                           <div class="input-group mt-3">
                               <input type="text" v-model="email" :class="email_error?'form-control border-danger rounded-0 to-left':'form-control rounded-0 to-left'" :placeholder="__('enter :field',{'field':__('email')})">
                               <div class="input-group-prepend">
                                   <div :class="email_error?'input-group-text border-danger':'input-group-text'">
                                       <i class="fal fa-envelope"></i>
                                   </div>
                               </div>
                           </div>
                           <p v-if="email_error" class="has_error flex-column">{{email_error}}</p>
                       </div>
                       </div>
                       <div class="form-group d-flex justify-content-center text-center">
                           <div>
                           <div class="input-group">
                               <input type="password" v-model="password" :class="password_error?'form-control border-danger rounded-0 to-left':'form-control rounded-0 to-left'" :placeholder="__('enter :field',{'field':__('password')})">
                               <div class="input-group-prepend">
                                   <div :class="password_error?'input-group-text border-danger':'input-group-text'">
                                       <i class="fal fa-lock"></i>
                                   </div>
                               </div>
                           </div>
                           <p v-if="password_error" class="has_error">{{password_error}}</p>
                           </div>
                       </div>

                       <div class="form-group d-flex justify-content-center">

                           <button type="submit" class="send_btn register_btn"  v-on:click="validate_login_form()">
                                <span class="line">
                                    <i class="fal fa-lock"></i>
                                </span>
                               <span class="title">{{__("login")}}</span>
                           </button>

                       </div>

                       <p class="mt-1 text-center">

                           <router-link :to="'/'+this.locale+'/register'" class="btn btn-outline-primary">
                               <span>{{__('your new user')}}</span>
                               {{__("register")}}
                           </router-link>

                       </p>

                       <div class="alert alert-warning " id="spinner" v-if="send_form">
                           <div class="spinner-border text-warning" role="status">
                               <span class="sr-only">Loading...</span>
                           </div>
                           <span style="padding-right:10px">{{__("sending information")}}</span>
                       </div>


                   </div>


                   <div class="alert-danger alert mt-3" v-if="server_error">
                       <i class="fal fa-warning"></i>
                       {{error_message}}
                   </div>

               </div>

           </div>
       </div>
   </div>
</template>

<script>
import myMixin from "@/myMixin";
export default {
    name: "Login",
    mixins:[myMixin],
    data(){
      return{
          email:'',
          password:'',
          email_error:'',
          password_error:'',
          server_error:'',
          error_message:'',
          send_form:false,
      }
    },
    mounted() {
    },
    methods:{
        login:function (){
            const app=this;
            this.server_error=false;
            if(this.send_form)
                return ;
            this.send_form=true;
            const url=this.host+'oauth/token';
            const formData=new FormData();
            formData.append('grant_type','password');
            formData.append('client_id','93ae5c45-2e0b-42a9-88aa-dfe6aa246a2f');
            formData.append('client_secret','Xs0PUFJp3XOYD4tSdF23ITd4ImIs8LDKv3hPB0HI');
            formData.append('username',this.email);
            formData.append('password',this.password);
            formData.append('scope','');
            formData.append('provider','sellers');
            this.axios.post(url,formData).then(response=>{
                this.send_form=false;
                if(response.data.access_token){
                    const expire_in=Math.floor((new Date().getTime()/1000))+response.data.expires_in;
                    localStorage.setItem('expire_in',expire_in)
                    localStorage.setItem('token',response.data.access_token)
                    this.$router.push({name:'index',params:{getUserData:'yes',locale:this.locale}});
                }
            }).catch(function (err){
                app.send_form=false;
                app.server_error=true;
                const status=err.response.status;
                if(status==400){
                    app.error_message=app.__('v.failed');
                }else{
                    app.error_message=app.__('error to send')+" "+app.__('try again');
                }

            });
        },
        validate_login_form(){
            if(this.send_form)
                return;
            if(!this.validate_email(this.email)|| !this.validate_password(this.password)){
                return ;
            }
            this.login();
        }
    }
}
</script>

