<template>
    <div>
        <table class="table table-bordered table-striped" >
            <thead>
            <tr>
                <th>#</th>
                <th class="text-center"><input type="checkbox" name="checkAll" id="checkAll" @change="checkAll($event)" ></th>
                <th v-if="!$parent.hasMobile">{{__("image")}}</th> <th v-if="$parent.hasMobile">{{__("product")}}</th>
                <th v-if="!$parent.hasMobile">{{__("product")}}</th>
                <th v-if="!$parent.hasMobile">{{__("price")}}</th>
                <th v-if="!$parent.hasMobile">{{__("status")}}</th>
                <th>{{__("operation")}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(orderProduct,key) in order.get_order_product" :key="orderProduct.id">
                <td class="text-center"> {{key+1}} </td>
                <td class="text-center"><input type="checkbox" class="CBOP" @change="addCheckIds(orderProduct.id,$event)"></td>
                <td class="text-center">
                    <img :src="orderProduct.product['imgPath']" class="product_pic" style="width: 80px;margin: 10px"/>
                    <div class="max-1line text-center" v-if="$parent.hasMobile">
                        {{_(orderProduct.product.title)}}
                    </div>
                    <div class="more-1line" v-if="$parent.hasMobile" :data-more="__('more')" :data-less="__('read less')">
                       <i class="fal fa-chevron-circle-up"></i> <span>{{__("more")}}</span>
                    </div>

                </td>
                <td class="text-center" v-if="!$parent.hasMobile" >
                    {{_(orderProduct.product.title)}}
                </td >
                <td v-if="!$parent.hasMobile" class="text-center" style="min-width: 150px">
                    {{priceFormat({},orderProduct.product_price2)}}
                </td >
                <td  class="text-center" v-if="!$parent.hasMobile" style="min-width: 190px">
                    <span :class="(orderProduct.send_status<1?'btn-danger p-2 rounded':(orderProduct.send_status==6?'btn-success2 p-2 rounded':'btn-primary p-2 rounded'))">{{getStatus(orderProduct.send_status)}}</span>
                </td>

                <td class="text-center" :style="[orderProduct.seller_read=='no'?{color:'red'}:{color:'black'}]" :data="orderProduct.seller_read">
                    <span class="text-dark" v-if="$parent.hasMobile">{{getStatus(orderProduct.send_status)}}</span>
                    <div class="dropdown w-auto text-center ">
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" :id="'dropdownMenuButton'+key" data-bs-toggle="dropdown" aria-expanded="false" >
                            {{__('options')}}
                        </button>

                        <ul class="dropdown-menu w-auto dropdown-menu-end min-w-auto" :aria-labelledby="'dropdownMenuButton'+key">
                            <li class="pointer" v-on:click="show_order_info(key)">
                                <span class="dropdown-item px-2" >
                                   <i class="fal fa-eye"></i>
                                    {{__('order details')}}
                                </span>
                            </li>
                            <li class="pointer " v-on:click="shipment_modal(key)">
                                <span class="dropdown-item px-2" >
                                   <i class="fal fa-conveyor-belt"></i>
                                    {{__('add :field',{field:__('packet')})}}
                                </span>
                            </li>
                            <li class="pointer " v-on:click="factor_modal(key)">
                                <span class="dropdown-item px-2" >
                                   <i class="fal fa-list-alt"></i>
                                    {{__('factors')}}
                                </span>
                        </li>
                        </ul>

                    </div>

                </td>
            </tr>
            </tbody>
        </table>
        <tr v-if="order.length==0" class="d-block w-100">
            <p class="alert alert-info"><i class="fa fa-exclamation-triangle mx-2"></i>{{__("There is no record to display")}}</p>
        </tr>

        <div class="modal" tabindex="-1" id="orderInfo">
            <div class="modal-dialog modal-lg shadow-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{__("order details")}}</h5>
                        <button v-on:click="closeModal()" type="button" class="close btn btn-sm rounded pointer" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table v-if="selected_key>-1" class="table-bordered table table-striped">
                            <tbody>
                            <tr>
                                <td >{{__("product title")}}</td>
                                <td class="text-center">{{_(order.get_order_product[selected_key].product.title)}}</td>
                            </tr>
                            <tr v-if="order.get_order_product[selected_key].warranty!=null">
                                <td >{{__("warranty")}}</td>
                                <td class="text-center">{{_(order.get_order_product[selected_key].warranty.name)}}</td>
                            </tr>
                            <tr v-if="order.get_order_product[selected_key].color!=null">
                                <td>{{__("color")}}</td>
                                <td class="text-center">{{_(order.get_order_product[selected_key].color.name)}}</td>
                            </tr>
                            <tr>
                                <td>{{__("price")}}</td>
                                <td class="text-center">{{priceFormat({},order.get_order_product[this.selected_key].product_price2)}}</td>
                            </tr>
                            <tr >
                                <td >{{__("count sell")}}</td>
                                <td class="text-center">{{order.get_order_product[selected_key].product_count}}</td>
                            </tr>
                            <tr v-if="order.get_order_product[selected_key].send_status<3 && order.get_order_product[selected_key].send_status>0">
                                <td> {{__("count for send")}} </td>
                                <td class="text-center">
                                    <span v-if="order.get_order_product[selected_key].seller_product_count">
                                        {{order.get_order_product[selected_key].seller_product_count}}
                                    </span>
                                    <span v-else>-</span>
                                </td>
                            </tr>
                            <tr v-if="order.get_order_product[selected_key].send_status<3 && order.get_order_product[selected_key].send_status>0">
                                <td> {{__("product inventory")}} </td>
                                <td class="text-center">
                                    <span v-if="order.get_order_product[selected_key].has_product_count">
                                        {{order.get_order_product[selected_key].has_product_count}}
                                    </span>
                                    <span v-else>0</span>
                                </td>
                            </tr>
                            <tr >
                                <td> {{__("shop must send")}} </td>
                                <td class="text-center">
                                    <span >
                                        {{order.get_order_product[selected_key].shop_product_count}}
                                    </span>

                                </td>
                            </tr>
<!--                            <tr v-if="order.get_order_product[selected_key].send_status<3 && order.get_order_product[selected_key].send_status>0">-->
<!--                                <td> {{__("number send to buyers")}} </td>-->
<!--                                <td class="text-center">-->
<!--                                    <div class="text-center" v-if="order.get_order_product[selected_key].has_product_count">-->
<!--                                        <span v-if="order.get_order_product[selected_key].preparation_product_count-order.get_order_product[selected_key].has_product_count>0">-->
<!--                                               {{order.get_order_product[selected_key].preparation_product_count-order.get_order_product[selected_key].has_product_count}}-->
<!--                                        </span>-->
<!--                                        <span v-else>-</span>-->
<!--                                    </div>-->
<!--                                    <div class="text-center" v-else>-->
<!--                                        {{order.get_order_product[selected_key].preparation_product_count}}-->
<!--                                    </div>-->
<!--                                </td>-->
<!--                            </tr>-->

                            <tr >
                                <td >{{__("status")}}</td>
                                <td class="text-center">{{getStatus(order.get_order_product[selected_key].send_status)}}</td>
                            </tr>

                            <tr v-if="order.get_order_product[selected_key].send_status==-1">
                                <td >{{__("description")}}</td>
                                <td class="text-center">{{order.get_order_product[selected_key].description}}</td>
                            </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" id="shipment_modal">
            <div class="modal-dialog modal-lg shadow-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{__("shipping packets")}}</h5>
                        <button v-on:click="closeModal()" type="button" class="close btn btn-sm rounded pointer" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="border p-1 rounded" v-if="selected_order.seller_product_count>0">
                            <div class="p-1 bg-light border-bottom w-100 d-flex justify-content-between" >
                             <span> {{__("seller must send")}} </span>
                             <span class="mx-2"> {{__("status")}} </span>
                            </div>
                            <div class="justify-content-between my-2 d-flex shipment_counts">
                                <div class="d-inline-block">
                                    <div class="px-1">{{__("total :name",{name:__('count')})}} : {{selected_order.seller_product_count}}</div>
                                    <div class="px-1">{{__("shipping count")}} : {{selected_order.seller_ship_count}}</div>
                                    <div class="px-1">{{__("must send")}} : {{selected_order.seller_product_count-selected_order.seller_ship_count}}</div>
                                </div>
                                <div :class="!$parent.hasMobile?'justify-content-end d-block':'justify-content-end d-flex'  " v-html="getState()"></div>
                            </div>

                        </div>


                        <div class="border p-1 rounded mt-1" v-if="selected_order.shop_product_count>0">
                            <div class="p-1 bg-light border-bottom w-100 d-flex justify-content-between" >
                                <span> {{__("shop must send")}} </span>
                                <span class="mx-2"> {{__("status")}} </span>
                            </div>

                            <div class="justify-content-between my-2 d-flex shipment_counts">
                                <div class="d-inline-block">
                                    <div class="px-1">{{__("total :name",{name:__('count')})}} : {{selected_order.shop_product_count}}</div>
                                    <div class="px-1" v-if="selected_order.shop_shipping_status=='send'||selected_order.shop_shipping_status=='delivered'">{{__("shipping count")}} : {{selected_order.shop_product_count}}</div>
                                    <div class="px-1" v-else>{{__("shipping count")}} : {{sumShopPackets()}}</div>
                                    <div class="px-1" v-if="selected_order.shop_shipping_status=='send'||selected_order.shop_shipping_status=='delivered'">{{__("must send")}} :0</div>
                                    <div class="px-1" v-else>{{__("must send")}} : {{selected_order.shop_product_count-sumShopPackets()}}</div>
                                </div>
                                    <div :class="!$parent.hasMobile?'justify-content-end d-block':'justify-content-end d-flex'" v-html="getState('shop_shipping_status')"></div>
                            </div>
                        </div>

                        <div v-if="selected_order.shipment_code!=null&&selected_order.shipment_code!=[]">
                        <div class="mt-2"  v-if="selected_order.seller_product_count>0&&selected_order.shipment_code.seller&&selected_order.shipment_code.seller.length>0">
                            <h6><i class="fal fa-check"></i>{{__("seller must sent")}}</h6>
                            <table class="table-bordered table table-striped">
                                <thead>
                                <tr v-if="!$parent.hasMobile">
                                    <th>#</th>
                                    <th>{{ __('company post') }}</th>
                                    <th>{{__("count")}}</th>
                                    <th>{{__("code")}}</th>
                                    <th>{{__("status")}}</th>
                                </tr>
                                <tr v-else>
                                    <th>#</th>
                                    <th>{{__("detail :name",{name:__('packet')})}}</th>
                                </tr>
                                </thead>
                                <tbody v-if="!$parent.hasMobile">
                                    <tr v-for="(obj,key) in selected_order.shipment_code.seller" :key="key" >
                                        <td class="text-center">{{key+1}}</td>
                                        <td class="text-center">{{obj.post}}</td>
                                        <td class="text-center">{{obj.count}}</td>
                                        <td class="text-center">{{obj.code==null?'--':obj.code}}</td>
                                        <td class="text-center">
                                            <select v-if="obj.state=='sent'" :id="'select_'+obj.id" class="pointer" v-on:change="toggleShowMessageDialog(obj.id,true)">
                                                <option value="sent" selected>{{__("sent")}}</option>
                                                <option value="delivered">{{__('delivered')}}</option>
                                            </select>
                                            <span v-else-if="obj.state=='delivered'">{{__('delivered')}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                <tr v-for="(obj,key) in selected_order.shipment_code.seller" :key="key">
                                    <td class="text-center">{{key+1}}</td>
                                    <td class="text-center">
                                        <div>{{ __('company post') +':'+ obj.post}}</div>
                                        <div>{{__("count")+':'+obj.count }}</div>
                                        <div>{{__("code")+':'+ obj.code==null?'--':obj.code}}</div>
                                        <div>
                                            {{__("status")+':' }}

                                            <select v-if="obj.state=='sent'" :id="'select_'+obj.id" class="pointer" v-on:change="toggleShowMessageDialog(obj.id,true)">
                                                <option value="sent" selected>{{__("sent")}}</option>
                                                <option value="delivered">{{__('delivered')}}</option>
                                            </select>
                                            <span v-else-if="obj.state=='delivered'">{{__('delivered')}}</span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="selected_order.shop_product_count>0&&selected_order.shipment_code.shop&&selected_order.shipment_code.shop.length>0">
                            <h6><i class="fal fa-check"></i>{{__("shop must sent")}}</h6>
                            <table class="table-bordered table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{__("count")}}</th>
                                    <th>{{ __('company post') }}</th>
                                    <th>{{__("code")}}</th>
                                    <th>{{__("status")}}</th>
                                </tr>
                                </thead>
                                <tbody >
                                <tr v-for="(obj,key) in selected_order.shipment_code.shop" :key="key">
                                    <td class="text-center">{{key+1}}</td>
                                    <td class="text-center">{{obj.count}}</td>
                                    <td class="text-center">{{obj.post}}</td>
                                    <td class="text-center">{{obj.code==null?'--':obj.code}}</td>
                                    <td class="text-center">
                                        <span >{{__(obj.state)}}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                </div>
                      </div>

                        <div class="mt-2 justify-content-end d-flex p-0 m-0" ref="rowBox" v-if="selected_order.seller_product_count>selected_order.seller_ship_count">
                                <div v-if="row_show" >
                                   <div > <small class="p-1 mb-1 " ><b>{{__("submit :name",{'name':__('packet')})}}</b></small></div>
                                    <div class="border border-info row w-100 py-1 m-0 rounded shipment_inputs">
                                        <div class="col col-lg-4 col-md-6 col-sm-12 col-12 pb-0 mb-0">
                                            <label class="p-1 w-100 bg-light">{{__(':name name',{name:__('company post')})}}</label>
                                            <input type="text" class="form-control w-100  border-info" v-model="post_name" :placeholder="__(':name name',{name:__('company post')})" >
                                        </div>
                                        <div class="col col-lg-4 col-md-6 col-sm-12 col-12 pb-0 mb-0">
                                            <label class="p-1 w-100 bg-light">{{__('count')}}</label>
                                            <input type="number" class="form-control w-100  border-info" v-model="count" :placeholder="__('shipping count')" >
                                        </div>
                                        <div class="col col-lg-4 col-md-12 col-sm-12 col-12 pb-0 mb-0">
                                            <label class="p-1 w-100 bg-light">{{__('code :name',{name:__('order tracking')})}}</label>
                                            <input type="text" class="form-control w-100  border-info" v-model="code" :placeholder="__('can empty :name',{name:''})" >
                                        </div>
                                    </div>

                                </div>



                            <button type="button" class="btn btn-outline-primary" v-on:click="add_row()">+ {{__("add :field",{field:__('packet')})}}</button>
                        </div>

                    </div>


                    <div class="modal-footer" v-if="selected_order.seller_product_count!=selected_order.seller_ship_count">

                        <small class="alert alert-danger d-block w-100 py-1" v-if="error!=''">
                            <i class="fa fa-exclamation-circle fa-xs mx-1"></i>
                            <b>{{__('Unknown error occurred')+' : '}}</b>
                            {{error }}
                        </small>
                        <div class="justify-content-center align-items-center d-flex w-100">
                            <button class="btn-success btn col col-11 col-md-8" v-on:click="add_packet()" v-if="row_show">
                                <i class="fal fa-save"></i>
                                {{__("submit info")}}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" id="factors_modal">
            <div class="modal-dialog modal-md shadow-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{__("factors")}}</h5>
                        <button v-on:click="closeFactorModal()" type="button" class="close btn btn-sm rounded pointer" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row justify-content-center">
                            <small for="file_input1" >{{__("choosing :name",{name:__('factor')})}}</small>
                            <div class="form-group col-12 my-2 border">
                            <small class="text-danger p-2" v-if="factor_required">* {{__("v.required",{attribute:__('order factor')})}}</small>
                                <input  class="d-none" type="file" ref="file_input1" v-on:change="selectFactor()">
                                <div class="select_file_div border-0 text-center justify-content-center d-inline-block" >
                                    <div class="border m-auto d-flex justify-content-center" style="max-width:273px">
                                        <img :src="tmp_factor" class="img-thumbnail mt-3 border-0 " style="max-width:273px;max-height:150px">
                                    </div>
                                    <div class="m-auto p-0" style="max-width:300px"  v-on:click="$refs['file_input1'].click()">
                                        <div class="title w-100">{{__("choose :name",{name:__('image')})}}</div>
                                        <div class="file_name w-100 text-truncate"></div>
                                    </div>
                                    <hr class="m-0 p-0">
                                </div>

                                <div class="m-1 d-flex justify-content-center" >
                                    <button class="btn btn-primary rounded-0" v-if="factor_btn" style="width:300px" v-on:click="addFactor()">
                                        {{__("send :name",{name:__('factor')})}}
                                    </button>
                                    <a class="btn border-primary rounded-0" v-else style="width:300px">
                                        <i class="fal fa-circle-notch fa-spin"></i>
                                        {{__("receiving information")}}
                                    </a>
                                </div>
                                <div class="alert alert-danger p-1 mt-2" v-if="this.factor_errors.length>0">
                                    <div v-for="(error,k) in this.factor_errors" :key="k" v-html="error"></div>
                                </div>
                                <hr class="m-0 p-0">
                                <div v-if="selected_order.factors&&(typeof selected_order.factors === 'object') && selected_order.factors.length>0">
                                    <div class="p-1 my-1"> <small ><b>{{__("factors")}}</b></small></div>
                                    <div class="border border-info row w-100 py-1 m-0 rounded factor_box">
                                        <div class="col col-lg-3 col-md-4 col-sm-6 col-6 p-0 mb-0 py-1 row"  v-for="(img,key) in selected_order.factors" :key="key">
                                            <a :href="host+'/files/factors/'+img" target="_blank" class="w-100">
                                                <img  :src="host+'/files/factors/'+img" :alt="__('factor')" class="img-thumbnail img-small rounded mx-auto d-block">
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" id="allFactorsModal">
            <div class="modal-dialog modal-md shadow-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{__("all factors")}}</h5>
                        <button v-on:click="closeFactorModal(true)" type="button" class="close btn btn-sm rounded pointer" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row justify-content-center">
                            <small for="file_input2" >{{__("choosing :name",{name:__('factor')})}}</small>
                            <div class="form-group col-12 my-2 border">
                                <small class="text-danger p-2" v-if="allFactorRequired">* {{__("v.required",{attribute:__('order factor')})}}</small>
                                <input  class="d-none" type="file" ref="file_input2" v-on:change="selectFactor(true)">
                                <div class="select_file_div border-0 text-center justify-content-center d-inline-block" >
                                    <div class="border m-auto d-flex justify-content-center" style="max-width:273px">
                                        <img :src="tmpAllFactor" class="img-thumbnail mt-3 border-0 " style="max-width:273px;max-height:150px">
                                    </div>
                                    <div class="m-auto p-0" style="max-width:300px"  v-on:click="$refs['file_input2'].click()">
                                        <div class="title w-100">{{__("choose :name",{name:__('image')})}}</div>
                                        <div class="file_name w-100 text-truncate"></div>
                                    </div>
                                    <hr class="m-0 p-0">
                                </div>

                                    <div class="text-success text-center p-1" v-if="upload_success"><i class="fal fa-check"></i>{{__("do")}}</div>
                                <div class="m-1 d-flex justify-content-center" >
                                    <button class="btn btn-primary rounded-0" v-if="allFactorBtn" style="width:300px" v-on:click="addAllFactor()">
                                        {{__("send :name",{name:__('factor')})}}
                                    </button>
                                    <a class="btn border-primary rounded-0" v-else style="width:300px">
                                        <i class="fal fa-circle-notch fa-spin"></i>
                                        {{__("receiving information")}}
                                    </a>
                                </div>
                                <div class="alert alert-danger p-1 mt-2" v-if="this.allFactorErrors.length>0">
                                    <div v-for="(error,k) in this.allFactorErrors" :key="k" v-html="error"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="message_div" v-if="showMessageDialog">
            <div class="message_box text-center">
                <p class="text-center mb-0" id="msg">{{__("do sure change status to :state",{state:"'"+__('delivered')+"'"})}}</p>
                <button class="alert alert-danger " v-on:click="toggleShowMessageDialog(idPacketSeller,false)" >{{__("no")}}</button>
                <button class="alert alert-success" v-on:click="changeToReceiveCustomer()">{{__("yes")}}</button>
            </div>
        </div>

    </div>
</template>

<script>
import myMixin from "@/myMixin";

export default {
name: "OrderProductsTable",
    mixins:[myMixin],
    props:['order'],
    data(){
        return {
            orderStatus: [],
            selected_key: -1,
            selected_order: {shipment_code: {}},
            row_show: false,
            count: 1,
            max_count: 0,
            code: '',
            stop: false,
            error: '',
            post_name:'',
            showMessageDialog: false,
            idPacketSeller: -1,
            tmp_factor:'',
            factor_required:false,
            allFactorRequired:false,
            factor_file:'',
            factor_errors:[],
            factor_btn:true,
            allFactorFile:'',
            checked_ids:{},
            allFactorErrors:[],
            allFactorBtn:true,
            tmpAllFactor:'',
            upload_success:false
        }
    },
    mounted() {
    this.tmp_factor=this.host+'/files/site/images/choose-file.png';
    this.tmpAllFactor=this.host+'/files/site/images/choose-file.png';
       this.orderStatus=[
            this.__('not pay'),
            this.__('return product'),
            this.__('o.waiting'),
            this.__('o.success'),
            this.__('o.preparation'),
            this.__('o.processing out'),
            this.__('o.send to post'),
            this.__('o.receive from post'),
            this.__('o.customer receive'),
        ];
       this.$nextTick(function(){
            $('.click').click();
       })

    },
    methods:{
        getStatus(status){
            status=status+2;
            return this.orderStatus[status];
        },
        show_order_info(key){
            /* global $ */
            this.selected_key=key;
            $("#orderInfo").show();
            if(this.order.get_order_product[this.selected_key].seller_read=='no'){
                const order_id=this.order.get_order_product[this.selected_key].order_id;
                this.readOrderRequest(order_id,this.selected_key);
            }
        },
        readOrderRequest(id,selected_key){
            const app=this;
            const url=this.host+"api/seller/order/read/"+id;
            this.axios.get(url,this.setHeader()).then(response=>{
                if(this.setAccountStatus(response.data)){
                    if(response.data=="ok"){
                        this.order.get_order_product[selected_key].seller_read='ok';
                    }else{
                        this.show_server_error();
                    }
                }
            }).catch(err=>{
                app.setCatch(err)
            })
        },
        closeModal(){
            this.row_show=false;
            $(this.$refs.rowBox).find('button').show();
            $('#orderInfo,#shipment_modal').hide();
            this.error="";
            this.post_name='';
            this.code='';
            this.count=1;
        },
        closeFactorModal(allFactors=false){
            if(allFactors==false){
                $('#factors_modal').hide();
                this.error="";
                this.factor_errors=[];
                this.factor_btn=true;
                this.tmp_factor=this.host+'/files/site/images/choose-file.png';
                this.$refs.file_input1.value=null;
            }else{
                $('#allFactorsModal').hide();
                this.allFactorErrors=[];
                this.allFactorBtn=true;
                this.tmpAllFactor=this.host+'/files/site/images/choose-file.png';
                this.$refs.file_input2.value=null;
            }

        },
        shipment_modal(key){
            if(this.stop==true)
                return true;
            this.selected_order=this.order.get_order_product[key]
            this.max_count=this.selected_order.seller_product_count-this.selected_order.seller_ship_count
            $("#shipment_modal").show();
        },
        factor_modal(key){
            if(this.stop==true)
                return true;
            this.selected_order=this.order.get_order_product[key]
            $("#factors_modal").show();
        },
        getState(who='seller_shipping_status'){
            let color='danger';
            let trans=this.__('unsent');
            const state=this.selected_order[who];
            switch (state) {
                      case 'incomplete':
                          color='warning'
                          trans=this.__('incomplete sent')
                      break;
                      case 'send':
                          color='info text-white'
                          trans=this.__('sent')
                          break;
                      case 'delivered':
                          color='success'
                          trans=this.__('delivered')
                          break;
            }
            return '<span class="btn btn-'+color+'">'+trans+'</span>'

        },
        add_row(){
            this.row_show=true;
            /* $ global */
            $(this.$refs.rowBox).find('button').hide();

        },
        add_packet(){
            const app=this;
            app.factor_required=false;
            if(app.stop==true)
                return "";
            if(app.post_name.trim().length==0)
                return app.error=app.__('v.required',{attribute:app.__(':name name',{name:app.__('company post')})})
            if(!app.selected_order.factors||!['object','array'].includes(typeof app.selected_order.factors)||app.selected_order.factors.length<1){
                if(app.selected_order.seller_product_count==parseInt(app.count)+parseInt(app.selected_order.seller_ship_count)){
                    app.factor_required=true;
                 return $("#factors_modal").show();
                }
            }
            app.stop=true;
            app.error="";
            app.row_show=false;
            const formData=new FormData();
            formData.append('count',app.count);
            formData.append('code',app.code);
            formData.append('post',app.post_name);
            formData.append('op_id',app.selected_order.id)
            formData.append('order_id',app.selected_order.order_id)
            const url=this.host+"api/send_packet"
            app.axios.post(url,formData,this.setHeader(false)).then(response=>{

                $(app.$refs.rowBox).find('button').show();
                app.stop=false;

               if(response.data.error!=undefined)
                    return app.error=response.data.error;
               app.count=1;

                for (const k in app.order.get_order_product) {
                    if(app.order.get_order_product[k].id==response.data.id){
                        for (const key in app.order.get_order_product[k]) {
                            if(response.data[key]!=undefined){
                                app.order.get_order_product[k][key]=response.data[key];
                            }
                        }
                    }
                }
                app.post_name='';
               app.code='';
               app.max_count=response.data.seller_product_count-response.data.seller_ship_count;

            }) .catch(()=>{
                $(app.$refs.rowBox).find('button').show();
                app.stop=false;
            })

        },
        changeToReceiveCustomer(){
            const app=this;
            app.showMessageDialog=false;
            if(app.stop==true)
                return;
            $('#select_'+app.idPacketSeller).attr('disabled',true);
            app.stop=true;
            app.error="";
            const formData=new FormData();
            formData.append('op_id',app.selected_order.id)
            formData.append('order_id',app.selected_order.order_id);
            formData.append('packet_id',app.idPacketSeller);
            const url=this.host+"api/change_seller_packet"
            app.axios.post(url,formData,this.setHeader(false)).then(response=>{
                app.stop=false;
                if(response.data.error!=undefined)
                    return app.error=response.data.error;

                for (const k in app.order.get_order_product) {
                    if(app.order.get_order_product[k].id==response.data.id){
                        for (const key in app.order.get_order_product[k]) {
                            if(response.data[key]!=undefined){
                                app.order.get_order_product[k][key]=response.data[key];
                            }
                        }
                    }
                }
            }) .catch(err=>{
                $(app.$refs.rowBox).find('button').show();
                app.stop=false;
                console.log("err",err)
            })
        },
        toggleShowMessageDialog(id,tf=false){
            this.showMessageDialog=tf;
            if(tf==true){
                this.idPacketSeller=id
            }else{
                $('body').find('#select_'+id).val('sent');
                this.idPacketSeller=0;
            }
        },
        sumShopPackets(){
            if(this.selected_order.shipment_code==undefined)
                return  0;
           const shop= this.selected_order.shipment_code['shop']
            if(shop==undefined||!Array.isArray(shop)) return 0;
            let sum=0;
            for (const k in shop)
                sum+=parseInt(shop[k]['count']?shop[k]['count']:0);
            return sum;
        },
        getAddress(){
            const address=this.order.get_order_product[this.selected_key].order.address;
            const spl=' / ';
            return address.address+spl+address.zip_code+spl+address.town+spl+address.city.name+spl+address.country.name
        },
        selectFactor(allFactors=false){
            if(!allFactors)
            this.tmp_factor=window.URL.createObjectURL(this.$refs['file_input1'].files[0])
            else
                this.tmpAllFactor=window.URL.createObjectURL(this.$refs['file_input2'].files[0])
        },
        addFactor(){
            this.factor_errors=[];
                this.factor_file=this.$refs.file_input1.files[0];
            if(this.has_file()==false)
                return false;
            this.factor_btn=false;
            const app=this;
            app.stop=true;
            const formData=new FormData();
            formData.append('factor_file', app.factor_file);
            app.factor_errors=[];
            const url=this.host+"api/upload_factor/"+app.selected_order.order_id+"/"+app.selected_order.id;
            app.axios.post(url,formData,this.setHeader(false)).then(response=>{
              app.stop=false;
              if(response.data.error)
                 return app.factor_errors.push(response.data.error);
              if(response.data!=false){
                  app.order.get_order_product.map(function(item){
                      if(item.id==response.data.id)
                        item.factors=response.data.factors;
                      }
                      )

                  this.factor_btn=true;
                  this.tmp_factor=this.host+'/files/site/images/choose-file.png';
                  this.$refs.file_input1.value=null;

                  if(app.factor_required==true){
                      app.factor_required=false;
                      $('#factors_modal').hide();
                      $('#shipment_modal .btn-success').click();
                  }
              }

            }) .catch((error)=>{
                console.log(error)
                this.factor_errors.push(this.__('errored')+'.'+this.__('try again'));
                app.stop=false;
                this.factor_btn=true;
            })

        },
        addAllFactor(){
            const app=this;
            if(app.stop==true)
                return false;
            app.stop=true;
            this.allFactorErrors=[];
            this.allFactorFile=this.$refs.file_input2.files[0];
            if(this.has_file2()==false)
                return false;
            this.allFactorBtn=false;
            const formData=new FormData();
            formData.append('allFactorFile', app.allFactorFile);
            const idsVals=Object.values(this.checked_ids);
            for (let i=0;i<idsVals.length;i++) {
                formData.append('ids['+i+']',idsVals[i]);
            }
            app.allFactorErrors=[];
            const url=this.host+"api/upload_all_factor/"+this.order.id;
            app.axios.post(url,formData,this.setHeader(false)).then(response=>{
                app.stop=false;
                this.allFactorBtn=true;
                if(response.data.error)
                    return app.allFactorErrors.push(response.data.error);
                if(response.data!=false){
                    app.order.get_order_product.map(function(op){
                        Object.entries(response.data).forEach(function(factor){
                            if(factor[0]==op.id){
                                op.factors=factor[1];
                            }

                    })})
                app.upload_success=true;


                    this.tmpAllFactor=this.host+'/files/site/images/choose-file.png';
                    this.$refs.file_input2.value=null;

                    if(app.allFactorRequired==true){
                        app.allFactorRequired=false;
                        $('#allFactorsModal').hide();
                    }
                }

            }) .catch((error)=>{
                console.log(error)
                app.allFactorErrors.push(this.__('errored')+'.'+this.__('try again'));
                app.stop=false;
                this.allFactorBtn=true;
            })

        },
        has_file(){
            if(!this.factor_file||this.factor_file.type==undefined){
                this.factor_errors.push(this.__('v.required',{attribute:this.__('factor')}))
                return false;
            }else if(!(this.factor_file.type+"").includes("image")){
                this.factor_errors.push(this.__('v.image',{attribute:this.__('factor')}))
                return false;
            }
        },
        has_file2(){
            if(!this.allFactorFile||this.allFactorFile.type==undefined){
                this.allFactorErrors.push(this.__('v.required',{attribute:this.__('factor')}))
                return false;
            }else if(!(this.allFactorFile.type+"").includes("image")){
                this.allFactorFile.push(this.__('v.image',{attribute:this.__('factor')}))
                return false;
            }
        },
        checkAll($event){
            this.checked_ids={};
            if($event.target.checked)
            {
                $('.CBOP').prop('checked', true);
                for (const op of this.order.get_order_product) {
                    this.$set(this.checked_ids, op.id, op.id)
                }
            }else{
                $('.CBOP').prop('checked', false);
            }

        },
        addCheckIds(id,$event){
            if($event.target.checked){
                for (const op of this.order.get_order_product) {
                    if(id==op.id){
                        this.$set(this.checked_ids, op.id, op.id)
                        break;
                    }
                }
                if(this.order.get_order_product.length==Object.keys(this.checked_ids).length){
                    $("#checkAll").prop('checked',true)
                }
            }else{
                if(this.checked_ids[id]!=undefined)
                    this.$delete(this.checked_ids, id);
                if(0==Object.keys(this.checked_ids).length)
                    $("#checkAll").prop('checked',false)
            }

        }
    },
    watch:{
        // order(){
        //     this.shipment_modal(0)
        // },
        checked_ids(n){
                this.$parent.show_options=Object.keys(n).length>0?true:false;
        },
        count(newVal){
            this.count=parseInt(Math.trunc(newVal));
            if(newVal>this.max_count){
                this.count=this.max_count;
            }
        },
        upload_success(nv){
            if(nv){
                const app=this;
                setTimeout(()=>{app.upload_success=false;},3000)
            }

        }
    }
}
</script>

<style scoped>

</style>
