<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>
        <div class="panel">
            <div class="header">
                {{__('edit :name',{'name':this.__('bank info')})}}
            </div>
            <div class="panel_content">

                <div class="form-group">
                    <label>{{__("account name")}} :</label>
                    <input type="text" v-model="account_name" :disabled="base_account_name!=null&&(base_account_name+'').trim().length>1" class="form-control">
                    <span class="has_error" v-if="account_name_error">{{account_name_error}}</span>
                </div>

                <div class="form-group">
                    <label>{{__("cart number")}} :</label>
                    <input type="text" v-model="cart_number" class="form-control">
                    <span class="has_error" v-if="cart_number_error">{{cart_number_error}}</span>
                </div>
                <div class="form-group">
                    <label>{{__("swift")}} :</label>
                    <input type="text" v-model="swift"  class="form-control">
                    <span class="has_error" v-if="swift_error">{{swift_error}}</span>
                </div>
                <div class="form-group">
                    <label>{{__("account number")}} :</label>
                    <input type="text" v-model="account_number"  class="form-control">
                    <span class="has_error" v-if="account_number_error">{{account_number_error}}</span>
                </div>

                <div class="form-group">
                    <label>{{__("paypal")}} :</label>
                    <input type="text" v-model="paypal"  class="form-control">
                    <span class="has_error" v-if="paypal_error">{{paypal_error}}</span>
                </div>

                <div class="alert alert-danger my-3" v-if="errors.length>0">
                    <p class="" v-for="(error,key) in errors" :key="key">{{key+1}}- {{error}} </p>
                </div>
                <div v-else-if="success" class="alert alert-success w-auto mt-2">
                    <button type="button" class="btn-close btn-sm" data-bs-dismiss="alert" aria-label="Close" v-on:click="success"></button>
                     {{__("saved")}}
                </div>
                <div class="form-group">
                    <label></label>
                    <button class="btn btn-success mt-2 col-sm-12 col-12 col-md-auto" v-on:click="send_data()">
                        {{__("submit info")}}
                    </button>
                </div>


            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>

    </div>
</template>

<script>
import myMixin from "@/myMixin";
export default {
name: "Bank",
    mixins:[myMixin],
    data(){
        return{
            showLoading:false,
            account_status:'active',
            server_error:false,
            BreadCrumbList:[],
            errors:[],
            seller:{},
            account_name:'',
            account_name_error:'',
            swift:'',
            account_number:'',
            account_number_error:false,
            swift_error:false,
            cart_number:'',
            cart_number_error:false,
            paypal:'',
            paypal_error:'',
            success:false,
            base_account_name:""
        }
    },mounted() {
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/setting/profile',title:this.__('manage :name',{'name':this.__('profile')})},
        ];
        this.account_status = localStorage.getItem('account_status');
        this.$emit('setSideBarItem','setting');
        this.getSellerInfo();
        this.getData();
        }
    ,methods:{
        getData(){
            const app=this;
            this.showLoading=true;
            const url=this.host+"api/seller/profile";
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                    this.seller=response.data.seller;
                    this.account_name=this.seller.account_name
                    this.base_account_name=this.seller.account_name
                    this.cart_number=this.seller.cart_number
                    this.account_number=this.seller.account_number;
                    this.swift=this.seller.swift;
                    this.paypal=this.seller.paypal;

            }).catch(err=>{
                app.setCatch(err)
            })
        },
        send_data(){
            const app=this;
               this.errors=[];
               this.showLoading=true;
               const formData=new FormData();
                if( (this.base_account_name+"").trim().length<1||this.base_account_name==null)
                    formData.append('account_name',this.account_name)
                formData.append('cart_number',this.cart_number)
                formData.append('swift',this.swift)
                formData.append('account_number',this.account_number)
                formData.append('paypal',this.paypal)

                const url=this.host+"api/seller/bank"
                this.axios.post(url,formData,this.setHeader()).then(response=>{
                    this.showLoading=false;
                    if(response.data.error!=undefined){
                       const errors=response.data.error;
                        for (const errorsKey in errors) {
                            this[errorsKey] = errors[errorsKey]
                        }
                    }else
                        this.success=true

                    app.getSellerInfo();

                }).catch(err=>{
                    app.showLoading=false;
                    app.setCatch(err)
                })

            }
    },

}
</script>

<style scoped>

</style>
