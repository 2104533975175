<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-12 right_box">
                <h5>{{ __('register to seller center') }}</h5>
                <div class="content">
                    <ul class="step_ul">
                        <li>
                            <div :class="step<2?'step_active_icon':'step_inactive_icon'">
                                <i class="fal fa-user"></i>
                            </div>
                            <div>
                                <span class="step_header">{{ __("submit :name", {'name': __('seller')}) }}</span>
                                <p>{{ __("personal&commerce info") }}</p>
                            </div>
                        </li>
                        <li>
                            <div :class="step==2?'step_active_icon':'step_inactive_icon'">
                                <i class="fal fa-envelope-badge"></i>
                            </div>
                            <div>
                                <span class="step_header">{{ __("confirm email") }}</span>
                                <p>{{ __("submit confirm email") }}</p>
                            </div>
                        </li>
                        <li>
                            <div :class="step==3?'step_active_icon':'step_inactive_icon'">
                                <i class="fal fa-upload"></i>
                            </div>
                            <div>
                                <span class="step_header">{{ __("load doc") }}</span>
                                <p>{{ __("send doc") }}</p>
                            </div>
                        </li>
                        <li>
                            <div :class="step==4?'last_step_active_icon':'step_inactive_icon last_step'">
                                <i class="fal fa-shopping-bag"></i>
                            </div>
                            <div>
                                <span class="step_header">{{ __("end register") }}</span>
                                <p>{{ __("welcome seller") }}</p>
                            </div>
                        </li>


                    </ul>
                </div>
            </div>
            <div class="col-lg-8 col-12 p-0">

                <div class="register_form_box">
                    <h6 class="text-center my-3"><i class="fal fa-user-plus"></i> {{ __('register to seller center') }}
                    </h6>
                    <hr>
                    <div v-if="step==0" class="first_step ">

                        <div class="form-group justify-content-center d-flex">
                            <div>
                                <div class="input-group">
                                    <v-select class="form-control style_lang" v-model="selected_lang" label="name"
                                              index="id" :options="languages"
                                              :placeholder="__('choose :name',{'name':__('language')})"></v-select>
                                    <div class="input-group-prepend">
                                        <div :class="lang_error?'input-group-text border-danger':'input-group-text'">
                                            <i class="fal fa-language"></i>
                                        </div>
                                    </div>
                                </div>
                                <p v-if="lang_error" class="has_error">{{ lang_error }}</p>
                            </div>
                        </div>

                        <div class="form-group justify-content-center d-flex">
                            <div>
                                <div class="input-group">
                                    <input type="text" v-model="email"
                                           :class="email_error?'form-control border-danger rounded-0 text-left ltr':'form-control rounded-0 text-left ltr'"
                                           :placeholder="__('enter :field',{'field':__('email')})">
                                    <div class="input-group-prepend">
                                        <div :class="email_error?'input-group-text border-danger':'input-group-text'">
                                            <i class="fal fa-envelope"></i>
                                        </div>
                                    </div>
                                </div>
                                <p v-if="email_error" class="has_error">{{ email_error }}</p>
                            </div>
                        </div>
                        <div class="form-group justify-content-center d-flex">
                            <div>
                                <div class="input-group">
                                    <input type="password" v-model="password"
                                           :class="password_error?'form-control border-danger rounded-0 text-left ltr':'form-control rounded-0 text-left ltr'"
                                           :placeholder="__('enter :field',{'field':__('password')})">
                                    <div class="input-group-prepend">
                                        <div
                                            :class="password_error?'input-group-text border-danger':'input-group-text'">
                                            <i class="fal fa-lock"></i>
                                        </div>
                                    </div>
                                </div>
                                <p v-if="password_error" class="has_error">{{ password_error }}</p>
                            </div>
                        </div>
                        <div class="form-group justify-content-center d-flex">
                            <div>
                                <div class="input-group">
                                    <input type="text" v-model="mobile"
                                           :class="mobile_error?'form-control border-danger rounded-0 text-left ltr':'form-control rounded-0 text-left ltr'"
                                           :placeholder="__('enter :field',{'field':__('mobile')})">
                                    <div class="input-group-prepend">
                                        <div :class="mobile_error?'input-group-text border-danger':'input-group-text'">
                                            <i class="fal fa-phone"></i>
                                        </div>
                                    </div>
                                </div>
                                <p v-if="mobile_error" class="has_error">{{ mobile_error }}</p>
                            </div>
                        </div>


                        <div class="form-group d-flex justify-content-center">

                            <button type="submit" class="send_btn register_btn" v-on:click="validate_register_form()">
                                <span class="line">
                                    <i class="fal fa-user-plus"></i>
                                </span>
                                <span class="title">{{ __("register") }}</span>
                            </button>

                        </div>


                        <p class="mt-1 text-center">

                            <router-link :to="'/'+this.locale+'/login'" class="btn btn-outline-primary">
                                {{ __("login to :name", {'name': __('seller panel')}) }}
                            </router-link>

                        </p>

                        <div class="alert alert-warning " id="spinner" v-if="send_form">
                            <div class="spinner-border text-warning" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span style="padding-right:10px">{{ __("sending information") }}</span>
                        </div>


                    </div>

                    <register-step2 :lang_id="selected_lang.id" :phone="mobile" v-if="step==1"
                                    @set_step="SetRegisterStep" @setServerError="show_server_error"
                                    @set_type="set_account_type"></register-step2>
                    <register-step3 :lang_id="selected_lang.id" :email_address="email" v-if="step==2"
                                    @set_step="SetRegisterStep" @setServerError="show_server_error"></register-step3>
                    <register-step4 :lang_id="selected_lang.id" :account_type="account_type" :email_address="email"
                                    v-if="step==3" @set_step="SetRegisterStep"
                                    @setServerError="show_server_error"></register-step4>


                    <div class="alert alert-info" v-if="step==4">
                        <p class="border-bottom p-1 text-center">
                            <strong>
                                <i class="fal fa-shopping-basket-alt"></i> {{ __("welcome seller") }}
                            </strong>
                        </p>
                        <p style="font-size: 15px;text-align: justify;line-height:27px">
                            {{ __("welcome seller desc") }}
                            <a :href="'/'+this.locale+'/login'"
                               class="border-bottom text-decoration-none border-primary mx-1">
                                {{ __("login to :name", {'name': __('seller panel')}) }}</a>
                        </p>
                    </div>

                    <div class="alert-danger alert mt-3" v-if="server_error">
                        <i class="fal fa-warning"></i>
                        {{ error_message }}
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>

import myMixin from "@/myMixin";
import RegisterStep2 from "@/components/RegisterStep2";
import RegisterStep3 from "@/components/RegisterStep3";
import RegisterStep4 from "@/components/RegisterStep4";

export default {
    name: "Register",
    components: {RegisterStep2, RegisterStep3, RegisterStep4},
    mixins: [myMixin],
    mounted() {
        this.getLanguage();
    },
    data() {
        return {
            step:0,
            email_error: false,
            email: '',
            password_error: false,
            password: '',
            mobile_error: false,
            mobile: '',
            send_form: false,
            server_error: false,
            error_message: '',
            account_type: 1,
            languages: [],
            selected_lang: 0,
            lang_error: false
        }
    },
    methods: {
        validate_register_form() {
            const app = this;
            app.error_message = '';
            app.server_error = false;
            app.lang_error = false;
            if (this.send_form)
                return;
            if (this.selected_lang.id == undefined || this.selected_lang.id < 1 || !Number.isInteger(this.selected_lang.id)) {
                return app.lang_error = this.__('v.required', {'attribute': this.__('language')})
            }
            if (!this.validate_email(this.email) || !this.validate_password(this.password) || !this.validate_mobile(this.mobile)) {
                return;
            }
            this.send_form = true;
            const formData = new FormData();
            formData.append('email', this.email);
            formData.append('password', this.password);
            formData.append('mobile', this.mobile);
            formData.append('lang_id', this.selected_lang.id);
            this.axios.post(this.host + "api/seller/first_step_register_seller", formData).then(response => {
                this.send_form = false;
                if (response.data.error != undefined) {
                    for (const i in response.data.error) {
                        switch (i) {
                            case 'email_error':
                                return app.email_error = response.data.error[i];
                            case 'password_error':
                                return app.password_error = response.data.error[i];
                            case 'mobile_error':
                                return app.mobile_error = response.data.error[i];
                            default:
                                response.data.status = 'error';
                                response.data.message = app.__('errored');

                        }
                    }
                }
                if (response.data.status == 'error') {
                    app.server_error = true;
                    app.error_message = response.data.message;
                } else {
                    app.server_error = false;
                    app.step = response.data.step;
                }


            }).catch(function () {
                app.send_form = false;
                app.server_error = true;
                app.error_message = app.__('errored');
            })


        },
        show_server_error(message) {
            this.server_error = (message == false ? false : true);
            this.error_message = message;
        },
        SetRegisterStep(step) {
            this.step = step;
        },
        set_account_type(type) {
            this.account_type = type;
        }
    }
    , watch: {
        languages: function (val) {
            this.languages = val;
            if (this.$route.query.sign) {
                const app = this;
                val.forEach(function (i) {
                    if (i.sign == app.$route.query.sign)
                        app.selected_lang = i;
                })
            }
        },
        selected_lang: function (val) {
            if (this.locale != val.sign) {
                window.location.href = '/' + val.sign + '/register?sign=' + val.sign;

            }
        }
    }
}
</script>



