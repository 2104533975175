<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>
        <div class="panel">
            <div class="header">
                {{__('manage :name',{'name':__('pays')})}}
            </div>
            <div class="panel_content">
                <div v-if="account_status=='active'" class="row">
                    <div  class="row search_form form-inline col-md-8 col-lg-5 col-sm-12 col-12" >
                        <h6>{{__('search')}}</h6>
                        <div class="input-group mb-3">
                            <label>{{__("pay date")}}</label>
                            <input type="datetime-local" class="form-control" v-model="date" >
                            <button class="btn btn-primary rounded-0" type="button" id="button-addon1" v-on:click="getList(1)">{{__("search")}}</button>
                        </div>
                    </div>

                    <table class="table table-bordered">
                        <thead>
                        <tr v-if="$parent.hasMobile">
                            <th>{{__("detail")}}</th>
                        </tr>
                        <tr v-else>
                            <th>#</th>
                            <th>{{__("id :name",{name:__('pay')})}}</th>
                            <th>{{__("amount paid")}}</th>
                            <th>{{__("submit time")}}</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(row,key) in list.data" :key="key" v-if="$parent.hasMobile">
                            <td class="text-justify">
                                <div>{{getRow(key)+') '+__("id :name",{name:__('pay')})+': '+row.pay_code}}</div>
                                <div>{{__("amount paid")+': '+priceFormat({}, row.price)}}</div>
                                <div>{{__("submit time")+': '+new Date(row.created_at).toDateString()}}</div>
                            </td>
                        </tr>
                        <tr v-for="(row,key) in list.data" :key="key" v-if="!$parent.hasMobile">
                            <td class="text-center">{{getRow(key)}}</td>
                            <td class="text-center">{{ row.pay_code }}</td>
                            <td class="text-center">{{priceFormat({}, row.price) }}</td>
                            <td class="text-center"> {{ new Date(row.created_at).toDateString()}} </td>
                        </tr>
                        </tbody>
                    </table>
                    <tr v-if="list.data.length==0" class="d-block w-100">
                        <p class="alert alert-info"><i class="fa fa-exclamation-triangle mx-2"></i>{{__("There is no record to display")}}</p>
                    </tr>

                    <pagination :data="list" @pagination-change-page="getList"></pagination>
                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{ __("403 text") }}
                    </p>
                </div>
            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
    </div>
</template>

<script>
import myMixin from "@/myMixin";

export default {
    name: "Payment",
    mixins:[myMixin] ,
    data(){
        return {
            showLoading:false,
            account_status:'active',
            server_error:false,
            BreadCrumbList:[],
            date:'',
            list:{data:[]},
            page:1,
            perPage:10
        }
    },
    mounted() {
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/orders/payment',title:this.__('manage :name',{'name':this.__('pays')})},
        ];
        this.account_status = localStorage.getItem('account_status');
        this.$emit('setSideBarItem','reports');
        this.getList();
    },
    methods:{
        getList(page=1){
            const app=this;
            this.page=page;
            this.showLoading=true;
            const url=this.host+"api/seller/payment?page="+page+"&date="+this.date;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    this.list=response.data;
                    this.perPage=response.data.per_page;
                }
            }).catch(err=>{
                app.setCatch(err)
            })
        }
    }
}
</script>

<style scoped>

</style>
