<template>
    <div class="bg-white">
        <loading v-if="showLoading"></loading>
        <div  v-if="showPrintButton" class="container d-flex justify-content-center my-2">
            <button class="btn btn-danger rounded-0" @click="print">{{__("print")}}</button>
        </div>
    <div class="container factor print_page" v-for="n in getPageCount" :key="n" >
        <div class="line"></div>
        <div class="header_factor w-100">
            <div>
                <div class="d-block">
                <span class="">
                    {{__('send time')+" : "+packet.send_date}}
                </span>
                    <span class="">
                    {{__("id :name",{'name':__('packet')}) +" : "+packet.packet_id}}
                </span>
                    <span class="">
                {{__(":name count",{'name':__('product')})+" : "+packet.product_count}}
            </span>

                </div>
            </div>
            <div class="title">
                {{__("send products factor")}}
            </div>
            <div>
                <span class=""> {{getPageCount+" "+__("from") +" "+n}}  </span>
            </div>
        </div>
        <div v-if="n>=((n-1)*count) && n<=((n-1)*count)+count">

            <table class="table table-bordered">
            <thead>
            <tr>
                <th>#</th>
                <th>{{__("image")}}</th>
                <th>{{__("title")}}</th>
                <th>{{__("warranty")}}</th>
                <th>{{__("color")}}</th>
                <th>{{__("count")}}</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(row,key) in getProducts(n,count)" :key="key">
                <td class="text-center" >{{((key+1)*n)}}</td>
                <td class="text-center">
                    <img :src="row.product_warranty.product.imgPath" class="product_pic stockroom_img">
                </td>
                <td class="text-center">{{_(row.product_warranty.product.title)}}</td>
                <td class="text-center">{{_(row.product_warranty.warranty.name)}}</td>
                <td  class="text-center">
                                <span v-if="row.product_warranty.color.id>0" >
                                  <span>  <b class="border mx-1" :style="[{padding: '0 12px',width:'15px',height:'15px',background:row.product_warranty.color.code}]" ></b> {{_(row.product_warranty.color.name)}} </span>
                                </span>
                </td>
                <td class="text-center">
                        <span>
                        {{ row.product_count }}
                        </span>
                    <div class="text-center" style="color:red"
                         v-if="row.real_count!=null && row.real_count!=row.product_count">
                        <span>{{ __("count receive from stock man") }}</span> :
                        <span>
                            {{ row.real_count }}
                            </span>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
            <div class="" v-if="n==getPageCount">
                <p class="text-center bg-light py-3 px-1">

                {{__("products submit by :name in :stockroom",{name:" : "+packet.seller.brand_name,stockroom:packet.stockroom.name})}}
                </p>
            </div>
            <div class="factor_footer">
                <span>{{__("signature of recipient")}}</span>
                <span>{{__("signature of deliverer")}}</span>
            </div>

        </div>
    </div>
        <div v-if="list.length==0" class="d-block w-100">
            <p class="alert alert-info"><i class="fa fa-exclamation-triangle mx-2"></i>{{__("There is no record to display")}}</p>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>

    </div>
</template>

<script>
import myMixin from "@/myMixin";

export default {
    name: "Factor",
    mixins:[myMixin] ,
    data(){
        return {
            showLoading:false,
            account_status:'active',
            server_error:false,
            list:[],
            packet:null,
            count:8,
            showPrintButton:true,
        }
    },
    mounted() {
        this.account_status = localStorage.getItem('account_status');
        this.getList();
        const app=this
        window.onafterprint=function (){
            app.showPrintButton=true
        }
    },
    methods:{
        getList(page=1){
            const app=this;
            this.page=page;
            this.showLoading=true;
            const url=this.host+"api/seller/getPacketContent/factor/"+this.$route.params.id;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    if(response.data!='error'){
                        this.list=response.data.packet_product;
                        this.packet=response.data.packet;
                    }else{
                        this.show_server_error();
                    }
                }
            }).catch(err=>{
                app.showLoading=false;
                app.setCatch(err)
            })

        }, getProducts(currentPos,perPage){
            let data=[];
            for (let i = 0; i < this.list.length; i++) {
                const start=(currentPos-1)*perPage;
                const end=((currentPos-1)*perPage)+perPage
                if(i>=start && i<end)
                    data.push(this.list[i])
            }
            return data;
        },
        print(){
            this.showPrintButton=false;
            this.$nextTick(function (){
                window.print();
            })
        }
    },
    computed:{
        getPageCount(){
            return Math.ceil(this.list.length/this.count);
        },

    }
}
</script>

<style scoped>
    body{
        background-color: #fff!important;

    }
</style>
