<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>


        <div class="panel">
            <div class="header">
                {{__('add new :name',{'name':__('packet')})}}
            </div>
            <div class="panel_content">
                <div v-if="account_status=='active'" class="row">
                    <div class="alert alert-warning" v-if="errors.length>0">
                        <div v-for="(error,key) in errors" :key="key"><small>  <span class="mx-1">{{key+1}}-</span> {{error}}</small></div>
                    </div>
                    <div class="pb-2">
                        <div class="form-group p-1">
                            <label for="stockroom_id">{{ __("choose :name", {'name': __('stockroom')}) }}</label>
                            <select name="stockroom_id" id="stockroom_id" style="width:200px;cursor: pointer" v-model="stockroom_id"  :title=" __('choose :name', {'name': __('stockroom')}) ">
                                <option  v-for="row in stockroom" v-bind:value="row.id" v-bind:key="row.id">{{row.name}}</option>
                            </select>
                        </div>
                        <div class="form-group p-1">
                            <label>{{__("send from")}}</label>
                            <input type="datetime-local" class="form-control w-auto" :placeholder="__('send from')" v-model="date"/>
                        </div>
                        <div class="form-group p-1">
                            <textarea class="col col-lg-8 col-12" style="height:120px" v-model="description" :placeholder="__('description')"></textarea>
                        </div>
                        <div class="form-group p-1">
                            <a data-toggle="modal" data-target="#product_list" v-on:click="modalDisplay=true" class="alert alert-success rounded-0 p-1 pointer text-decoration-none">
                                {{__("add :field",{'field':__('product')} )}}
                            </a>
                        </div>
                    </div>
                    <p class="mt-3 mb-2">{{__("chose :name",{'name':__('products')})}}</p>
                    <table class="table table-bordered">
                        <thead>
                        <tr v-if="$parent.hasMobile">
                            <th>#</th>
                            <th>{{__("detail")}}</th>
                        </tr>
                        <tr v-else>
                            <th>#</th>
                            <th>{{__("image")}}</th>
                            <th>{{__("title")}}</th>
                            <th>{{__("warranty")}}</th>
                            <th>{{__("color")}}</th>
                            <th>{{__("count")}}</th>
                            <th>{{__("operation")}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,key) in selected_product" :key="key" v-if="$parent.hasMobile">
                            <td>{{getRow(key)}}</td>
                            <td>
                                <div class="text-center">
                                    <img :src="item.product.imgPath" class="stockroom_img w-auto" style="max-height:80px">
                                </div>
                                    <div>{{_(item.product.title)}}</div>
                                    <div>{{__('warranty')+': '+_(item.warranty.name)}}</div>
                                    <div>
                                        <span v-if="item.color.id>0&&item.color_type==1" >
                                          <span>  <b class="border mx-1" :style="[{padding: '0 12px',width:'15px',height:'15px',background:item.color.code}]" ></b> {{__('color')+': '+_(item.color.name)}} </span>
                                        </span>
                                        <span v-else-if="item.color.id>0">
                                            <span> {{__("size")}} : {{_(item.color.name)}}</span>
                                        </span>
                                    </div>
                            <div class="justify-content-center d-flex mt-1" >
                                <input type="text"  v-model="selected_product[key].product_number" class="form-control w-auto text-center" />
                                <span style="line-height:30px" class="remove_item mt-0 rounded-0" v-on:click="removeOfList(key)" >{{__("delete")}}</span>
                            </div>

                            </td>
                        </tr>
                        <tr v-for="(item,key) in selected_product" :key="key" v-if="!$parent.hasMobile">
                            <td>
                                {{getRow(key)}}
                            </td>
                            <td>
                                <img :src="item.product.imgPath" class="product_pic stockroom_img">
                            </td>
                            <td>
                                <span>{{_(item.product.title)}}</span>
                            </td>
                            <td >
                                <span>{{_(item.warranty.name)}}</span>
                            </td>
                            <td style="width: 150px">
                                    <span v-if="item.color.id>0&&item.color_type==1" >
                                      <span>  <b class="border mx-1" :style="[{padding: '0 12px',width:'15px',height:'15px',background:item.color.code}]" ></b> {{_(item.color.name)}} </span>
                                    </span>
                                <span v-else-if="item.color.id>0">
                                    <span> {{__("size")}} : {{_(item.color.name)}}</span>
                                </span>
                            </td>
                            <td style="width: 70px">
                                <input type="text" v-model="selected_product[key].product_number" class="form-control w-100 text-center" />
                            </td>
                            <td class="p-0 m-0 text-center">
                                <span class="remove_item" v-on:click="removeOfList(key)" >{{__("delete")}}</span>
                            </td>
                        </tr>
                        <tr class="text-center" v-if="selected_product.length==0">
                            <td colspan="8">{{__("not choose :name",{'name':__('product')})}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <button class="btn btn-success border-0" v-on:click="send_data()">{{__("add")}}</button>

                    <div class="modal fade product_list show d-block" v-if="modalDisplay" id="product_list" tabindex="-1"  aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div class="box_border">
                                        <div class="input_div">
                                            <input type="text" class="form-control" v-model="search_text" :placeholder="__(':name name',{'name':__('product')})" />
                                            <a class="btn btn-primary" v-on:click="getProductWarranty(1)">{{__("search")}}</a>
                                        </div>
                                    </div>
                                    <button type="button" v-on:click="modalDisplay=false" class="close btn" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>

                                </div>
                                <div class="modal-body p-0">

                                    <div class="loading_box2" v-if="get_data">
                                        <div class="spinner">
                                            <div class="b1"></div>
                                            <div class="b2"></div>
                                            <div class="b3"></div>
                                        </div>
                                    </div>



                                    <table class="table table-bordered">
                                        <tbody>
                                        <tr v-for="(item,key) in productList.data" :key="key" v-if="$parent.hasMobile">
                                            <td>{{getRow(key)}}</td>
                                            <td>
                                                <div class="d-flex justify-content-start">
                                                    <img :src="item.product.imgPath" class="stockroom_img_pic" style="max-height:50px">
                                                    <div class="mx-1">
                                                        <div>
                                                            {{_(item.product.title)}}
                                                        </div>
                                                        <div>
                                                            {{__('seller')+': '+item.seller.brand_name}}
                                                        </div>
                                                        <div>
                                                              <span v-if="item.color.id>0" >
                                                                  <span v-if="item.color.type==1">
                                                                      {{__("color")+': '}}
                                                                  </span>
                                                                  <span v-else>
                                                                      {{__("size")+': '}}
                                                                  </span>
                                                                <span>
                                                                    <b class="border mx-1" :style="[{padding: '0 12px',width:'15px',height:'15px',background:item.color.code}]" ></b> {{_(item.color.name)}}
                                                                </span>
                                                              </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex mt-2">
                                                    <input type="text" v-model="product_count[key]" class="form-control w-50 text-center" />

                                                    <span v-if="checkInList(item.id)" class="mx-1 px-2 rounded bg-light text-success" style="line-height:40px"  >{{__("added")}}</span>
                                                    <span v-else v-on:click="add_product(item.id,key)" class="select_item rounded-0 btn-success" style="line-height:28px"   >{{__("add to :field",{'field':__('list')})}}</span>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-for="(item,key) in productList.data" :key="key" v-if="!$parent.hasMobile">
                                            <td>
                                                {{getRow(key)}}
                                            </td>
                                            <td>
                                                <img :src="item.product.imgPath" class="stockroom_img_pic" style="max-height:50px">
                                            </td>
                                            <td style="max-width:180px">
                                                <span>{{_(item.product.title)}}</span>
                                            </td>
                                            <td style="font-size: 14px">
                                                <span>{{item.seller.brand_name}}</span>
                                            </td>
                                            <td >
                                    <span v-if="item.color.id>0" >
                                      <span>  <b class="border mx-1" :style="[{padding: '0 12px',width:'15px',height:'15px',background:item.color.code}]" ></b> {{_(item.color.name)}} </span>
                                    </span>
                                            </td>
                                            <td style="width: 70px">
                                                <input type="text" v-model="product_count[key]" class="form-control w-100 text-center" />
                                            </td>
                                            <td class="p-0 m-0 text-center">
                                                <span v-if="checkInList(item.id)" style="color: #ef5661">{{__("added")}}</span>
                                                <span class="select_item" v-on:click="add_product(item.id,key)" v-else>{{__("add to :field",{'field':__('list')})}}</span>
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                    <pagination :data="productList" @pagination-change-page="getProductWarranty"></pagination>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="message_div" v-if="show_message_box" style="display:block">
                        <div class="message_box">
                            <p id="msg">{{msg}}</p>
                            <span class="alert alert-success" v-on:click="add_product_to_stockroom">{{__("yes")}}</span>
                            <span class="alert alert-danger" v-on:click="show_message_box=false">{{__("no")}}</span>
                        </div>
                    </div>

                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{ __("403 text") }}
                    </p>
                </div>
            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
        <div class="modal-backdrop fade show" v-if="modalDisplay"></div>
    </div>
</template>

<script>
import myMixin from "@/myMixin";
export default {
name: "AddPacket",
    mixins:[myMixin],
    data(){
        return {
            showLoading:false,
            account_status:'active',
            server_error:false,
            BreadCrumbList:[],
            stockroom:[],
            stockroom_id:0,
            description:'',
            productList:{data:[]},
            errors:[],
            get_data:false,
            page:1,
            search_text:'',
            show_message_box:false,
            selected_product:[],
            product_count:[],
            modalDisplay:false,
            perPage:10,
            msg:'',
            date:''

        }
    },
    mounted() {
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/packets',title:this.__('manage :name',{'name':this.__('packets')})},
            {path:"/"+this.locale+'/packets/add',title:this.__('add new :name',{'name':this.__('packet')})},
        ];
        this.account_status = localStorage.getItem('account_status');
        this.$emit('setSideBarItem','packets');
        this.getStockroomList();
        this.getProductWarranty();
    },
    methods:{
        getStockroomList(){
            const app=this;
            const url=this.host+"api/seller/StockroomList";
            this.axios.get(url,this.setHeader()).then(response=>{
                if(this.setAccountStatus(response.data)){
                    this.stockroom=response.data;

                }
            }).catch(err=>{
                app.setCatch(err);
            })
        },
        getProductWarranty(page=1){
            const app=this;
            this.page=page;
            this.get_data=true;
            this.showLoading=true
            const url=this.host+"api/seller/stockroom/getProductWarranty?page="+page+"&search_text="+this.search_text;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.perPage=response.data.per_page
                this.showLoading=false;
                this.get_data=false;
                if(this.setAccountStatus(response.data)){
                    for (let i = 0; i < response.data.data.length; i++) {
                        this.product_count[i]=response.data.data[i].product_number;
                    }
                    this.productList=response.data;
                }
            }).catch(error=>{
                app.setCatch(error)
                app.showLoading=false
                app.get_data=false
            })
        },
        checkInList:function (id){
            let result=false;
            this.selected_product.forEach(function (row){
                if(row.id==id){
                    return  result=true;

                }
            });
            return result;
        },
        add_product:function (id,key){
            this.show_message_box=true;
            this.msg=this.__('sure add product to stockroom');
            this.select_id=id;
            this.select_key=key;
        },
        add_product_to_stockroom:function (){
            this.show_message_box=false;
            const n=this.product_count[this.select_key];
            if(parseInt(n)>0){
                let item=this.productList.data[this.select_key];
                item.product_number=n;
                this.selected_product.push(item);
            }
        },
        removeOfList:function (key){
            this.$delete(this.selected_product,key);
        },
        send_data(){
            this.errors=[];
            let send=true;
            const app=this;
            if(this.stockroom_id==0){
                 send=false;
                this.errors.push(this.__('v.filled',{'attribute':this.__('stockroom')}))
            }
            if(this.selected_product.length==0){
                 send=false;
                this.errors.push(this.__('v.required',{'attribute':this.__('products')}))
            }
            if(this.date==''){
                 send=false;
                this.errors.push(this.__('v.filled',{'attribute':this.__('date')}))
            }
            if(send==false)
                return "";
            let string='';
            this.selected_product.forEach(function (row) {
                string=string+'@'+row.id+'_'+row.product_number
            })
            const url=this.host+"api/seller/stockroom/add_product"
            const formData=new FormData();
            formData.append('list',string);
            formData.append('description',this.description);
            formData.append('date',this.date);
            formData.append('stockroom_id',this.stockroom_id);
            this.axios.post(url,formData,this.setHeader()).then(response=>{
                if(this.setAccountStatus(response.data)){
                    if(response.data=='ok'){
                        this.$router.push({name:"packets"})
                    }else{
                        this.show_server_error();
                    }
                }
            }).catch(error=>{
                app.setCatch(error)
                app.showLoading=false
                app.get_data=false
        })
        }

    }
}
</script>

<style scoped>

</style>
