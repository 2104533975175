<template>
    <div class="floatAlert" v-if="er!=''">
        <div :class="$props.state">
            <i @click="close" class="fal fa-times-circle bg-white rounded-circle pointer closeAlert"></i>
            <b v-html="er"></b>
        </div>
    </div>
</template>

<script>
export default {
name: "Alert",
    props:['error'],
    data(){
        return{
          er:'',
         }
    },
    mounted() {
        this.er=this.$props.error;
    },
    methods:{
      close(){
          this.er="";
      }
    },watch:{
        error(){
            const ap=this;
            ap.er=ap.$props.error;
           const len=ap.er.length;
            if(len>0)
            {
                setTimeout(()=>{ap.er=''},len*160)
            }
        }
    }
}
</script>

<style scoped>

</style>
