<template>
    <div>
        <div class="account_title d-flex justify-content-center" ref="blur">
            {{__("enter sent code :email",{'email':email_address})}}
        </div>
        <div class="number_input_div">
            <input type="text" v-model="code" name="active_code" maxlength="6" ref="number_input" class="number_input number">
        </div>
        <div class="line_box">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>

        <div class="justify-content-between align-items-center">
            <p v-if="code_error" class="has_error text-center">{{code_error}}</p>
        <div class="justify-content-center align-items-center w-100 d-flex">
            <button id="resend_active_code" v-on:click="resend_active_code()" class="timer btn btn-outline-secondary">
                <span>{{__("send repeat code")}}</span>
                <span id="timer" class="px-1">{{show_time}}</span>
            </button>
        </div>

            <div class="form-group d-flex justify-content-center">

                <button type="submit" class="send_btn register_btn" v-on:click="send_code()">
                                <span class="line">
                                    <i class="fal fa-chevron-square-left"></i>
                                </span>
                    <span class="title">{{ __("submit & resume") }}</span>
                </button>

            </div>

        </div>
        <div class="alert alert-warning " id="spinner" v-if="send_form">
            <div class="spinner-border text-warning" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <span style="padding-right:10px">{{__("sending information")}}</span>
        </div>

    </div>
</template>

<script>
import myMixin from "@/myMixin";
export default {
    name: "RegisterStep3",
    props:['email_address','lang_id'],
    mixins:[myMixin],
    data(){
        return {
            code:'',
            t:120,
            timer:null,
            show_time:'',
            send_form:false,
            code_error:false
        }
    },mounted() {
        this.startTimer();
    },methods:{
        startTimer(){
            const app=this;
            this.timer=setInterval(function (){
                app.t-=1;
                let m=Math.floor(app.t/60);
                let s=app.t-(m*60);
                if(s.toString().length==1){
                    s="0"+s;
                }
                let text=m+":"+s;
                if(app.t==0){
                    clearInterval(app.timer);
                    app.show_time='';
                    app.timer=null;
                }else{
                    app.show_time=text;
                }

            },1000);
        },
        resend_active_code(){
            this.$emit('setServerError',false);
            if(this.t==0){
                this.send_form=true;
                const formData=new FormData();
                formData.append('email',this.email_address);
                formData.append('userLang',this.locale);
                formData.append('lang_id',this.lang_id);
                this.axios.post(this.host+'api/seller/resend_active_code',formData).then(response=>{
                    this.send_form=false;
                    if(response.data.status=='error')
                    {
                        this.$emit('setServerError',response.data.message);
                    }else{
                        this.t=180;
                        this.startTimer()
                    }


                }).catch(function (){
                    this.send_form=false;
                    this.$emit('setServerError',this.__('errored'));
                })
            }
        },
        send_code(){
            this.$emit('setServerError',false);
            const validate_code=this.validate_field(this.__('active code'),this.code,'code_error',6);
            if(validate_code&&this.send_form==false){
                this.send_form=true;
                const formData=new FormData();
                formData.append('email',this.email_address);
                formData.append('code',this.code);
                this.axios.post(this.host+'api/seller/check_active_code',formData).then(response=>{
                    this.send_form=false;
                    if(response.data.status=='error')
                    {
                        this.$emit('setServerError',response.data.message);
                    }else{
                        this.$emit('set_step',3)
                        this.$emit('setServerError',false);

                    }


                }).catch(function (){
                    this.send_form=false;
                    this.$emit('setServerError',this.__('errored'));
                })
            }
        },
    },
    watch:{
        code(val){
           if(String(val).length>5)
              this.$refs.number_input.blur();
        }
    }
}
</script>

<style scoped>

</style>
