import { render, staticRenderFns } from "./RegisterStep2.vue?vue&type=template&id=2b4a92f0&scoped=true&"
import script from "./RegisterStep2.vue?vue&type=script&lang=js&"
export * from "./RegisterStep2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b4a92f0",
  null
  
)

export default component.exports