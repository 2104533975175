<template>
<div>

    <loading v-if="showLoading"></loading>
    <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
    <account-status :account_status="this.account_status"></account-status>

    <div class="panel">
        <div class="header">
            {{__('manage :name',{'name':__('product warranty')})}}
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" >
                    {{__('options')}}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li>
                        <router-link :to="'/'+this.locale+'/products/warranty/add/'+product_id" class="dropdown-item" >
                            <span class="btn btn-sm btn-outline-success rounded-circle"><i class="fal fa-add"></i></span>
                            {{__("add new :name",{'name':__('product warranty')})}}
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="'/'+this.locale+'/products/warranty/'+product_id+'?trashed=true'" class="dropdown-item" >
                            <span class="btn btn-sm btn-outline-danger rounded-circle "><i class="fal fa-trash"></i></span>
                            {{__("Recycle Bin")+" : "+this.trash_count}}
                        </router-link>
                    </li>


                </ul>
            </div>


        </div>

        <div class="panel_content">

            <div v-if="account_status=='active'" class="row">

                <div v-if="$route.params.error" class="alert alert-dismissible mb-1 alert-danger mb-2" role="alert">
                    <span>{{$route.params.error}}</span>
                    <button type="button" class="btn-close " data-bs-dismiss="alert" aria-label="Close"></button>
                </div>

                <div class="col-12 alert alert-warning py-1 align-items-center d-flex flex-warp flex-column" v-if="commission.percentage>0||commission.fix>0">
                    <div class="w-100 pb-2" v-if="commission.percentage>0">{{__("com perc")+' : '+commission.percentage +'%'}}</div>
                    <div class="w-100" v-if="commission.fix>0">{{__("com fix")+' : '}} <label class="ltr w-auto">{{commission.fix+ defCurrency.sign}}</label></div>
                </div>

                <div class="panel_content px-0">


                    <div v-if="show_alert_message" class="alert alert-success alert-dismissible fade show mb-1" role="alert">
                        <span>{{alert_message}}</span>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="d-flex border">
                        <div class="align-items-center m-1">
                            <img class="img-small m-auto" style="max-width:80px" :src="product.thumbPath" :alt="_(product.title)">
                        </div>
                        <div class="d-flex flex-column pt-2 border border-bottom-0 border-top-0 w-100" style="font-size:14px;line-height:inherit">
                            <span class="mx-1"> <b class="iranyekanB text-black-50">{{__(":name details",{'name':__('product')})}}</b>"{{_(product.title)}}"</span>
                            <span class="mx-1"> <span class="text-black-50">{{__(':name name',{name:__('category')})}}</span> {{': '+_(product.category.name)}}</span>
                            <span class="mx-1"> <span class="text-black-50">{{__(':name name',{name:__('brand')})}}</span> {{': '+_(product.brand.name)}}</span>
                            <span v-if="product.category.send_free=='true'" class="mx-1"> <span class="text-black-50">{{__('shipping cost')}}</span> {{': '+__('free')}} </span>
                            <span class="mx-1"> <span class="text-black-50">{{__('product weight')}}</span> {{': '+product.product_weight}}</span>
                        </div>
                    </div>

                    <table class="table table-bordered table-striped"  v-if="!$parent.hasMobile">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{{__(":name name",{'name':__('warranty')})}}</th>
                            <th>{{__("product price")}}</th>
                            <th>{{__("price for sell")}}</th>
                            <th>{{__("product number")}}</th>
                            <th v-if="product.noneColor=='0'" >{{__("color")+" | "+__("size")}}</th>
                            <th>{{__("operation")}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(warranty,key) in product_warranty.data" :key="key">
                            <td class="text-center">  {{getRow(key)}} </td>
                            <td class="text-center">{{_(warranty.warranty.name)}}</td>
                            <td class="text-center ltr">
                                <a class="alert-warning alert  p-1 m-2 ">{{priceFormat(defCurrency,warranty.price1)}}</a>
                            </td>
                            <td class="text-center ltr">
                                <a class="alert-info alert p-1 m-2 ">{{priceFormat(defCurrency,warranty.price2)}}</a>
                            </td>
                            <td class="text-center">
                                <a class="alert-secondary alert   p-1 m-2">{{warranty.product_number}}</a>
                            </td>

                            <td class="text-center color_td" v-if="product.noneColor=='0'">
                               <button type="button" class="btn p-0 mx-1" v-if="warranty.color!=null" :style="'width:15px;height:15px;background-color:'+warranty.color.code"></button>{{_(warranty.color.name)}}
                            </td>
                            <td class="text-center">
                                <div class="dropdown w-auto text-center ">
                                    <button class="btn btn-outline-primary dropdown-toggle" type="button" :id="'dropdownMenuButton'+key" data-bs-toggle="dropdown" aria-expanded="false" >
                                        {{__('options')}}
                                    </button>
                                    <ul class="dropdown-menu w-auto dropdown-menu-end min-w-auto" :aria-labelledby="'dropdownMenuButton'+key">

                                        <li class="pointer" >
                                            <span v-on:click="warranty_key=key;getDetails(warranty.id)" v-b-modal.modalWarrantyDetail  type="button" class="dropdown-item px-2" style="font-size:13px!important">
                                                <i class="fal fa-eye"></i>
                                                {{__('see :field',{field:__('detail')})}}
                                            </span>
                                        </li>



                                        <li class="pointer" v-if="warranty.deleted_at==null" v-on:click="$router.push({name:'update_product_warranty',params:{'product_id':product_id,'id':warranty.id}})">
                                            <span class="dropdown-item px-2" >
                                               <i class="fal fa-edit"></i>
                                                {{__('edit :name',{name:__('product warranty')})}}
                                            </span>
                                        </li>

                                        <li class="pointer" v-if="warranty.deleted_at==null" v-on:click="remove_warranty(warranty.id)">
                                            <span class="dropdown-item px-2" >
                                               <i class="fal fa-trash"></i>
                                                {{__('remove :name',{name:__('product warranty')})}}
                                            </span>
                                        </li>

                                        <li class="pointer" v-if="warranty.deleted_at!=null" v-on:click="restore_warranty(warranty.id)">
                                            <span class="dropdown-item px-2" >
                                               <i class="fal fa-trash-undo"></i>
                                                {{__('restore :name',{name:__('product warranty')})}}
                                            </span>
                                        </li>


                                    </ul>
                                </div>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <table class="table mt-2" v-else>
                        <thead>
                        <tr class="bg-light">

                            <th >{{__("all :field",{field:__('product warranties')})}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(warranty,key) in product_warranty.data" :key="key">
                            <td class="text-center d-flex justify-content-between position-relative">
                                <div style="line-height:30px;min-height:90px">
                                    <div>
                                        {{getRow(key)+'- '+__(":name name",{'name':__('warranty')})+' : '+_(warranty.warranty.name)}}
                                    </div>
                                    <div class="d-flex">
                                       <span>{{__("price for sell")+' : '}} </span> <span class="ltr text-left d-flex">{{' '+priceFormat(defCurrency,warranty.price2)}}</span>
                                    </div>
                                    <div>
                                        {{__("product number")+' : '+warranty.product_number}}
                                    </div>
                                    <div class="color_td"  v-if="product.noneColor=='0'">
                                        {{__("color")+" : "}}
                                        <button type="button" class="btn p-0 mx-1" v-if="warranty.color!=null" :style="'width:15px;height:15px;background-color:'+warranty.color.code"></button>{{_(warranty.color.name)}}
                                    </div>
                                </div>
                                <div class="dropdown w-auto text-center position-absolute" style="z-index:0;left:0">
                                    <button class="btn btn-primary dropdown-toggle py-1" type="button" :id="'dropdownMenuButton'+key" data-bs-toggle="dropdown" aria-expanded="false" >
                                        {{__('options')}}
                                    </button>
                                    <ul class="dropdown-menu w-auto dropdown-menu-end min-w-auto py-0" style="margin-top:-10px;z-index:100000000" :aria-labelledby="'dropdownMenuButton'+key">

                                        <li class="pointer" >
                                            <span v-on:click="warranty_key=key;getDetails(warranty.id)" v-b-modal.modalWarrantyDetail  type="button" class="dropdown-item px-2 py-1" style="font-size:13px!important">
                                                <i class="fal fa-eye"></i>
                                                {{__('see :field',{field:__('detail')})}}
                                            </span>
                                        </li>



                                        <li class="pointer" v-if="warranty.deleted_at==null" v-on:click="$router.push({name:'update_product_warranty',params:{'product_id':product_id,'id':warranty.id}})">
                                            <span class="dropdown-item px-2 py-0" >
                                               <i class="fal fa-edit"></i>
                                                {{__('edit :name',{name:__('product warranty')})}}
                                            </span>
                                        </li>

                                        <li class="pointer" v-if="warranty.deleted_at==null" v-on:click="remove_warranty(warranty.id)">
                                            <span class="dropdown-item px-2 py-0" >
                                               <i class="fal fa-trash"></i>
                                                {{__('remove :name',{name:__('product warranty')})}}
                                            </span>
                                        </li>

                                        <li class="pointer" v-if="warranty.deleted_at!=null" v-on:click="restore_warranty(warranty.id)">
                                            <span class="dropdown-item px-2" >
                                               <i class="fal fa-trash-undo"></i>
                                                {{__('restore :name',{name:__('product warranty')})}}
                                            </span>
                                        </li>


                                    </ul>
                                </div>
                            </td>
                        </tr>

                        </tbody>
                    </table>

                    <tr v-if="product_warranty.data.length==0" class="d-block w-100">
                        <p class="alert alert-info"><i class="fa fa-exclamation-triangle mx-2"></i>{{__("There is no record to display")}}</p>
                    </tr>


                    <pagination :data="product_warranty" @pagination-change-page="getData"></pagination>
                </div>

            </div>
            <div v-else>
                <p class="py-5 text-center h2">
                    <i class="far fa-exclamation-circle"></i>
                    {{ __("403 text") }}
                </p>
            </div>
        </div>
    </div>

    <div>
        <b-modal id="modalWarrantyDetail" size="lg">
            <template #modal-title>
                <small style="font-size:15px!important;">
                    {{__('see :field',{field:__('detail')})}}
                </small>
            </template>
            <div v-if="product_warranty.data[warranty_key]">
                <table class="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>{{__(":name details",{name:''})}}</th>
                        <th >{{__("value")}}</th>
                    </tr>
                    </thead>
                <tbody>
                <tr>
                    <td class="text-center fs-14">{{__("warranty")}}</td>
                    <td class="text-center" >
                        {{_(product_warranty.data[warranty_key].warranty.name)}}
                    </td>
                </tr>
                <tr v-if="product.noneColor=='0'">
                        <td class="text-center fs-14">{{__("color")+" | "+__("size")}}</td>
                        <td class="text-center" >
                            <button type="button" class="btn p-0 mx-1" v-if="product_warranty.data[warranty_key].color!=null" :style="'width:15px;height:15px;background-color:'+product_warranty.data[warranty_key].color.code"></button>{{_(product_warranty.data[warranty_key].color.name)}}
                        </td>
                    </tr>
                <tr>
                    <td class="text-center fs-14">{{__("product price")}}</td>
                    <td class="text-center ltr" >
                      {{ priceFormat(defCurrency,product_warranty.data[warranty_key].price1)}}
                    </td>
                </tr>
                <tr>
                    <td class="text-center fs-14">{{__("price for sell")}}</td>
                    <td class="text-center ltr" >
                      {{priceFormat(defCurrency,product_warranty.data[warranty_key].price2)}}
                    </td>
                </tr>

                <tr>
                    <td class="text-center fs-14">{{__("product number")}}</td>
                    <td class="text-center" >
                        {{product_warranty.data[warranty_key].product_number}}
                    </td>
                </tr>

                <tr>
                    <td class="text-center fs-14">{{__("count one packet")}}</td>
                    <td class="text-center" >
                        {{product_warranty.data[warranty_key].count_one_packet}}
                    </td>
                </tr>

                <tr>
                    <td class="text-center fs-14">{{__("stockroom products")+' "'+__('shop')+'"'}}</td>
                    <td class="text-center" >
                        {{product_warranty.data[warranty_key].inventory_sum_product_count==null?0:product_warranty.data[warranty_key].inventory_sum_product_count}}
                    </td>
                </tr>

                <tr>
                    <td class="text-center fs-14">{{__("stockroom products")+' "'+__('seller')+'"'}}</td>
                    <td class="text-center" >
                        {{product_warranty.data[warranty_key].product_number-product_warranty.data[warranty_key].inventory_sum_product_count}}
                    </td>
                </tr>

                </tbody>
                </table>

                <table class="table table-striped table-bordered scd" v-if="shippingData.length > 0">
                    <thead>
                    <tr>
                        <th class="w-25">{{__("country")}}</th>
                        <th>{{__("shipping cost")}}</th>
                        <th>{{__("send time")}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(shippingCost,key) in shippingData" :key="key">
                        <td class="text-center">{{shippingCost.country.name}}</td>
                        <td class="text-center">{{shippingCost.shipping_cost+' '+defCurrency.sign}}</td>
                        <td class="text-center">{{shippingCost.shipping_time+' '+__('day')}}</td>
                    </tr>
                    </tbody>
                </table>


            </div>
            <template #modal-footer>
                <div class="w-100 row justify-content-between">
                    <b-button  @click="$bvModal.hide('modalWarrantyDetail')" class="col-md-4"
                               variant="secondary" size="sm">
                        {{ __("close") }}
                    </b-button>
                </div>
            </template>

        </b-modal>
    </div>


    <div class="message_div" v-if="showMessageDialog">
        <div class="message_box text-center">
            <p class="text-center" id="msg">{{msg}}</p>
            <button class="alert alert-success" v-on:click="remove_warranty_of_list">{{__("yes")}}</button>
            <button class="alert alert-danger " v-on:click="showMessageDialog=!showMessageDialog" >{{__("no")}}</button>
        </div>
    </div>

    <ServerErrorMessage v-if="server_error"></ServerErrorMessage>


</div>
</template>

<script>
import myMixin from "@/myMixin";

export default {
name: "ProductWarranty",
    mixins:[myMixin],
    data(){
    return{
        page:1,
        account_status:'active',
        showLoading:false,
        BreadCrumbList:[],
        server_error:false,
        title:'',
        product_id:0,
        product_warranty:{data:[]},
        trash_count:0,
        show_alert_message:false,
        perPage:10,
        showMessageDialog:false,
        msg:"",
        warranty_id:0,
        trashed:false,
        alert_message:"",
        commission:{fix:0,percentage:0},
        options:{
            delimiter:',',
            numeral:true,
            numeralDecimalScale:3
        },
        product:{title:{},category:{name:{}},brand:{name:{}}},
        warranty_key:0,
        shippingData:[]
    }
    },
    mounted() {
        this.$bvModal.hide('modalWarrantyDetail')
        this.account_status = localStorage.getItem('account_status');
        this.$emit('setSideBarItem','products');
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/products',title:this.__('manage :name',{'name':this.__('products')})},
            {path:"/"+this.locale+'/products/warranty/'+(this.$route.params.id),title:this.__('manage :name',{'name':this.__('product warranty')})},
        ];
        this.product_id=this.$route.params.id;
        let params=new window.URLSearchParams(window.location.search)
        if(params.get('trashed')=='true'){
            this.trashed=true;
            this.BreadCrumbList.push({
                title:this.__('Recycle Bin'),
                path:''
            })
        }

        if(this.$route.params.success=="ok"){
            this.show_alert_message=true;
            this.alert_message=this.__('success :action :name',{'action':this.__('submit :field',{'field':this.__('product warranty')}),'name':''});
            if(this.$route.params.message!=undefined)
                this.alert_message=this.$route.params.message

        }

        this.getData();
    },
    methods:{
        getData(page=1){
            const app=this;
            this.page=page;
            const resume=(this.trashed==true)?"&trashed=true":"";
            const url=this.host+"api/seller/getProductWarranty/"+this.product_id+"?page="+page+resume;
            this.showLoading=true;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false
                if(this.setAccountStatus(response.data)){
                    if(response.data.title!=undefined){
                        this.title=response.data.title;
                        this.product_warranty=response.data.product_warranties;
                        this.perPage=response.data.product_warranties.per_page;
                        this.trash_count=response.data.trash_count;
                        if(response.data.commission!=undefined)
                            this.commission=response.data.commission;
                        if(response.data.product!=undefined)
                            this.product=response.data.product;

                    }else if(response.data=="error"){
                        alert(this.__('not found products'))
                        return this.$router.push({name:'product_list'});
                    }
                }
            }).catch(err=>{
                app.setCatch(err);
            })
        },
        remove_warranty(id){
            this.msg=this.__('Do you :action this information',{'action':this.__('delete')});
            this.showMessageDialog=true
            this.warranty_id=id;


        },
        remove_warranty_of_list(){
            const app=this;
            const url=this.host+"api/seller/ProductWarranty/"+this.warranty_id;
            this.showMessageDialog=false;
            this.showLoading=true;
            this.axios.delete(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(response.data.trash_count!=undefined){
                    this.show_alert_message=true;
                    if(this.trashed){
                        let query = Object.assign({}, this.$route.query);
                        delete query.trashed;
                        this.$router.replace({ query });
                        this.alert_message=this.__('success :action :name',{'action':this.__('restore'),'name':this.__('product warranty')});
                    }else{
                        this.alert_message=this.__('success :action :name',{'action':this.__('delete'),'name':this.__('product warranty')});
                    }
                    this.product_warranty=response.data.product_warranties;
                    this.perPage=response.data.product_warranties.per_page;

                    this.trash_count=response.data.trash_count;
                }else{
                    this.show_server_error();
                }
            }).catch(err=>{
                app.setCatch(err);
            });
        },
        restore_warranty(id){
            this.msg=this.__('Do you :action this information',{'action':this.__('restore')});
            this.showMessageDialog=true
            this.warranty_id=id;
        },
        getDetails(pw_id){
            const app=this;
            if(app.shippingData.length>0&&app.shippingData[0].product_warranty_id==pw_id)
                return true;

            app.shippingData=[];
            const url=this.host+"api/shippingData/"+pw_id;
            this.axios.get(url,this.setHeader()).then(response=>{
                app.shippingData=response.data;
            }).catch(err=>{
                app.setCatch(err);
            })

        }
    },watch:{
    '$route.query.trashed'(){
        this.trashed=!this.trashed;
        if( this.trashed){
            this.BreadCrumbList.push({
                title:this.__('Recycle Bin'),
                path:''
            })
        }else if(this.BreadCrumbList.length==3){
            this.BreadCrumbList.pop();
        }
        this.getData();
    }
    }
}

</script>

<style scoped>

</style>
