import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false
import router from "@/router";
import axios from 'axios';
import { BootstrapVue} from 'bootstrap-vue'
import VueAxios from 'vue-axios';
Vue.use(VueAxios,axios);
import 'bootstrap';
import vSelect from 'vue-select'
window.$=require('jquery')

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/fontawesome.min.css';
import './assets/css/app.css';
import './assets/css/admin.css';
import 'vue-select/dist/vue-select.css';


Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('AccountStatus', require('@/components/AccountStatus').default);
Vue.component('ServerErrorMessage', require('@/components/ServerErrorMessage').default);
Vue.component('Breadcrumb', require('@/components/Breadcrumb').default);
Vue.component('Loading', require('@/components/Loading').default);
Vue.use(BootstrapVue)

Vue.component('v-select', vSelect)
const defLang="FA"
const host="https://supersemarket.com/";
// const host="http://shop.local/";
const pathname=window.location.pathname;
const arr_pathname=pathname.split('/');
let urlWithLang=window.location.protocol + "//" + window.location.host+"/";
//const resumePath='/'+arr_pathname.slice(2).join('/')
let locale=(arr_pathname[1]+"").trim();
let time=0;
if(locale==undefined||locale.length!=2){
    locale=defLang;
    window.history.pushState('data','title',urlWithLang+defLang+pathname);
}

var lang="";
async function getLang(locale) {
   return await axios.get(host+locale+"/getLang")
}
let data=getLang(locale);
data.then((r)=>(lang=r.data));
if(Vue.defCurrency==undefined){
    Vue.defCurrency= Vue.prototype.defCurrency ={'init':false}
}

var interVal=setInterval(function() {
    if (lang == "")
        return;
    clearInterval(interVal);

    Vue.mixin({
        data() {
            return {
                lang: lang,
                //host:"http://shop.local/",
                host: host,
                defLang: "",
                site_url: '',
                locale: '',
                currency: {},
                stop: false,
                config_order_count: 0,
                hasMobile:false,
                learns:{},
                pageTitle:''
            }
        },
        mounted() {
            if(this.isMobile()) {
            const mobileStyle=()=>import('@/assets/css/mobile.css');
            const mobileJs=()=>import('@/assets/js/mobile.js');
                mobileStyle();
                mobileJs();
            }
            this.defLang = defLang;
            this.locale = locale;
            this.site_url = this.host + locale + "/";
            this.changeTitle();
            if(Vue.defCurrency.init==false){
                Vue.defCurrency= Vue.prototype.defCurrency ={'sign':'','name':'','multiply':1,'count_float':2,'delimiter':'.','float_delimiter':','}
                this.getDefCurrency()
            }

        },
        updated(){
            this.autoDir();
        },
        methods: {
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                    this.hasMobile=true;
                    return true;
                }
                this.hasMobile=false;
                    return false;
            },
            setHeader(image) {
                if (image) {
                    return {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + (localStorage.getItem('token')),
                        }
                    }
                } else {
                    return {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + (localStorage.getItem('token')),
                            'content-Type': 'multipart/form-data'
                        }
                    }
                }

            },
            isJson(val) {
                try {
                    JSON.parse(val)
                    return true;
                } catch (e) {
                    return false;
                }
            },
            __() {
                if (arguments.length < 1)
                    return null;
                const key = arguments[0];
                const replace = arguments[1];
                if (this.lang[key] === undefined)
                    return key;
                let trans = this.lang[key];
                if (replace != undefined) {
                    Object.keys(replace).forEach(function (v) {
                        trans = trans.replace(":" + v, replace[v]);
                    })
                }
                return trans;
            },
            _(val, sign) {
                if ("string" == typeof val && this.isJson(val)) {
                    val = JSON.parse(val)
                }
                if (sign != undefined) {
                    if (val[sign] != undefined)
                        return val[sign];
                    return "";
                }
                if (val[locale] != undefined) {
                    return val[locale];
                }
                if ("object" == typeof val) {
                    let obj = Object.entries(val);
                    for (const v of obj) {
                        return v[1];
                    }
                }


            },
            getRow(data, index) {
                ++index;
                let k = (data.current_page - 1) * data.per_page;
                k = k + index;
                return k;
            },
            formatMoney(number, decPlaces, decSep, thouSep) {
                var arrp = (number + "").toString().split('.');
                var _i = arrp[0], _f = arrp[1] === undefined ? "" : arrp[1];
                var __i = "", __f = "", __t = "";
                for (let i = 0; i < decPlaces && i < _f.length; i++) {
                    __f += "" + _f[i];
                }
                __f = __f ? decSep + "" + __f : "";
                for (let i = _i.length - 1; i >= 0; i -= 3) {
                    __t = "";
                    if (_i[i - 3] !== undefined)
                        __t += "" + thouSep;
                    if (_i[i - 2] !== undefined)
                        __t += _i[i - 2];
                    if (_i[i - 1] !== undefined)
                        __t += _i[i - 1];
                    if (_i[i] !== undefined)
                        __t += _i[i];
                    __i = __t + "" + __i;
                }
                return __i + "" + __f;
            },
            priceFormat(data, price) {
                if (data.sign == undefined) data.sign = "€";
                if (data.multiply == undefined) data.multiply = "1";
                if (data.count_float == undefined) data.count_float = "2";
                if (data.payment == undefined) data.payment = "no";
                if (data.delimiter == undefined) data.delimiter = ",";
                if (data.float_delimiter == undefined) data.float_delimiter = ".";
                price = this.formatMoney(price, data.count_float, data.float_delimiter, data.delimiter)
                return data.sign + " " + price;
            },
            getConfig() {
                /* global $ */
                const app = this;
                setTimeout(function () {
                    time = 20000;
                    if (app.stop == true) {
                        app.getConfig()
                        return;
                    }
                    app.stop = true;
                    app.axios.get(app.host + "api/getConfig", app.setHeader()).then(response => {
                        app.stop = false;
                        $('#favIco').attr('href', response.data.favIco);
                        if (response.data.orderCount > 0) {
                            $("#orderCount").show().html(response.data.orderCount)
                        } else {
                            $("#orderCount").hide().html('')
                        }
                        app.learns=response.data.learns;

                        app.getConfig()
                    }).catch(function () {
                        app.getConfig()
                    });
                }, time)

            },
            changeTitle() {
                const app = this;
                app.$nextTick(function(){
                let clone = $('.panel>.header')

                if(clone.length==0)
                   clone=$(".register_form_box>h6")

                let step=0;

                    clone.contents().filter(function (i, v) {
                        if (step === 0&&v.nodeValue!=null) {
                            step+=1;
                            app.pageTitle=v.nodeValue;
                            document.title = app.__('seller panel') + ' | ' + v.nodeValue;
                        }
                    })
                })

            },
            autoDir(){
                let dir="to-left";
                let textList = document.querySelectorAll('input,textarea')
                for (const text of textList) {
                    text.onkeyup = function () {
                        if (text.value.length > 0) {
                            var firstChar = text.value.charCodeAt(0);
                            if (firstChar >= 1536 && firstChar <= 1791) {
                                dir = 'to-right';
                            } else {
                                dir = 'to-left';
                            }
                        }
                        text.classList.remove('ltr','rtl','to-left','to-right')
                        text.classList.add(dir);
                    }
                }
            },
            getDefCurrency(){
                const app=this;
                if(app.defCurrency.name!='')
                    return false;

                    app.axios.get(app.host + "api/getDefCurrency",app.setHeader()).then((res)=>{
                        Vue.defCurrency= Vue.prototype.defCurrency = res.data;
                    })

            }
        },
        watch: {
            $route() {
                $('#sidebarToggle.fa-close').click();
                const app=this;
                app.changeTitle();
                time=1000;
                app.$nextTick(function(){
                    app.autoDir();
                })
            }
        }
    });

    new Vue({
        router,
        render: h => h(App),
    }).$mount('#app')
});


