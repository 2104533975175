import Vue from 'vue'
import VueRouter from "vue-router";
import Login from "@/components/Login";
import Register from "@/components/Register";
import Admin from "@/components/Admin";
import Index from "@/components/Index";
import SaleStatistics from "@/components/SaleStatistics";
import AddProduct from "@/components/AddProduct";
import ProductList from "@/components/ProductList";
import UpdateProduct from "@/components/UpdateProduct";
import AddProductWarranty from "@/components/AddProductWarranty";
import ProductWarranty from "@/components/ProductWarranty";
import UpdateProductWarranty from "@/components/UpdateProductWarranty";
import Items from "@/components/Items";
import Gallery from "@/components/Gallery";
import Orders from "@/components/Orders";
import AddPacket from "@/components/AddPacket";
import Packet from "@/components/Packet";
import PacketContent from "@/components/PacketContent";
import InventoryList from "@/components/InventoryList";
import Factor from "@/components/Factor";
import Profile from "@/components/Profile";
import Bank from "@/components/Bank";
import ChangePassword from "@/components/ChangePassword";
import Payment from "@/components/Payment";
import MessageList from "@/components/MessageList";
import MessageContent from "@/components/MessageContent";
import AddMessage from "@/components/AddMessage";
import OrderProducts from "@/components/OrderProducts";
import OrderProductsPackets from "@/components/OrderProductsPackets";

Vue.use(VueRouter)



function check_login(next,locale) {
    const expire_in = localStorage.getItem('expire_in');
    const time = Math.floor((new Date().getTime() / 1000));
    if (expire_in > time) {
        router.push({name:'/index',params:{locale:locale}});
    } else {
        return next();
    }
}
function check_auth(next,locale) {
    const expire_in = localStorage.getItem('expire_in');
    const time = Math.floor((new Date().getTime() / 1000));
    if (expire_in > time) {
        return next();
    } else {
        router.push({name:'login',params:{locale:locale}});
    }
}
const baseUrl='';
const routes = [
    {
        path: baseUrl+'/:locale', component: Admin, children: [
            {
                path: '/:locale', component: Index, name: 'index',
            }
        ], beforeEnter: (to, form, next) => {
            check_auth(next,to.params.locale);
        }
    },
    {
        path: baseUrl+'/:locale/orders', component: Admin, children: [
            {
                path: '/', component: Orders, name: 'orders', title:'orders'
            },
            {
                path: 'sale_statistics', component: SaleStatistics, name: 'sale_statistics',
            },
            {
                path: 'payment', component: Payment, name: 'payment',
            },
        ], beforeEnter: (to, form, next) => {
            check_auth(next,to.params.locale);
        }
    },
    {
        path: baseUrl+'/:locale/packet/show/:id/factor', name: 'factor', component: Factor, beforeEnter: (to, form, next) => {
            check_auth(next,to.params.locale);
        }
    },
    {
    path: baseUrl+'/:locale/packets', component: Admin, children: [
        {
            path: '/', component: Packet, name: 'packets',
        },
        {
            path: 'add', component: AddPacket, name: 'add_packet',
        },
        {
            path: 'show/:id', component: PacketContent, name: 'packet_content',
        },
        {
            path: 'inventory', component: InventoryList, name: 'inventory_list',
        },
        ],
        beforeEnter: (to, form, next) => {
            check_auth(next,to.params.locale);
        }
    },
    {
        path: baseUrl+'/:locale/products', component: Admin, children: [
            {
                path: '/', component: ProductList, name: 'product_list',
            },
            {
                path: 'add', component: AddProduct, name: 'add_product',
            },
            {
                path: ':id/edit', component: UpdateProduct, name: 'update_product',
            },
            {
                path: ':id/items', component: Items, name: 'items',
            },
            {
                path: ':id/gallery', component: Gallery, name: 'gallery',
            },
            {
                path: 'warranty/:id', component: ProductWarranty, name: 'product_warranty',
            },
            {
                path: 'warranty/add/:id', component: AddProductWarranty, name: 'add_product_warranty',
            },
            {
                path: 'warranty/:product_id/edit/:id', component: UpdateProductWarranty, name: 'update_product_warranty',
            }
        ], beforeEnter: (to, form, next) => {
            check_auth(next,to.params.locale);
        }
    },
    {
        path: baseUrl+'/:locale/setting', component: Admin, children: [
            {
                path: 'profile', component: Profile, name: 'profile',
            },
            {
                path: 'bank', component: Bank, name: 'bank',
            },
            {
                path: 'change_password', component: ChangePassword, name: 'change_password',
            },
        ]
    },
    {
        path: baseUrl+'/:locale/messages', component: Admin, children: [
            {
                path: '/', component: MessageList, name: 'message-list',
            },
            {
                path: ':id/show', component: MessageContent, name: 'message-content',
            },
            {
                path: 'add', component: AddMessage, name: 'add-message',
            },
        ]
    },
    {
        path: baseUrl+'/:locale/orderProducts', component: Admin, children: [
            {
                path: ':id', component: OrderProducts, name: 'order-products',
            },
            {
                path: ':id/packets', component: OrderProductsPackets, name: 'order-products-packets',
            },
        ]
    },
    {
        path: baseUrl+'/:locale/login', name: 'login', component: Login, beforeEnter: (to, form, next) => {
            check_login(next,to.params.locale);
        }
    },
    {
        path: baseUrl+'/:locale/register', component: Register, beforeEnter: (to, form, next) => {
            check_login(next,to.params.locale);
        }
    }
];
const router = new VueRouter({
    mode: 'history',
    routes
})


export default router;
