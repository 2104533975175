<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <div class="panel">
            <div class="header">{{__("add new :name",{'name':__('message')})}}</div>
            <div class="panel_content">
                <div v-if="errors.length>0" class="alert alert-warning">
                    <ul class="errors_ul">
                        <li v-for="(error,key) in errors" :key="key">{{(key+1)+" - "+error}}</li>
                    </ul>
                </div>

                <div class=" mx-1">

                    <div class="input-group my-1 row"  >
                        <label class="my-2 col-12 col-sm-12 col-md-2 col-lg-1">{{__('title :name',{name:__('message')})}}</label>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <input type="text" class="form-control w-100 " v-model="title" :placeholder="__('title :name',{name:__('message')})">
                        </div>
                        <span v-if="title_error" class="has_error col-12 col-sm-12 col-md-2 col-lg-5">{{ title_error }}</span>
                    </div>


                    <div class="input-group my-1 row" >
                        <label class="my-2 col-12 col-sm-12 col-md-2 col-lg-1">{{__('content :name',{name:__('message')})}}</label>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                        <textarea class="form-control w-100 "  style="height:250px" v-model="content" :placeholder="__('your :name',{name:__('message')})"></textarea>
                        </div>
                        <span v-if="content_error" class="has_error col-12 col-sm-12 col-md-2 col-lg-">{{ content_error }}</span>
                    </div>
                <div class="form-group">
                    <label class="my-2">{{__('choose :name',{name:__('file')})}}</label>
                    <input type="file" ref="fileInput"  v-on:change="selectFile()">
                </div>
                <div class="form-group text-center justify-content-center my-3">
                    <button class="btn btn-success" style="min-width:300px" v-on:click="addMessage()">{{__("send")}}</button>
                </div>

                </div>

            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
    </div>
</template>

<script>
import myMixin from "@/myMixin";
window.$=require('jquery')
export default {
    name: "AddMessage",
    mixins:[myMixin],
    mounted() {

        this.$emit('setSideBarItem','messages')
        this.account_status=localStorage.getItem('account_status');
        this.BreadCrumbList=[
            {path:this.locale+'/products',title:this.__('manage :name',{'name':this.__('messages')})},
            {path:this.locale+'/products/add',title:this.__('add new :name',{'name':this.__('message')})},
        ];


    },
    data(){
        return {
            BreadCrumbList:[],
            showLoading:false,
            title:[],
            file:null,
            content:null,
            errors:[],
            send_form:true,
            server_error:false,
            title_error:false,
            content_error:false
        }
    },
    methods:{
        selectFile(){
            this.file=this.$refs.fileInput.files[0];
        },
        addMessage(){
            if(!this.send_form)
                return "";
            const check_title=this.validate_field(this.__('title'),this.title,'title_error',2);
            const check_content=this.validate_field(this.__('content'),this.content,'content_error',2);
            if(check_title&&check_content){
                this.errors=[];
                this.showLoading=true;
                const url=this.host+'api/seller/message/add';
                this.send_form=false;
                const app=this;
                const formData=new FormData();
                formData.append('title', this.title)
                formData.append('content',this.content)
                if(this.file!=null)
                    formData.append('pic',this.file)
                this.axios.post(url,formData,this.setHeader(true)).then(response => {
                    this.showLoading = false;
                    this.send_form = true;
                    if(response.data.status=="error") {
                        const errors = response.data.errors;
                        const keys = Object.keys(errors);
                        for (let i = 0; i < keys.length; i++) {
                            this.errors.push(errors[keys[i]][0])
                        }
                    }else if(response.data.status=="ok"){
                        this.$router.push({name:'message-list',params:{success:'ok'}});
                    }else{
                        this.show_server_error();
                    }
                }).catch(err => {
                    app.showLoading = false;
                    app.send_form = true;
                    app.setCatch(err)
                });
            }
        }
    }

}
</script>

<style scoped>

</style>
