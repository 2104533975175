<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>
        <div class="panel">
            <div class="header">
                {{__('product inventory')}}
            </div>
            <div class="panel_content">
                <div v-if="account_status=='active'" class="row">
                    <div  class="row search_form form-inline col-md-8 col-lg-5 col-sm-12 col-12" >
                            <h6>{{__('search')}}</h6>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" :placeholder="__('product title')" v-model="title" >
                                <select  name="stockroom_id" id="stockroom_id" v-model="stockroom_id" class="form-select form-control pointer" >
                                    <option value="all"  disabled selected>{{__("choose :name",{name:__('stockroom')})}}</option>
                                    <option  v-for="row in stockroom" v-bind:value="row.id" v-bind:key="row.id">{{row.name}}</option>
                                </select>
                                <button class="btn btn-primary rounded-0" type="button" id="button-addon1" v-on:click="getList(1)">{{__("search")}}</button>
                        </div>

                    </div>

                    <table class="table table-bordered">
                        <thead>
                        <tr v-if="$parent.hasMobile">
                            <th>#</th>
                            <th>{{__("detail")}}</th>
                        </tr>
                        <tr v-else>
                            <th>#</th>
                            <th>{{__("image")}}</th>
                            <th>{{__("title")}}</th>
                            <th>{{__("warranty")}}</th>
                            <th>{{__("color")}}</th>
                            <th>{{__("stockroom")}}</th>
                            <th>{{__("count")}}</th>
                        </tr>
                        </thead>

                        <tbody>

                        <tr  v-for="(row,key) in list.data" :key="key" v-if="$parent.hasMobile">
                            <td>{{getRow(key)}}</td>
                            <td>
                                <div class="text-center">
                                    <img :src="row.product_warranty.product.imgPath" class="stockroom_img" style="max-height:80px">
                                </div>
                                <div class="max-1line">
                                    <div>{{_(row.product_warranty.product.title)}}</div>
                                    <div>{{__('warranty')+': '+_(row.product_warranty.warranty.name)}}</div>
                                    <div>
                                        <span v-if="row.product_warranty.color.type==1">{{__("color")}}: </span>
                                        <span v-if="row.product_warranty.color.type==2">{{__("size")}}: </span>
                                        <span v-if="row.product_warranty.color.id>0" >
                                  <span>  <b class="border mx-1" :style="[{padding: '0 12px',width:'15px',height:'15px',background:row.product_warranty.color.code}]" ></b> {{_(row.product_warranty.color.name)}} </span>
                                </span>
                                    </div>
                                    <div>
                                        <span>{{__("stockroom")+': '}}</span>
                                        <span v-if="row.stockroom">
                                            {{row.stockroom.name}}
                                        </span>
                                    </div>
                                    <div>
                                        {{__("count")}}: {{row.product_count}}
                                    </div>
                                </div>
                                <div class="more-1line" v-if="$parent.hasMobile" :data-more="__('more')" :data-less="__('read less')">
                                    <i class="fal fa-chevron-circle-up"></i> <span>{{__("more")}}</span>
                                </div>

                            </td>
                        </tr>

                        <tr v-for="(row,key) in list.data" :key="key" v-if="!$parent.hasMobile">
                            <td class="text-center">{{getRow(key)}}</td>
                            <td class="text-center">
                                <img :src="row.product_warranty.product.imgPath" class="product_pic stockroom_img">
                            </td>
                            <td class="text-center">{{_(row.product_warranty.product.title)}}</td>
                            <td class="text-center">{{_(row.product_warranty.warranty.name)}}</td>
                            <td style="width: 150px" class="text-center">
                                <span v-if="row.product_warranty.color.id>0" >
                                  <span>  <b class="border mx-1" :style="[{padding: '0 12px',width:'15px',height:'15px',background:row.product_warranty.color.code}]" ></b> {{_(row.product_warranty.color.name)}} </span>
                                </span>
                            </td>
                            <td class="text-center">
                                <span v-if="row.stockroom">
                                    {{row.stockroom.name}}
                                </span>
                            </td>
                            <td class="text-center" style="width: 70px">
                                {{row.product_count}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <tr v-if="list.data.length==0" class="d-block w-100">
                        <p class="alert alert-info"><i class="fa fa-exclamation-triangle mx-2"></i>{{__("There is no record to display")}}</p>
                    </tr>

                    <pagination :data="list" @pagination-change-page="getList"></pagination>
                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{ __("403 text") }}
                    </p>
                </div>
            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
    </div>
</template>

<script>
import myMixin from "@/myMixin";

export default {
    name: "InventoryList",
    mixins:[myMixin] ,
    data(){
        return {
            showLoading:false,
            account_status:'active',
            server_error:false,
            BreadCrumbList:[],
            title:'',
            list:{data:[]},
            page:1,
            perPage:10,
            stockroom:[],
            stockroom_id:'all'
        }
    },
    mounted() {
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/packets/inventory_list',title:this.__('product inventory')},
        ];
        this.account_status = localStorage.getItem('account_status');
        this.$emit('setSideBarItem','packets');
        this.getStockroomList();
        this.getList();

    },
    methods:{
        getList(page=1){
            const app=this;
            this.page=page;
            this.showLoading=true;
            const title=this.title.trim().length>0?"&title="+this.title:"";
            const stockroom_id="&stockroomId="+this.stockroom_id;
            const url=this.host+"api/seller/getStockroomProductList?page="+this.page+title+stockroom_id;

            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    this.list=response.data;
                    this.perPage=response.data.per_page;
                }
            }).catch(err=>{
                app.showLoading=false;
                app.setCatch(err)
            })
        },
        getStockroomList(){
            const url=this.host+"api/seller/StockroomList";
            const app=this;
            this.axios.get(url,this.setHeader()).then(response=>{
                if(this.setAccountStatus(response.data)){
                    this.stockroom=response.data;

                }
            }).catch(err=>{
                app.setCatch(err);
            })
        },
    }
}
</script>

<style scoped>

</style>
