<template>
    <div>
        <loading v-if="showLoading"></loading>
        <breadcrumb :data="BreadcrumbList"></breadcrumb>
        <account-status :account_status="account_status"></account-status>
        <div class="panel">

            <div class="header">
                {{__("images gallery")+" - "+title}}
            </div>
            <div class="panel_content">
                <div v-if="account_status=='active'">
                    <div v-if="$route.params.next" class="w-100 p-2 w-100 alert alert-success alert-dismissible " role="alert">
                        <p class="small mb-0">{{__("created items")}}</p>
                    </div>
                    <div v-if="show_alert_message" class="alert alert-warning alert-dismissible fade show mb-1" role="alert">
                        <span>{{alert_message}}</span>
                        <button type="button" class="btn-close " data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <vue-dropzone ref="myVueDropzone" @vdropzone-success="uploadSuccess" @vdropzone-error="errorUpload" id="dropzone" :options="initOptions()"></vue-dropzone>
                    <table class="table table-bordered" id="gallery_table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{{__("image")}}</th>
                                <th>{{__("operation")}}</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(img,key) in gallery" :key="key" :id="img.id">
                            <td>{{key+1}}</td>
                            <td>
                                <img :src="img.imgPath" class="img-thumbnail">
                            </td>
                            <td>
                                <span class="fa fa-remove text-danger pointer" v-on:click="remove_image(img.id,key)"></span>
                            </td>
                        </tr>
                        <tr v-if="gallery.length==0">
                            <td colspan="3">{{__("There is no record to display")}}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="d-flex justify-content-end">
                        <a class="btn btn-primary" v-on:click="returnBack()">
                            <i class="fal fa-save"></i>
                            {{__("do")}}
                        </a>
                    </div>


                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{__("403 text")}}
                    </p>
                </div>
            </div>
        </div>
        <div class="message_div" v-if="showMessageDialog">
            <div class="message_box text-center">
                <p class="text-center" id="msg">{{__("Do you :action this information",{'action':__('delete')})}}</p>
                <button class="alert alert-success" v-on:click="remove_img_of_list">{{__("yes")}}</button>
                <button class="alert alert-danger " v-on:click="showMessageDialog=!showMessageDialog" >{{__("no")}}</button>
            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import 'jquery-ui/ui/widgets/sortable';
import myMixin from "@/myMixin";
const token=localStorage.getItem('token');
export default {
name: "Gallery",
    components: {
        vueDropzone: vue2Dropzone
    },
    mixins:[myMixin],
    data(){
        return {
            server_error:false,
            showLoading:false,
            account_status:'active',
            BreadcrumbList:[],
            base:'',
            title:'',
            gallery:[],
            selected_key:0,
            selected_id:0,
            showMessageDialog:false,
            show_alert_message:false,
            alert_message:'',
            product_id:0,
            do_change:false
        }
    },
    mounted() {
        this.BreadCrumbList=[
            {path:this.locale+'/products',title:this.__('manage :name',{'name':this.__('products')})},
            {path:this.locale+'/products/seller/'+this.$route.params.id+'/gallery',title:this.__('manage :name',{'name':this.__('gallery')})},
        ];
        this.$emit('setSideBarItem','products')
        this.getGallery();
        this.product_id=this.$route.params.id;
        const app=this;
        /* global $ */
        const sortable=$('#gallery_table tbody');
        sortable.sortable({
            stop:function(){
                app.showLoading=true;
                app.do_change=true;
                const parameters=sortable.sortable('toArray');
                const url=app.host+"api/seller/product/gallery/change_image_position/"+app.product_id;
                const formData=new FormData();
                formData.append('parameters',parameters);
                app.axios.post(url,formData,app.setHeader()).then(response=>{
                    app.showLoading=false;
                    if(app.setAccountStatus(response.data)){
                            if(response.data=='error')
                                app.show_server_error();

                    }
                }).catch(err=>{
                    app.setCatch(err)
                });
            }
        })

    },
    methods:{
        uploadSuccess:function (file,response){
            if(response['status']!=undefined){
                this.do_change=true;
                this.gallery.push({id:response['id'],'imgPath':response['imgPath']});
            }
        },
        errorUpload:function (){
            alert("errored");
        },
        initOptions(){
            const options= {
                url:this.host+"api/seller/product/"+this.$route.params.id+"/gallery",
                thumbnailWidth: 150,
                maxFilesize: 5,
                maxFiles:7,
                addRemoveLinks:true,
                acceptedFiles:'.png,.jpg,.jpeg',
                headers: {  'Accept': 'application/json', 'Authorization': 'Bearer ' +token },
            };
            return options;
        },
        getGallery(){
            const app=this;
            const url=this.host+"api/seller/product/"+this.$route.params.id+"/gallery";
            this.axios.get(url,this.setHeader()).then(response=>{
                if(this.setAccountStatus(response.data)){
                    if(response.data.product!=undefined){
                        this.title=this._(response.data.product.title);
                        this.gallery=response.data.product_gallery
                    }else if(response.data=='redirect'){
                        this.$router.push({name:'product_list'})
                    }else {
                        this.show_server_error()
                    }
                }

            }).catch(err=>{
               app.setCatch(err)
            });
        },
        remove_image(id,key){
            this.selected_id=id;
            this.selected_key=key;
            this.showMessageDialog=true;
        },
        remove_img_of_list(){
            const app=this;
            app.do_change=true;
            this.showMessageDialog=false;
            this.show_alert_message=false;
            this.showLoading=true;
            const  url=this.host+"api/seller/product/gallery/"+this.selected_id;
            this.axios.delete(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    if(response.data['status']=='ok'){
                        this.$delete(this.gallery,this.selected_key);
                        this.show_alert_message=true;
                        this.alert_message=this.__('success :action :name',{'action':this.__('delete'),'name':this.__('image')});
                    }else{
                        this.show_server_error();
                    }
                }
            }).catch(err=>{
                app.setCatch(err)
            });
        }
        ,returnBack(){
            let p={}
            if(this.$route.params.next||this.do_change==true)
                p={success:'ok',message:this.__('saved')}
            this.$router.push({name:'product_list',params:p})
        }
    }
}
</script>

<style scoped>

</style>
