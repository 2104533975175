import { render, staticRenderFns } from "./InventoryList.vue?vue&type=template&id=765b72ec&scoped=true&"
import script from "./InventoryList.vue?vue&type=script&lang=js&"
export * from "./InventoryList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "765b72ec",
  null
  
)

export default component.exports