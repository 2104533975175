<template>
    <div>
        <table class="table table-bordered table-striped" >
            <thead>
            <tr>
                <th>#</th>
                <th >{{__("id :name",{name:__('order')})}}</th>
                <th >{{__("recipient name")}}</th>
                <th v-if="!$parent.hasMobile">{{__("submitted at")}}</th>
                <th>{{__("operation")}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(order,key) in orderList.data" :key="order.id">
                <td class="text-center"> {{getRow(key)}} </td>
                <td class="text-center" :style="readUnread(order)">
                    {{order.id}}
                </td>
                <td class="text-center"  >
                    {{order.address.name}}
                </td >
                <td v-if="!$parent.hasMobile" class="text-center">
                    <small> {{ new Date(order.created_at).toLocaleString()}} </small>
                </td>

                <td class="text-center">
                    <router-link :to="'/'+locale+'/orderProducts/'+order.id" class="btn btn-outline-primary btn-sm" >
                        <small >
                           <i class="fal fa-eye" v-if="!$parent.hasMobile"></i>
                            {{__('detail')}}
                        </small>
                    </router-link>
                </td>
            </tr>
            </tbody>
        </table>
        <tr v-if="orderList.data.length==0" class="d-block w-100">
            <p class="alert alert-info"><i class="fa fa-exclamation-triangle mx-2"></i>{{__("There is no record to display")}}</p>
        </tr>



    </div>
</template>

<script>

import myMixin from "@/myMixin";

export default {
name: "OrderTable",
    mixins:[myMixin],
    props:['orderList','page','perPage'],
    methods:{
        readUnread(order){
            const color={color:'black'};
            for (const o of order.get_order_product) {
                if(o.seller_read=="no")
                    return {color:'red'};
            }
            return color;
        }
    }
}
</script>

<style scoped>

</style>
