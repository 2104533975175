<template>
<div >
    <loading v-if="showLoading"></loading>
    <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
    <account-status :account_status="this.account_status"></account-status>
    <alert :error="alertError" :state="alertState"></alert>

    <div class="panel" style="height:1000px" >
        <div class="header">
            {{__("manage :name",{'name':__('all packets')})}}
        </div>

        <div class="panel_content p-0">
            <div v-if="account_status=='active'" class="row">
                <div class="panel_content ">
                    <div class="row mb-3 border" v-for="(op,key) in ops" :key="key" >
                        <div class="col-auto d-inline-flex justify-content-start p-1">
                            <span class="mx-1">{{key+1}})</span>
                            <div>
                                <div class="mx-auto d-flex" style="max-width:300px;">
                                    <img class="img-small mx-auto" :src="op.product.thumbPath" :alt="_(op.product.title)">
                                    <ul class="p-0 mb-0">
                                    <li class="mx-1 text-primary">{{ _(op.product.title) }}</li>
                                    <li v-if="op.color"><small><i class="fa fa-check fa-xs mx-1"></i><b> {{ _(op.color.name) }}</b></small></li>
                                    <li  v-if="op.warranty"><small><i class="fa fa-check fa-xs mx-1"></i><b> {{ _(op.warranty.name) }}</b></small></li>
                                    <li><small><i class="fa fa-check fa-xs mx-1"></i><b>{{__("total :name",{name:__('count')})}} : {{op.product_count}}</b></small></li>
                                    </ul>
                                </div>

                            <div class="d-block">
                                <ul class="p-0 mb-0">
                                    <li><small><i class="fa fa-check fa-xs mx-1"></i><b>{{__("seller must send")}} : {{op.seller_product_count}}</b></small></li>
                                    <li><small><i class="fa fa-check fa-xs mx-1"></i>{{__("shop must send")}} : {{op.shop_product_count}}</small></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div class="p-0" style="width:2px;background-color: #b5b2b2;" v-if="!$parent.hasMobile"></div>
                        <div :class="'col-7 m-auto '+($parent.hasMobile?'border-top w-100 mt-1':'')">
                            <div class="mt-2 justify-content-end d-flex p-0 m-0 packet_from" ref="rowBox" v-if="op.seller_product_count>op.seller_ship_count">
                                <div  >
                                    <div > <small class="p-1 mb-1 " ><b>{{__("submit :name",{'name':__('packet')})}}</b></small></div>
                                    <div class="border border-info row py-1 m-0 rounded shipment_inputs">
                                        <div class="col col-lg-4 col-md-6 col-sm-12 col-12 pb-0 mb-0">
                                            <label class="p-1 w-100 bg-light">{{__(':name name',{name:__('company post')})}}</label>
                                            <input type="text" class="form-control w-100  border-info" v-model="post_name[key]" v-on:change="postNameChange(key)"  :placeholder="__(':name name',{name:__('company post')})" >
                                        </div>
                                        <div class="col col-lg-4 col-md-6 col-sm-12 col-12 pb-0 mb-0">
                                            <label class="p-1 w-100 bg-light">{{__('count')}}</label>
                                            <input type="number" class="form-control w-100  border-info" v-model="count[key]" v-on:keyup="countChange(key,op)" :placeholder="__('shipping count')" >
                                        </div>
                                        <div class="col col-lg-4 col-md-12 col-sm-12 col-12 pb-0 mb-0">
                                            <label class="p-1 w-100 bg-light">{{__('code :name',{name:__('order tracking')})}}</label>
                                            <input type="text" class="form-control w-100  border-info" v-model="code[key]" v-on:change="codeChange(key)" :placeholder="__('can empty :name',{name:''})" >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="op.shipment_code!=null&&op.shipment_code!=[]">
                                <div class="mt-2"  v-if="op.seller_product_count>0&&op.shipment_code.seller&&op.shipment_code.seller.length>0">
                                    <h6><i class="fal fa-check"></i>{{__("seller must sent")}}</h6>
                                    <table class="table-bordered table table-striped">
                                        <thead>
                                        <tr v-if="!$parent.hasMobile">
                                            <th>#</th>
                                            <th>{{ __('company post') }}</th>
                                            <th>{{__("count")}}</th>
                                            <th>{{__("code")}}</th>
                                            <th>{{__("status")}}</th>
                                        </tr>
                                        <tr v-else>
                                            <th>#</th>
                                            <th>{{__("detail :name",{name:__('packet')})}}</th>
                                        </tr>
                                        </thead>
                                        <tbody v-if="!$parent.hasMobile">
                                        <tr v-for="(obj,k) in op.shipment_code.seller" :key="k" >
                                            <td class="text-center">{{k+1}}</td>
                                            <td class="text-center">{{obj.post}}</td>
                                            <td class="text-center">{{obj.count}}</td>
                                            <td class="text-center">{{obj.code==null?'--':obj.code}}</td>
                                            <td class="text-center">
                                                <select v-if="obj.state=='sent'" :id="'select_'+obj.id" class="pointer"  v-on:change="changeState(key+'_'+k,op.id,obj.id)">
                                                    <option value="sent" selected>{{__("sent")}}</option>
                                                    <option value="delivered">{{__('delivered')}}</option>
                                                </select>
                                                <span v-else-if="obj.state=='delivered'">{{__('delivered')}}</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tbody v-else>
                                        <tr v-for="(obj,k) in op.shipment_code.seller" :key="k">
                                            <td class="text-center">{{k+1}}</td>
                                            <td class="text-center">
                                                <div>{{ __('company post') +':'+ obj.post}}</div>
                                                <div>{{__("count")+':'+obj.count }}</div>
                                                <div>{{__("code")+':'+ obj.code==null?'--':obj.code}}</div>
                                                <div>
                                                    {{__("status")+':' }}

                                                    <select v-if="obj.state=='sent'" :id="'select_'+obj.id" class="pointer" v-on:change="changeState(key+'_'+k,op.id,obj.id)">
                                                        <option value="sent" selected>{{__("sent")}}</option>
                                                        <option value="delivered">{{__('delivered')}}</option>
                                                    </select>
                                                    <span v-else-if="obj.state=='delivered'">{{__('delivered')}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="mt-2" v-if="op.shop_product_count>0&&op.shipment_code.shop&&op.shipment_code.shop.length>0">
                                <h6><i class="fal fa-check"></i>{{__("shop must sent")}}</h6>
                                <table class="table-bordered table table-striped">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{{__("count")}}</th>
                                        <th>{{ __('company post') }}</th>
                                        <th>{{__("code")}}</th>
                                        <th>{{__("status")}}</th>
                                    </tr>
                                    </thead>
                                    <tbody >
                                    <tr v-for="(obj,key) in op.shipment_code.shop" :key="key">
                                        <td class="text-center">{{key+1}}</td>
                                        <td class="text-center">{{obj.count}}</td>
                                        <td class="text-center">{{obj.post}}</td>
                                        <td class="text-center">{{obj.code==null?'--':obj.code}}</td>
                                        <td class="text-center">
                                            <span >{{__(obj.state)}}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            </div>
                            <div class="h-100 w-100 text-center p-0 m-0 text-info align-items-center d-flex" style="min-height:100px"  v-if="op.seller_product_count<1&&op.shop_product_count>0&&op.shipment_code!=null&&op.shipment_code.shop&&op.shipment_code.shop.length<1">
                                <b class="m-auto">
                                    <i class="fa fa-exclamation-circle mx-1"></i>{{__("shop must send")}}
                                </b>
                            </div>
                        </div>

                    </div>

                    <div class="justify-content-end align-items-center d-flex w-100"  >
                        <button class="btn-info text-white btn rounded mx-1" v-on:click="showMessageDialog=1" v-if="count.length>0" :disabled="this.post_name.join('').split('').length<1">
                            <i class="fal fa-shipping-fast"></i>
                            {{__("o.send to post")}}
                        </button>
                        <button class="btn-primary text-white btn rounded mx-1" v-if="hasSelect" v-on:click="showMessageDialog=2" :disabled="Object.values(toDelivered).length<1" >
                        <i class="fal fa-shipping-fast"></i>
                        {{__("o.customer receive")}}
                    </button>
                    </div>




                </div>

            </div>
            <div v-else>
                <p class="py-5 text-center h2">
                    <i class="far fa-exclamation-circle"></i>
                    {{ __("403 text") }}
                </p>
            </div>
        </div>

        <div class="message_div" v-if="showMessageDialog>0">
            <div class="message_box text-center">
                <p class="text-center mb-0" id="msg">{{this.msgText}}</p>
                <button class="alert alert-danger " v-on:click="showMessageDialog=0" >{{__("no")}}</button>
                <button class="alert alert-success" v-on:click="submitPackets()">{{__("yes")}}</button>
            </div>
        </div>

    </div>



</div>
</template>

<script>
import alert from "@/components/Alert"
export default {
name: "OrderProductsPackets",
    components:{alert},
data(){
    return{
        oId:null,
        opIds:[],
        account_status:'active',
        showLoading:true,
        BreadCrumbList:[],
        server_error:false,
        alertError:'',
        alertState:'',
        ops:{},
        count:[],
        post_name:[],
        code:[],
        showMessageDialog:0,
        msgText:"",
        toDelivered:{},
        hasSelect:false,

    }
},
    mounted(){

    /* global $ */
        if(this.check()!==true)
            return false;
        this.account_status = localStorage.getItem('account_status');
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/orders/',title:this.__('manage :name',{'name':this.__('orders')})},
            {path:"/"+this.locale+'/orderProducts/'+this.oId,title:this.__('order details')},
        ];
        this.getOPS();
        $(function () {
            var focusedElement;
            $(document).on('focus', 'input', function () {
                if (focusedElement == this) return;
                focusedElement = this;
                setTimeout(function () { focusedElement.select(); }, 100);
            });
        });

    },
    methods:{
        check(){
            this.oId=this.$route.params.id;
            this.opIds=this.$route.params.opIds;
            if(this.oId==null)
                return  this.$router.replace({name:'orders', params:{locale:this.locale}});
            if(this.opIds==null||!Array.isArray(this.opIds)||this.opIds.length<1)
                return  this.$router.replace({name:'order-products', params:{id:this.oId}});
            return true;
        },
        getOPS(){
            const app=this;
            app.stop=true;
            const formData=new FormData();
            formData.append('id',app.oId);
            for (const id of app.opIds) {
                formData.append('opIds[]',id);
            }
            const url=this.host+"api/seller/getPackets"
            app.axios.post(url,formData,this.setHeader(false)).then(response=>{
                app.showLoading=false;
                app.stop=false;
                if(response.data.error!=undefined){
                        app.alertState='';
                         return app.alertError=response.data.error;
                }else{
                    this.ops=response.data;
                    for (const k in this.ops) {
                        this.count[k]=this.ops[k].seller_product_count-this.ops[k].seller_ship_count
                        this.post_name[k]='';
                        this.code[k]='';
                    }
                }
            }) .catch((err)=>{
                console.log(err)
                app.server_error=true;
            })

        },
        changeState(k,opId,pakId){
            if($('#select_'+pakId).val()=='sent'){
                this.$delete(this.toDelivered,k)
            }else{
                this.$set(this.toDelivered,k,[opId,pakId])
            }
        },
        postNameChange(k){
             const val=this.post_name[k];
            const post_name=this.post_name;
            for (const j in post_name) {
                if(j==k||j>k&&post_name[j].trim().length<1&&val.trim().length>0){
                    post_name[j]=val;
                }
            }
            this.post_name=[];
            this.post_name=post_name;
        },
        codeChange(k){
            const val=this.code[k];
            const code=this.code;
            for (const j in code) {
                if(j==k||j>k&&code[j].trim().length<1&&val.trim().length>0){
                    code[j]=val;
                }
            }
            this.code=[];
            this.code=code;
        },
        countChange(k,op){
            const remain=op.seller_product_count-op.seller_ship_count;
            let count=this.count;
            if(this.count[k]>remain)
                count[k]=remain;
            if(this.count[k]<1)
                count[k]=1;
            this.count=[];
            this.count=count;
        },
        submitPackets(){
            switch (this.showMessageDialog) {
                case 1:
                    this.sendPackets();
                break;
                case 2:
                    this.delivered()
                break;
                default:
                    this.showMessageDialog=0;
            }
        },
        sendPackets(){
            const ap=this;
            if(ap.stop)
                return true;
            ap.stop=true;
            ap.showLoading=true;
            ap.showMessageDialog=false;
            let rows={};
            let k=0;
            for (const op of ap.ops) {
                if(ap.post_name[k]&&ap.post_name[k].trim().length>0)
                    rows[op.id]={post_name:ap.post_name[k],count:ap.count[k],code:ap.code[k]}
                k++;
            }
            if(Object.values(rows).length<1)
                return true;
            const url=ap.host+"api/seller/addPackets";
            ap.axios.post(url,{'rows':rows,'id':ap.oId},ap.setHeader(true)).then(response=>{
                ap.showLoading=false;
                ap.stop=false;
                if(response.data.error!=undefined){
                    ap.alertState='';
                    return ap.alertError=response.data.error;
                }else{
                    for (const dt of response.data) {
                        for (const k in ap.ops) {
                            if (dt.id == ap.ops[k].id) {
                                for (const q in ap.ops[k]) {
                                    if(dt[q]!=undefined)
                                        ap.ops[k][q] = dt[q];
                                }
                                ap.count[k] = ap.ops[k].seller_product_count - ap.ops[k].seller_ship_count
                                ap.post_name[k] = '';
                                ap.code[k] = '';
                            }
                        }
                    }
                }
            }) .catch((err)=>{
                console.log(err)
                ap.server_error=true;
                ap.showLoading=false;
                ap.stop=false;
            })

        },
        delivered(){
            const ap=this;
            if(ap.stop)
                return true;
            ap.stop=true;
            ap.showLoading=true;
            ap.showMessageDialog=false;
            const url=ap.host+"api/seller/deliveredPackets";
             const fd=new FormData();
            for (const it of  Object.values(ap.toDelivered)) {
                fd.append("rows["+ it[1]+"]",it[0]);
            }
            fd.append('id',ap.oId);
            ap.axios.post(url,fd,ap.setHeader(false)).then(response=>{
                ap.showLoading=false;
                ap.stop=false;
                if(response.data.error!=undefined){
                    ap.alertState='';
                    return ap.alertError=response.data.error;
                }else{
                    for (const dt of response.data) {
                        for (const k in ap.ops) {
                            if (dt.id == ap.ops[k].id) {
                                for (const q in ap.ops[k]) {
                                    if(dt[q]!=undefined)
                                        ap.ops[k][q] = dt[q];
                                }
                                ap.count[k] = ap.ops[k].seller_product_count - ap.ops[k].seller_ship_count
                                ap.post_name[k] = '';
                                ap.code[k] = '';
                            }
                        }
                    }
                    ap.toDelivered={};
                }

            }) .catch((err)=>{
                console.log(err)
                ap.server_error=true;
                ap.showLoading=false;
                ap.stop=false;
            })
        }
    },
    watch:{
        showMessageDialog(v){
            switch (v) {
                case 1:
                    this.msgText=this.__('sure sent packets')
                    break;
                case 2:
                    this.msgText=this.__('do sure change status to :state',{state:"'"+this.__('delivered')+"'"})
                break;
                default:
                    this.msgText="";
                break;
            }
        },
        ops(o){
            for (const v of o) {
                if(v.seller_shipping_status!="unset"||v.seller_shipping_status!="")
                   return this.hasSelect=true;
            }
        }
    }
}
</script>

<style scoped>

</style>
