<template>
    <div class="server_error_box" >
        <div>
            <span class="fal fa-warning mx-1"></span>
            <span id="message">{{__("errored")+" - "+__("try again")}}</span>
        </div>
    </div>
</template>

<script>
export default {
name: "ServerErrorMessage"
}
</script>

<style scoped>

</style>
