<template>
    <div>
        <loading v-if="showLoading"></loading>
        <breadcrumb :data="BreadcrumbList"></breadcrumb>
        <account-status :account_status="account_status"></account-status>

        <div class="panel">

            <div class="header">
                {{__('manage :name',{'name':__('product')})}}
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" >
                        {{__('options')}}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li>
                            <router-link :to="'/'+this.locale+'/products/add'" class="dropdown-item" >
                                <span class="btn btn-sm btn-outline-success rounded-circle"><i class="fal fa-add"></i></span>
                                {{__("add new :name",{'name':__('product')})}}
                            </router-link>
                        </li>

                    </ul>
                </div>


            </div>
            <div class="panel_content">
                <form method="get" class="row search_form form-inline col-md-8 col-lg-5 col-sm-12 col-12" >
                        <h6>{{__('search part')}}</h6>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :placeholder="__('search text')" v-model="search_title"  aria-label="Example text with button addon" aria-describedby="button-addon1">
                            <button class="btn btn-primary rounded-0" type="button" id="button-addon1" v-on:click="getProduct(1)">{{__("search")}}</button>
                        </div>
                <div class="my-1">
                    <a class="btn btn-info text-white"  v-on:click="show_product_list=true"><i class="fal fa-search mx-1"></i>{{__("search in shop products")}}</a>
                </div>
                </form>

                <div class="m-0 p-0" id="box_alert">
                    <div v-if="show_alert_message"  :class="'alert  alert-dismissible fade show mb-1 '+(alert_message==__('saved')?'alert-success':'alert-warning')" role="alert">
                        <span>{{alert_message}}</span>
                        <button type="button" class="btn-close " data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </div>
                <table class="table table-bordered table-striped" >
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{{__("image")}}</th>
                        <th>{{__("title")}}</th>
                        <th v-if="!$parent.hasMobile">{{__("status")}}</th>
                        <th>{{__("operation")}}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(product,key) in productList.data" :key="product.id">
                            <td class="text-center">  {{getRowDesc(key,perPage,totalPage)}} </td>
                            <td class="text-center"> <img class="img-fluid product_pic" :src="product.imgPath" /> </td>
                            <td class="text-center">  {{_(product.title)}} </td>
                            <td class="text-center" v-if="!$parent.hasMobile"> <span :class="'p-1 fs-14 '+productStatusClass[product.status+3]">  {{productStatus[product.status+3]}} </span></td>
                            <td class="text-center">
                                <span v-if="$parent.hasMobile" :class="'w-100 p-1 text-truncate fs-14 mb-2 d-block '+productStatusClass[product.status+3]">  {{productStatus[product.status+3]}} </span>

                                <div class="dropdown w-auto text-center ">
                                    <button class="btn btn-outline-primary dropdown-toggle" type="button" :id="'dropdownMenuButton'+key" data-bs-toggle="dropdown" aria-expanded="false" >
                                        {{__('options')}}
                                    </button>
                                    <ul class="dropdown-menu w-auto dropdown-menu-end min-w-auto" :aria-labelledby="'dropdownMenuButton'+key">
                                        <li class="pointer" v-if="product.seller_id==seller_id" v-on:click="$router.push({name:'update_product',params:{id:product.id}})">
                                            <span class="dropdown-item px-2" >
                                               <i class="fal fa-edit"></i>
                                                {{__('edit :name',{name:__('product')})}}
                                            </span>
                                        </li>

                                        <li class="pointer" v-if="product.seller_id==seller_id" v-on:click="$router.push({name:'items',params:{id:product.id}})">
                                            <span class="dropdown-item px-2" >
                                               <i class="fal fa-list"></i>
                                                {{__('manage :name',{name:__('items')})}}
                                            </span>
                                        </li>

                                        <li class="pointer" v-on:click="$router.push({name:'product_warranty',params:{id:product.id}})">
                                            <span class="dropdown-item px-2" >
                                               <i class="fal fa-line-chart"></i>
                                                {{__('manage :name',{name:__('product warranty')})}}
                                            </span>
                                        </li>


                                        <li class="pointer" v-if="product.seller_id==seller_id" v-on:click="$router.push({name:'gallery',params:{id:product.id}})">
                                            <span class="dropdown-item px-2" >
                                               <i class="fal fa-image"></i>
                                                {{__('images gallery')}}
                                            </span>
                                        </li>

                                        <li class="pointer" v-if="product.status==-2||product.status==-3&&product.seller_id==seller_id"  v-on:click="remove_product(product.id)">
                                            <span class="dropdown-item px-2" >
                                               <i class="fal fa-trash"></i>
                                                {{__('remove')}}
                                            </span>
                                        </li>

                                    </ul>
                                </div>

                            </td>
                        </tr>

                    </tbody>
                </table>
                <tr v-if="productList.data.length==0" class="d-block w-100">
                    <p class="alert alert-info"><i class="fa fa-exclamation-triangle mx-2"></i>{{__("There is no record to display")}}</p>
                </tr>


                <pagination :data="productList" @pagination-change-page="getProduct"></pagination>
            </div>
        </div>

        <div class="message_div" v-if="showMessageDialog">
            <div class="message_box text-center">
                <p class="text-center" id="msg">{{__("Do you :action this information",{'action':__('delete')})}}</p>
                <button class="alert alert-success" v-on:click="remove_product_of_list">{{__("yes")}}</button>
                <button class="alert alert-danger " v-on:click="showMessageDialog=!showMessageDialog" >{{__("no")}}</button>
            </div>
        </div>
    <ServerErrorMessage v-if="server_error"></ServerErrorMessage>

        <total-product-list v-if="show_product_list" ></total-product-list>
    </div>
</template>

<script>

import myMixin from "@/myMixin";
import TotalProductList from "@/components/TotalProductList";
export default {
name: "ProductList",
    components:{TotalProductList},
    mixins:[myMixin],
    data(){
    return {
        showLoading:true,
        productList:{data:[]},
        BreadcrumbList:[],
        account_status:'active',
        page:1,
        perPage:10,
        totalPage:10,
        productStatus:[],
        productStatusClass:[],
        seller_id:-1,
        showMessageDialog:false,
        server_error:false,
        show_alert_message:false,
        alert_message:'',
        search_title:'',
        show_product_list:false
    }
    },
    mounted() {
        this.account_status=localStorage.getItem('account_status')
        this.seller_id=localStorage.getItem('seller_id')
        if(this.$route.params.success=="ok"){
            this.show_alert_message=true;
            this.alert_message=this.__('product submitted')
            if(this.$route.params.message!=undefined)
                this.alert_message=this.$route.params.message
            window.scrollTo({top:document.getElementById('box_alert').offsetTop-10,behavior: 'smooth'});
        }
        this.getProduct();
        this.$emit('setSideBarItem','products')
        this.BreadCrumbList=[
            {path:this.locale+'/products',title:this.__('manage :name',{'name':this.__('products')})},
        ];
        this.productStatus=[this.__('unaccepted'),this.__('waiting'),this.__('stopped'),this.__('unavailable'),this.__('available')];
        this.productStatusClass=['alert alert-danger','alert alert-warning','alert alert-secondary','alert alert-primary','alert alert-success'];
        },
    methods:{
        getProduct(page=1){
            const app=this;
        this.showLoading=true;
        this.page=page;
        const url=this.host+"api/seller/product/list?page="+page+"&search_text="+this.search_title;
        this.axios.get(url,this.setHeader()).then(response=>{
            this.showLoading=false;
            if(this.setAccountStatus(response.data)){
                this.perPage=response.data.per_page?response.data.per_page:this.perPage;
                this.totalPage=response.data.total?response.data.total:this.totalPage;
                this.productList=response.data;
            }
        }).catch(err=>{
            app.showLoading=false;
            if(err.response!=undefined && err.response.status==401){
                app.logout();
            }else{
                app.show_server_error();
            }
        })
    },
        remove_product(proId){
            this.showMessageDialog=true;
            this.delete_product_id=proId;
        },
        remove_product_of_list(){
            const app=this;
            this.showLoading=true;
        this.showMessageDialog=false;
        const  url=this.host+'api/seller/product/'+this.delete_product_id;
        this.axios.delete(url,this.setHeader()).then(response=>{
            this.showLoading=false;
            const result=this.setAccountStatus(response.data);
            if(result && response.data!="error"){
                this.productList=response.data;
                this.show_alert_message=true;
                this.alert_message=this.__('removed');
            }
            else{
                this.show_server_error();
            }
        }).catch(err=>{
            app.showLoading=false;
            if(err.response!=undefined && err.response.status==401){
                app.logout();
            }else{
                app.show_server_error();
            }
        })
    },
        close_modal(){
            this.show_product_list=false;
        }
    }
}
</script>

<style scoped>

</style>
