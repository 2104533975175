<template>
    <div class="d-block p-xl-5 p-md-1 p-0">
            <loading v-if="showLoading"></loading>
            <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
            <account-status :account_status="this.account_status"></account-status>


        <div class="panel">
            <div class="header">{{__("report sale")}}</div>
            <div class="panel_content">
                <div v-if="show_alert_message" class="alert alert-warning alert-dismissible fade show mb-1" role="alert">
                    <span>{{alert_message}}</span>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <div v-if="account_status=='active'">

                    <p>
                        <span> {{__('sales per year')}} </span>
                        <select class="w-auto year_tag" v-model="default_year" v-on:change="getData()" ref="default_year">
                            <option value="">{{__('selecting')}}</option>
                            <option v-for="(year,key) in years" v-bind:key="key" v-bind:value="year">{{year}}</option>
                        </select>

                    </p>
                    <highcharts :options="chartOptions" v-if="renderHighChart"></highcharts>
                    <div class="mt-4">
                        <highcharts :options="chartOptions2" v-if="renderHighChart"></highcharts>
                    </div>

                    <table class="table table-striped table-bordered mt-4">
                        <tr class="p-2">
                            <td class="p-2 ">{{__("total :name",{'name':__('sales amount')})}}</td>
                            <td class="bg-light text-center"> {{priceFormat({},total_sale-commission)}} </td>
                        </tr>
                        <tr class="p-2">
                            <td class="p-2 ">{{__('commission deducted')}}</td>
                            <td class="bg-light text-center">{{priceFormat({},commission)}} </td>
                        </tr>
                    </table>

                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{__("403 text")}}
                    </p>
                </div>
            </div>
        </div>

        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
    </div>
</template>

<script>
import myMixin from "@/myMixin";
import {Chart} from 'highcharts-vue'
export default {
name: "SaleStatistics",
    mounted() {
        this.$emit('setSideBarItem','reports');
        this.BreadCrumbList=[
            {path:'/'+this.locale+'/orders',title:this.__('manage :name',{'name':this.__('orders')})},
            {path:'/'+this.locale+'/orders/sale_statistics',title:this.__('report sale')},
        ];
        this.account_status=localStorage.getItem('account_status');
        this.chartOptions.title.text=this.__('sales amount per month');
       // this.chartOptions2.title.text=this.__('receive commission');
        this.getData();
    },
    components:{
        highcharts:Chart
    },
    mixins:[myMixin],
    data(){
        const app=this;
        return {
            renderHighChart:true,
            showLoading:false,
            account_status:'active',
            BreadCrumbList:[],
            server_error:false,
            show_alert_message:false,
            alert_message:'',
            default_year:'',
            years:[],
            chartOptions:{
                series:[{
                    data:[],
                    color:'rgb(244,81,108)',
                    dataLabels:{
                        enabled:true,
                        color:'rgb(136,0,24)',
                        fontWeight:'lighter',
                        formatter:function (){
                            return app.priceFormat(app.currency,this.y);
                        },
                        style:{
                            fontSize:'10px',
                            color:'black',
                            letterSpacing:'0px',

                        },
                    }
                }],
                legend:{
                    enabled:false
                },
                title:{
                    text:'', //app.__('sales amount per month')
                    update:true
                },
                xAxis:{
                    type:'category',
                },
                chart:{
                    type:'column',
                    style: {
                        fontFamily:'iranyekanR'
                    }
                },yAxis: {
                    title: {
                        text: ''
                    },
                    labels:{
                        useHtml:true,
                        formatter:function (){

                            return '<div >' +
                                '<span>'+ app.priceFormat(app.currency,this.value)+'</span>' +
                                '</div>';
                        },
                        style: {
                            fontSize:'15px',
                        }
                    }
                },
                tooltip:{
                    useHtml:true,
                    formatter:function (){
                        return '<div >' +
                            '<p class="" style=""><span >'+(app.__('sales amount per month'))+(this.x)+' : '+'</span>' +
                            '<span style="color:rgb(244,81,108)">'+ (app.priceFormat(app.currency,this.y))+'</span></p>' +
                            '</div>';

                    },
                    style:{
                        fonSize:'16px',
                    }
                },
            },
            chartOptions2:{
                series:[{
                    data:[],
                    dataLabels:{
                        enabled:true,
                        color:'rgb(0,39,136)',
                        formatter:function (){
                            return app.priceFormat(app.currency,this.y);
                        },
                        style:{
                            fontSize:'10px'
                        },
                    }
                }],
                legend:{
                    enabled:false
                },
                title:{
                    text:'',
                    update:true,
                    y:50
                },
                xAxis:{
                    type:'category',
                },
                chart:{
                    type:'column',
                    style: {
                        fontFamily:'iranyekanR'
                    }
                },yAxis: {
                    title: {
                        text: ''
                    },
                    labels:{
                        useHtml:true,
                        formatter:function (){

                            return '<div >' +
                                '<span>'+ app.priceFormat(app.currency,this.value)+'</span>' +
                                '</div>';
                        },
                        style: {
                            fontSize:'15px',
                        }
                    }
                },
                tooltip:{
                    useHtml:true,
                    formatter:function (){
                        return '<div style="width: 210px">' +
                            '<p class="d-block"><span style="display:block!important">'+(app.__('commission per month'))+(this.x)+' : '+'</span></p>' +
                            '<span style="color:rgb(244,81,108);display:block!important">'+ app.priceFormat(app.currency,this.y)+'</span>' +
                            '</div>';
                    },
                    style:{
                        fonSize:'16px'
                    }
                }

            },
            url:'',
            total_sale:0,
            commission:0
        }
},
    methods:{
        getData(){
            /* global $ */
            this.url=this.host+'api/orders/get_sale_report?default_year='+this.default_year;
            this.showLoading=true;
            const app=this;
            app.renderHighChart=false;
            this.axios.get(this.url,this.setHeader()).then(response=>{
                app.showLoading=false;
                app.chartOptions['series'][0]['data']=[];
                app.chartOptions2['series'][0]['data']=[];
                const sale=response.data.sale;
                if(sale!=undefined){
                    $.each(sale,function (key,row){
                        if(key!=0){
                            if(response.data.commission!=undefined&&response.data.commission[key]!=undefined)
                                row-=response.data.commission[key];
                            app.chartOptions['series'][0]['data'].push([key,row]);
                        }
                    })
                }

                const commission=response.data.commission;
                if(commission!=undefined){
                    $.each(commission,function (key,row){

                        if(key!=0){

                            app.chartOptions2['series'][0]['data'].push([key,row]);
                        }
                    })
                }
                app.years=response.data.year_list;
                app.default_year=response.data.default_year;
                app.total_sale=response.data.total_sale
                app.commission=response.data.total_commission;

                app.$nextTick(() => {
                    app.renderHighChart = true;
                });


            }).catch(err=>{
                app.showLoading=false;
                app.setCatch(err);
                app.show_server_error();
            })

        },
    }
}
</script>

<style scoped>

</style>
