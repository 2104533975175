<template>
    <div class="bg-white  p-2">
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>
        <h5 class="text-center d-block mt-3 w-100">{{__("change :name",{name:__('password')})}}</h5>
        <div class=" d-flex justify-content-center text-center">
        <div class="col-md-8 col-lg-4 col-sm-12 my-2 border p-3 shadow-sm rounded">
            <p class="text-center p-2 border rounded border-danger shadow-sm">
                <i class="far fa-shield"></i>
                {{__("enter info")}}
            </p>
        <div class="input-group ">
            <span class="input-group-text  bg-light"  style="height:39px" ><i class="fal fa-lock-keyhole-open"></i></span>
            <input type="password" :class="'form-control '+(password_error.length>0?'border-danger':'')" v-model="password" :placeholder="__('password')" aria-describedby="basic-addon2">
        </div>
           <div class="mb-2" dir="auto">
               <small style="font-size:12px" class="text-danger ">{{password_error}}</small>
           </div>
        <div class="input-group ">
            <span class="input-group-text bg-light "  style="height:39px" ><i class="fal fa-lock-keyhole"></i></span>
            <input type="password" :class="'form-control '+(newPassword_error.length>0?'border-danger':'')" v-model="newPassword" :placeholder="__('new password')" aria-describedby="basic-addon2">
        </div>
            <div class="mb-2" dir="auto">
            <small style="font-size:12px" class="text-danger ">{{newPassword_error}}</small>
            </div>
        <div class="input-group ">
            <span class="input-group-text bg-light "  style="height:39px" ><i class="fal fa-lock-keyhole"></i></span>
            <input type="password" :class="'form-control '+(password_confirmation_error.length>0?'border-danger':'')" v-model="password_confirmation" :placeholder="__('password_confirmation')" aria-describedby="basic-addon2">
        </div>
            <div class="mb-2" dir="auto">
                <small style="font-size:12px" class="text-danger">{{password_confirmation_error}}</small>
            </div>
            <div class="input-group mb-3 btn-danger btn" v-on:click="send_data()">
                <i class="fal fa-sync-alt mx-1"></i>
                {{__("change :name",{'name':__('password')})}}
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import myMixin from "@/myMixin";

export default {
name: "ChangePassword",
    mixins:[myMixin],
    data(){
        return{
            showLoading:false,
            password:'',
            newPassword:'',
            password_confirmation:'',
            password_error:'',
            newPassword_error:'',
            password_confirmation_error:'',
            BreadCrumbList:[],
            account_status:'active',
            send:true
        }
    },
    mounted() {
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/setting/change_password',title:this.__('change :name',{'name':this.__('password')})},
        ];
    },
    methods:{
        send_data(){
                const app=this;
              const checkPass=this.check_pass(this.password,this.__('password'),"password_error");
              const checkNPass=this.check_pass(this.newPassword,this.__('new password'),"newPassword_error");
              const checkRNPass=this.check_pass(this.password_confirmation,this.__('password_confirmation'),"password_confirmation_error");
              if(checkPass&&checkNPass&&checkRNPass){
                  if( this.send==false)
                      return "";
                  this.send=false;
                  const url=this.host+"api/seller/change_password";
                  const formData=new FormData();
                  formData.append('password',this.password);
                  formData.append('newPassword',this.newPassword);
                  formData.append('password_confirmation',this.password_confirmation);
                 this.axios.post(url,formData,this.setHeader()).then(response=>{
                     this.showLoading=false;
                     this.send=true;
                     if(response.data.status=='error'){
                        this[response.data.label]=response.data.error_text;
                     }else if(response.data.status=='ok'){
                          this.logout(this.__('changed password'));
                     }
                 }).catch(err=>{
                     app.showLoading=false;
                     app.send=true;
                     app.setCatch(err)
                 })
              }
        }
        ,
        check_pass(password,translate,error_box){
            password = "" + password;
            if (password.toString().trim().length > 7 && password != undefined) {
                this[error_box] = "";
                return true;
            }
            this[error_box] = this.__('v.gt.string', {'attribute': this.__(translate), 'value': 7})
            return false;
        }
    }
}
</script>

<style scoped>

</style>
