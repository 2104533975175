<template>
    <div class="d-flex justify-content-center">
    <div class="pb-2">
      <div v-if="account_type==1" >
        <h6 class="pb-2"><i class="fal fa-circle fa-xs"></i>{{__("personal info")}}</h6>
        <div class="form-group">
            <label  class="my-1 w-100 p-1">{{__("choose :name",{'name':__('scan :field',{'field':__('national cart')})})}}</label>
            <input class="d-none" type="file" ref="file_input1" v-on:change="set_file(1)">
            <div class="select_file_div" v-on:click="$refs.file_input1.click()">
                <div class="title">{{__("choose :name",{'name':__('file')})}}</div>
                <div class="file_name text-truncate">{{this.file1_name}}</div>
            </div>
        </div>

        <div class="form-group mt-2">
          <label class="my-1 w-100 p-1">{{__("choose :name",{'name':__('scan :field',{'field':__('back national cart')})})}}</label>
          <input class="d-none" type="file" ref="file_input2" v-on:change="set_file(2)">
          <div class="select_file_div" v-on:click="$refs.file_input2.click()">
            <div class="title">{{__("choose :name",{'name':__('file')})}}</div>
            <div class="file_name text-truncate">{{this.file2_name}}</div>
          </div>
        </div>

        <div class="form-group mt-2 p-1">
          <label class="d-block my-1">{{__("tax code")}}</label>
          <input type="text" class="form-control d-block w-100 " ref="tax_code" v-model="tax_code" v-on:leave="this.focus=false" v-on:focus="tax_empty()" :placeholder="__('enter :field',{field:__('tax code')})">
        </div>

      </div>
      <div v-if="account_type==2">
          <h6 class="border-bottom pb-2"><i class="fal fa-circle fa-xs"></i>{{__("submit legal info")}}</h6>
        <div class="form-group mt-2">
          <label class="my-1">{{__("company registration certificate")}}</label>
          <input class="d-none" type="file" ref="file_input1" v-on:change="set_file(1)">
          <div class="select_file_div" v-on:click="$refs.file_input1.click()">
            <div class="title">{{__("choose :name",{'name':__('file')})}}</div>
            <div class="file_name text-truncate">{{this.file1_name}}</div>
          </div>
        </div>

        <div class="form-group mt-2">
          <label class="d-block my-1">{{__("income tax code")}}</label>
          <input type="text" class="form-control d-block w-100" v-model="tax_code" :placeholder="__('enter :field',{field:__('tax code')})">
        </div>
      </div>


        <div v-if="errors.length>0" class="alert alert-warning mt-2">
            <div v-for="(error,key) in errors" :key="key">
               <small> {{(key+1)+'- '+error}}</small>
            </div>
        </div>


        <div class="form-group d-flex justify-content-center">

            <button type="submit" class="send_btn register_btn" v-on:click="send_data()">
                                <span class="line">
                                    <i class="fal fa-upload"></i>
                                </span>
                <span class="title">  {{__("send :name",{'name':__('file')})}}</span>
            </button>

        </div>

        <div class="alert alert-warning" id="spinner" v-if="send_form">
            <div class="spinner-border text-warning" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <span style="padding-right:10px">{{__("sending information")}}</span>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: "RegisterStep4",
    props:['account_type','email_address','lang_id'],
    data(){
        return{
            file1_name:'',
            file2_name:'',
            send_form:false,
            file1:'',
            file2:'',
            tax_code:'',
            errors:[],
            focus:false
        }
    },
  mounted() {
      if(this.account_type==1&&this.tax_code.trim().length==0)
        this.tax_code =this.__('not have')
  },
  methods:{
        set_file(n){
         const name="file"+n;
         const file_input_name="file_input"+n;
         this[name]=this.$refs[file_input_name].files[0];
         this["file"+n+"_name"]=this[name].name;
        },
        send_data(){
          if(this.validate_data()==false){
            return false;
          }
            if(this.send_form==true)
                return ;
            if(this.file1==null){
             return  this.$emit('setServerError',this.__('v.file',{'attribute':this.__('scan :field',{'field':this.__('national cart')})}))
            }
            this.send_form=true;
            this.$emit('setServerError',false)
            const form_data=new FormData();
            form_data.append('account_type',this.account_type)
            form_data.append('file1',this.file1)
            form_data.append('file2',this.file2)
            form_data.append('email',this.email_address)
            form_data.append('lang_id',this.lang_id);
            form_data.append('tax_code',this.tax_code)
            const app=this;
            this.axios.post(this.host+'api/seller/upload_file',form_data,{headers:{'content-Type':'multipart/form-data'}}).then(response=>{
                this.send_form=false;
                if(response.data.status=='error')
                {
                  return this.$emit('setServerError',response.data.message);
                }else if(response.data.status=='ok'){
                    this.$emit('set_step',4);
                    return this.$emit('setServerError',false);
                }else{
                    if(response.data.error!=undefined){
                        for (const i in response.data.error) {
                            return this.$emit('setServerError',response.data.error[i]);
                        }
                    }
                    return this.$emit('setServerError',this.__('errored'));
                }
            }).catch(function (){
                app.send_form=false;
                app.$emit('setServerError',this.__('errored'));
            })
        },
        validate_data(){
            this.errors=[]
        if(this.account_type==1){
            if((this.tax_code+"").trim().length==0){
                this.errors.push(this.__('v.required',{attribute:this.__('tax code')}))
            }
            this.has_file('file1','national cart');
            this.has_file('file2','back national cart');


        }else if(this.account_type==2){
            if((this.tax_code+"").trim().length==0){
                this.errors.push(this.__('v.required',{attribute:this.__('income tax code')}))
            }
            this.has_file('file1','company registration certificate');
        }

        if(this.errors.length==0)
            return true;
        return false;

    },
      has_file(input,field_name){
            if(this[input].type==undefined){
                this.errors.push(this.__('v.required',{attribute:this.__(field_name)}))
                return false;
            }else if(!(this[input].type+"").includes("image")){
                this.errors.push(this.__('v.image',{attribute:this.__(field_name)}))
                return false;
            }
            if(this[input].size==undefined||this[input].size>5245329){
                this.errors.push(this.__('v.lte.file.mg',{attribute:this.__(field_name),value:5}))
                return false;
            }
            return true;
      },
      tax_empty(){
            this.focus=true;
            this.tax_code='';
      }
    },
      watch:{
          tax_code(){
              if(this.account_type==1&&this.tax_code.trim().length==0&&this.focus==false){
                  this.tax_code=this.__('not have')
              }
          }
      }
}
</script>

<style scoped>

</style>
