<template>
    <div>

        <div class="admin_panel container-fluid">

            <loading v-if="showLoading"></loading>

            <div class="page_sidebar page_sidebar-disable" id="page_sidebar">
                <span class="fa fa-bars" v-on:click="Toggled()" id="sidebarToggle"></span>

                <ul id="sidebar_menu">
                    <li>
                        <a v-bind:href="host">
                            <i class="mx-1 fal fa-globe"></i>
                            <span class="sidebar_menu_text">{{__("see :field",{'field':__('shop')})}}</span>
                        </a>
                    </li>
                    <li>
                        <a v-on:click="show_child_menu('products')" :class="active_item=='products'?'active_li':''">
                            <i class="mx-1 fal fa-shopping-cart"></i>
                            <span class="sidebar_menu_text">{{__("products")}}</span>
                            <span class=" fa fa-angle-left"></span>
                        </a>
                        <div class="child_menu" ref="products" :class="active_item=='products'?'active_child child_menu':'child_menu'">
                            <router-link :to="'/'+this.locale+'/products'">{{__("manage :name",{'name':__('product')})}}</router-link>
                            <router-link :to="'/'+this.locale+'/products/add'">{{__("add new :name",{'name':__('product')})}}</router-link>
                        </div>

                    </li>

                    <li v-on:click="active_me('orders')">
                        <router-link :to="'/'+this.locale+'/orders'" id="side_orders" :class="active_item=='orders'?'active_li':''">
                            <i class="mx-1 fal fa-list"></i>
                            <span class="sidebar_menu_text">{{__("manage :name",{'name':__('orders')})}}</span>
                            <label id="orderCount" class="rounded-circle bg-warning mx-1 w-auto text-dark" style="padding:0 3%">{{this.config_order_count}}</label>
                        </router-link>
                    </li>

                    <li>
                        <a v-on:click="show_child_menu('reports')" :class="active_item=='reports'?'active_li':''">
                            <i class="mx-1 fal fa-line-chart"></i>
                            <span class="sidebar_menu_text">{{__("reports")}}</span>
                            <span class=" fa fa-angle-left"></span>
                        </a>
                        <div class="child_menu" ref="reports" :class="active_item=='reports'?'active_child child_menu':'child_menu'">
                            <router-link :to="'/'+this.locale+'/orders/sale_statistics'">{{__("report sale")}}</router-link>
                            <router-link :to="'/'+this.locale+'/orders/payment'">{{__("manage :name",{'name':__('pays')})}}</router-link>
                        </div>

                    </li>


                    <li>
                        <a v-on:click="show_child_menu('packets')" :class="active_item=='packets'?'active_li':''">
                            <i class="mx-1 fal fa-warehouse"></i>
                            <span class="sidebar_menu_text">{{__("stockroom")}}</span>
                            <span class=" fa fa-angle-left"></span>
                        </a>
                        <div class="child_menu" ref="packets" :class="active_item=='packets'?'active_child child_menu':'child_menu'">
                            <router-link :to="'/'+this.locale+'/packets'">{{__("manage :name",{'name':__('packets')})}}</router-link>
                            <router-link :to="'/'+this.locale+'/packets/add'">{{__("add new :name",{'name':__('packet')})}}</router-link>
                            <router-link :to="'/'+this.locale+'/packets/inventory'">{{__("product inventory")}}</router-link>
                        </div>
                    </li>


                    <li v-on:click="active_me('messages')">
                        <router-link :to="'/'+this.locale+'/messages'" id="side_messages"  :class="active_item=='messages'?'active_li':''">
                            <i class="mx-1 fal fa-message"></i>
                            <span class="sidebar_menu_text">{{__("manage :name",{'name':__('messages')})}}</span>
                        </router-link>
                    </li>

                    <li v-if="$parent.$data.learns&&$parent.$data.learns.sideSellerPanel">
                        <a class="learn_link" target="_blank" :href="String($parent.$data.learns.sideSellerPanel).replace(':locale',this.locale)" >
                            <i class="mx-1 fal fa-chalkboard-teacher"></i>
                            <span class="sidebar_menu_text">{{__("learn")}}</span>
                        </a>
                    </li>

                    <li>
                        <a v-on:click="show_child_menu('setting')" :class="active_item=='setting'?'active_li':''">
                            <i class="mx-1 fal fa-cogs"></i>
                            <span class="sidebar_menu_text">{{__("setting")}}</span>
                            <span class=" fa fa-angle-left"></span>
                        </a>
                        <div class="child_menu" ref="setting" :class="active_item=='setting'?'active_child child_menu':'child_menu'">
                            <router-link :to="'/'+this.locale+'/setting/profile'">{{__("edit :name",{'name':__('profile')})}}</router-link>
                            <router-link :to="'/'+this.locale+'/setting/bank'">{{__("edit :name",{'name':__('bank info')})}}</router-link>
                            <router-link :to="'/'+this.locale+'/setting/change_password'">{{__("change :name",{'name':__('password')})}}</router-link>
                        </div>
                    </li>




                    <li>
                        <a v-on:click="logout_dialog()" :class="active_item=='logout'?'active_li':''">
                            <i class="mx-1 fal fa-sign-out"></i>
                            <span class="sidebar_menu_text">{{__("logout")}}</span>
                        </a>

                    </li>

                </ul>

            </div>
        </div>

        <div class="page_content" id="page_content">
            <div class="page_header">
                <div class="d-flex w-100 justify-content-between align-items-center">
                    <span class="text-truncate ltr text-left">
                        {{brand_name}}
                    </span>
                    <div class="d-flex flex-column">
                        <router-link :to="'/'+this.locale+'/setting/bank'" class="alert alert-warning px-2 py-0 text-primary" v-if="bankStatus()" >
                            <small> {{__("edit :name",{name:__('bank info')})}} </small>
                        </router-link>
                        <a  class="btn btn-outline-primary px-2 py-0 rounded text-truncate" target="_blank" v-if="$parent.learns&&$parent.learns[$route.name]" :href="String($parent.learns[$route.name]).replace(':locale',this.locale)">
                           <small> <i class="fal fa-question-circle"></i> {{__("learn :name",{'name':$parent.pageTitle})}} </small>
                        </a>
                    </div>


                </div>
            </div>
            <div class="content_box">
                <router-view ref="child" @setSideBarItem="setSideBarItem" @updateInfo="updateInfo"></router-view>
            </div>
        </div>

        <div class="message_div" v-if="showMessageDialog">
            <div class="message_box text-center">
                <p class="text-center" id="msg">{{__("logout seller")}}</p>
                <button class="alert alert-success" v-on:click="logoutUser()">{{__("yes")}}</button>
                <button class="alert alert-danger " v-on:click="showMessageDialog=!showMessageDialog" >{{__("no")}}</button>
            </div>
        </div>


        <server-error-message v-if="server_error"></server-error-message>

    </div>
</template>

<script>
import myMixin from "@/myMixin";
import loading from "@/components/Loading"
import ServerErrorMessage from "@/components/ServerErrorMessage";


export default {
name: "Admin",
    data(){
        return{
            active_item:'',
            showMessageDialog:false,
            showLoading:false,
            server_error:false,
            brand_name:'',
            favIco:'',
        }
    },
    components:{ServerErrorMessage, loading},
    mixins:[myMixin],
    mounted() {
        const getUserData=this.$route.params.getUserData;
        if(getUserData=="yes"){
            this.showLoading=true;
            this.getSellerInfo();
        }else{
            if(localStorage.getItem('brand_name')!=undefined){
                this.brand_name=localStorage.getItem('brand_name');
            }
        }
        // window.addEventListener('resize',this.onResize)
    },
    methods:{
        hide_child_menu(){
            const child_menu=document.getElementsByClassName('child_menu');
                for (let i=0;i<child_menu.length;i++){
                    child_menu[i].style.display='none';
                    child_menu[i].classList.remove('active_child')
                }
         },
        show_child_menu($ref){
          this.hide_child_menu();
            const sidebar_menu=document.getElementById('sidebar_menu').getElementsByTagName('li')
            for (let j=0;j<sidebar_menu.length;j++){
                sidebar_menu[j].getElementsByTagName('a')[0].classList.remove('active_li')
            }
            this.$refs[$ref].parentElement.getElementsByTagName('a')[0].classList.add('active_li');
            this.$refs[$ref].style.display='block';
        },
        setSideBarItem(item){
            this.active_item=item;
        },
        Toggled(){
            if(this.hasMobile==true)
                return;
            const class_name= document.getElementById('page_sidebar').className;
            if(class_name=='page_sidebar'){
                document.getElementById('page_sidebar').classList.add('toggled');
                this.hide_child_menu();
                document.getElementById('page_content').style.marginRight='50px';
            }else{
                document.getElementById('page_sidebar').classList.remove('toggled');
                document.getElementById('page_content').style.marginRight='240px';
                if(document.getElementsByClassName('active_li')==1)
                 document.getElementsByClassName('active_li')[0].parentElement.getElementsByClassName('child_menu')[0].style.display='block';
            }
        },
        logout_dialog(){
            this.showMessageDialog=true;
        },
        logoutUser(){
            const app=this;
            this.showLoading=true;
            this.showMessageDialog=false;
            const token=localStorage.getItem('token');
            const url=this.host+"api/seller_logout"
            this.axios.get(url,{
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }}).then(response=>{
                    this.showLoading=false;
                    if(response.data=="ok"){
                        this.logout();
                    }else{
                        this.show_server_error();
                    }
            }).catch(error=>{
                app.showLoading=false;
                if(error.response.status==401){
                    app.logout();
                }else{
                    app.showLoading=false;
                    app.show_server_error();
                }
            })
        },
        updateInfo(){
           this.brand_name=localStorage.getItem('brand_name');
        },
        onResize(event){
            if(event.currentTarget.innerWidth<600){
                const class_name= document.getElementById('page_sidebar').className;
                if(class_name=='page_sidebar'){
                    document.getElementById('page_sidebar').classList.add('toggled');
                    this.hide_child_menu();
                    document.getElementById('page_content').style.marginRight='50px';
                }
            }
        },
        bankStatus(){
                return localStorage.getItem('bank_status')==='false'?true:false;
        },
        active_me(item){
            /* global $ */
            this.active_item=item;
            $('.child_menu').css('display','none');
            $('.active_li').removeClass('active_li')
            $('#side_'+item).addClass('active_li');
        }

    }
}
</script>

<style scoped>
    @import "../assets/css/admin.css";
</style>
