<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>
        <div class="panel">
            <div class="header">
                {{__('detail :name',{'name':this.__('packet')})}}
            </div>
            <div class="panel_content">
                <div v-if="account_status=='active'" class="row">
                    <div class="px-0">
                    <div  class="row search_form form-inline  " >

                        <div class="col-md-8 col-lg-5 col-sm-12 col-12 mb-3">
                        <label class="">{{__('search')}}</label>
                            <div class="input-group">
                            <input type="text" class="form-control" :placeholder="__(':name name',{name:__('product')})" v-model="title" >
                            <button class="btn btn-primary rounded-0" type="button" id="button-addon1" v-on:click="getList(1)">{{__("search")}}</button>
                            </div>
                        </div>
                        <div class="">
                        <router-link
                            :to="'/'+this.locale+'/packet/show/'+this.$route.params.id+'/factor'"
                            target="_blank" class="btn rounded-0 mb-2 w-auto btn-secondary">
                            <i class="fal fa-print mx-1"></i> {{__("factor")}}
                        </router-link>
                        </div>
                    </div>


                    </div>

                    <table class="table table-bordered" >
                        <thead>
                        <tr v-if="$parent.hasMobile">
                            <th>#</th>
                            <th>{{__("detail")}}</th>

                        </tr>
                        <tr v-else>
                            <th>#</th>
                            <th>{{__("image")}}</th>
                            <th>{{__("title")}}</th>
                            <th>{{__("warranty")}}</th>
                            <th>{{__("color")+"|"+__("size")}}</th>
                            <th>{{__("count")}}</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(row,key) in list.data" :key="key" v-if="$parent.hasMobile">
                            <td class="text-center">{{getRow(key)}}</td>
                            <td>
                                <div class="text-center">
                                    <img :src="row.product_warranty.product.imgPath" class="product_pic stockroom_img">
                                    <div class="max-1line">
                                        <div>{{__(":name name",{name:__('product')})+': '+_(row.product_warranty.product.title)}}</div>
                                        <div>{{__('warranty')+': '+_(row.product_warranty.warranty.name)}}</div>
                                        <div>
                                            <span v-if="row.product_warranty.color.id>0">
                                                <span v-if="row.product_warranty.color.type==1">{{__("color")}}: </span>
                                                <span v-else>{{__("size")}}: </span>
                                            <span>
                                                <b class="border mx-1" :style="[{padding: '0 12px',width:'15px',height:'15px',background:row.product_warranty.color.code}]"></b>
                                                {{ _(row.product_warranty.color.name) }}
                                                </span>
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                {{__('count')+': '+row.product_count}}
                                         </span>
                                            <div style="color:red" v-if="row.real_count!=null && row.real_count!=row.product_count">
                                                <span>{{__("count receive from stock man")}}</span> :
                                                <span >
                                         {{row.real_count}}
                                    </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="more-1line"  :data-more="__('more')" :data-less="__('read less')">
                                        <i class="fal fa-chevron-circle-up"></i> <span>{{__("more")}}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="(row,key) in list.data" :key="key" v-if="!$parent.hasMobile">
                            <td class="text-center">{{getRow(key)}}</td>
                            <td class="text-center">
                                <img :src="row.product_warranty.product.imgPath" class="product_pic stockroom_img">
                            </td>
                            <td class="text-center">{{_(row.product_warranty.product.title)}}</td>
                            <td class="text-center">{{_(row.product_warranty.warranty.name)}}</td>
                            <td  class="text-center">
                                <span v-if="row.product_warranty.color.id>0" >
                                  <span>  <b class="border mx-1" :style="[{padding: '0 12px',width:'15px',height:'15px',background:row.product_warranty.color.code}]" ></b> {{_(row.product_warranty.color.name)}} </span>
                                </span>
                            </td>
                            <td  class="text-center">
                                <span>
                                    {{row.product_count}}
                                </span>
                                <div  class="text-center" style="color:red" v-if="row.real_count!=null && row.real_count!=row.product_count">
                                    <span>{{__("count receive from stock man")}}</span> :
                                    <span >
                                         {{row.real_count}}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <tr v-if="list.data.length==0" class="d-block w-100">
                        <p class="alert alert-info"><i class="fa fa-exclamation-triangle mx-2"></i>{{__("There is no record to display")}}</p>
                    </tr>

                    <pagination :data="list" @pagination-change-page="getList"></pagination>
                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{ __("403 text") }}
                    </p>
                </div>
            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
    </div>
</template>

<script>
import myMixin from "@/myMixin";

export default {
    name: "PacketContent",
    mixins:[myMixin] ,
    data(){
        return {
            showLoading:false,
            account_status:'active',
            server_error:false,
            BreadCrumbList:[],
            title:'',
            list:{data:[]},
            page:1,
            perPage:10
        }
    },
    mounted() {
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/packets',title:this.__('manage :name',{'name':this.__('packets')})},
            {path:"/"+this.locale+'/packets/show/'+this.$route.params.id,title:this.__('detail :name',{'name':this.__('packet')})},
        ];
        this.account_status = localStorage.getItem('account_status');
        this.$emit('setSideBarItem','packets');
        this.getList()
    },
    methods:{
        getList(page=1){
            const app=this;
            this.page=page;
            this.showLoading=true;
            const url=this.host+"api/seller/getPacketContent/"+this.$route.params.id+"?page="+this.page+"&title="+this.title;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    if(response.data!='error'){
                        this.list=response.data;
                        this.perPage=response.data.per_page;
                    }else{
                        this.show_server_error();
                    }
                }
            }).catch(err=>{
                app.showLoading=false;
                app.setCatch(err)
            })

        }
    }
}
</script>

<style scoped>

</style>
