<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>

        <div class="panel">
            <div class="header">
                {{__('manage :name',{'name':__('orders')})}}
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" :disabled="!show_options">
                        {{__('options')}}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li >
                            <a class="dropdown-item pointer" @click="showAllFactors()" >
                                <i class="fal fa-list-alt text-primary"></i>
                                {{__("add :field",{'field':__('all factors')})}}
                            </a>
                        </li>
                        <li>
                            <router-link :to="{name:'order-products-packets',params:{opIds:getOpIds()}}"  class="dropdown-item" >
                                <i class="fal fal fa-conveyor-belt text-primary"></i>
                                {{__("manage :name",{'name':__('all packets')})}}
                            </router-link>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="panel_content p-0">
                <div v-if="account_status=='active'" class="row">
                    <div class="panel_content">

                        <div class="border border-dashed border-primary border-2 rounded mb-2 p-lg-2 p-1">
                            <div class="p-1 border-bottom">
                                {{__("recipient name")}}:
                                {{order.receiver_name}}
                            </div>
                            <div class="p-1 border-bottom" v-if="order.user&&order.user.email!=''">
                                {{__("email")}}:
                                {{order.user.email}}
                            </div>
                            <div class="p-1 border-bottom" v-if="order.fullAddress!=''">
                             {{__("address")}}:
                                {{order.fullAddress}}
                            </div>
                            <div class="p-1" >
                                {{__("submitted at")}}:
                                {{ new Date(order.created_at).toLocaleString()}}
                            </div>
                        </div>

<!--                        <div  class="row search_form form-inline col-md-8 col-lg-5 col-sm-12 col-12" >-->
<!--                            <h6>{{__('search')}}</h6>-->
<!--                            <div class="input-group mb-3">-->
<!--                                <input type="text" class="form-control" :placeholder="__('product title')" v-model="search_title" >-->
<!--                                <input type="datetime-local" class="form-control" v-model="date" >-->
<!--                                <button class="btn btn-primary rounded-0" type="button" id="button-addon1" v-on:click="getOrders(1)">{{__("search")}}</button>-->
<!--                            </div>-->
<!--                        </div>-->

                        <OrderProductTable ref="orderProductTable" :order="order" noneBg=""></OrderProductTable>


                    </div>

                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{ __("403 text") }}
                    </p>
                </div>
            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
    </div>
</template>

<script>
/*  global $ */
import myMixin from "@/myMixin";


import 'bootstrap/dist/js/bootstrap.min';
import OrderProductTable from "@/components/OrderProductsTable";
export default {
    name: "OrderProducts",
    components: {OrderProductTable},
    data(){
        return{
            account_status:'active',
            showLoading:false,
            BreadCrumbList:[],
            server_error:false,
            order:{},
            orderStatus:[],
            //search_title:'',
            //date:'',
            selected_key:-1,
            noneBg:'',
            order_id:'',
            show_options:false
        }
    },
    mixins:[myMixin],
    mounted() {
        this.orderStatus=[
            this.__('not pay'),
            this.__('return product'),
            this.__('o.waiting'),
            this.__('o.success'),
            this.__('o.preparation'),
            this.__('o.processing out'),
            this.__('o.send to post'),
            this.__('o.receive from post'),
            this.__('o.customer receive'),
        ];
        this.account_status = localStorage.getItem('account_status');
        this.$emit('setSideBarItem','orders');
        this.order_id=this.$route.params.id;
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/orders/',title:this.__('manage :name',{'name':this.__('orders')})},
            {path:"/"+this.locale+'/orderProducts/'+this.order_id,title:this.__('order details')},
        ];
        this.getOrders();
    },
    methods:{
        getOrders(){
            const app=this;
            this.showLoading=true;
            const url=this.host+"api/seller/orderProducts/"+this.order_id;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    this.order=response.data;
                }
            }).catch(err=>{
                app.showLoading=false;
                app.setCatch(err);
            })

        },
        showAllFactors(){
            $("#allFactorsModal").show();
        },
        getOpIds(){
            if(this.$refs.orderProductTable)
                return Object.values(this.$refs.orderProductTable.checked_ids)

        }

    }
}
</script>

<style scoped>

</style>
