<template>
    <div class="step2">

    <div class="row justify-content-center p-2">

        <div class="col-lg-6 col-12  mt-2 account-type-box" >
            <div class="form-control form-check d-flex justify-content-center align-items-center p-0" style="height:45px">
            <small class="px-1">{{ __("register type") }}</small>
            <div class="d-flex">
                <input value="1" id="personal" v-model="account_type" class="form-check-input" type="radio"
                       name="account_type">
                <label for="personal" class="form-check-label register_label">{{ __("personal") }}</label>
            </div>
            <div class="d-flex">
                <input value="2" id="commerce" v-model="account_type" class="form-check-input" type="radio"
                       name="account_type">
                <label for="commerce" class="form-check-label register_label">{{ __("commerce") }}</label>
            </div>
            </div>
        </div>


        <div class="col-lg-6 col-12 mt-2">
            <input v-model="brand_name" type="text"
                   :class="[brand_name_error?'form-control border-danger':'form-control']"
                   :placeholder="this.__(':name name',{'name':this.__('brand')})">
            <p v-if="brand_name_error" class="has_error">{{ __(brand_name_error) }}</p>
        </div>
        <div class="col-lg-6 col-12 mt-2">
            <input v-model="fname" type="text" :class="[fname_error?'form-control border-danger':'form-control']"
                   :placeholder="this.__('first_name')">
            <p v-if="fname_error" class="has_error">{{ __(fname_error) }}</p>
        </div>
        <div class="col-lg-6 col-12 mt-2">
            <input v-model="lname" type="text" :class="[lname_error?'form-control border-danger':'form-control']"
                   :placeholder="this.__('family')">
            <p v-if="lname_error" class="has_error">{{ __(lname_error) }}</p>
        </div>


        <div class="col-lg-6 col-12 ">
            <v-select autocomplete="chrome-off" :name="'name'" :aria-autocomplete="'none'"
                      v-model="country_id" :reduce="country => country.id" :options="countries" index="id"
                      :class="[country_id_error?'form-control border-danger style_lang custom-select':'form-control style_lang border custom-select']" label="name"
                      :placeholder="__('choose :name',{'name':__('country')})"></v-select>
            <p v-if="country_id_error" class="has_error">{{ country_id_error }}</p>
        </div>


        <div class="col-lg-6 col-12 ">
            <v-select autocomplete="chrome-off" :name="'name'" :aria-autocomplete="'none'"
                      v-model="city_id" :reduce="city => city.id" :options="cities" index="id"
                      :class="[city_id_error?'form-control border-danger style_lang custom-select':'form-control style_lang border custom-select']" label="name"
                      :placeholder="__('choose :name',{'name':__('city')})"></v-select>
            <p v-if="city_id_error" class="has_error">{{ city_id_error }}</p>
        </div>

        <div class="col-lg-6 col-12 mt-2">
            <input v-model="town" type="text" :class="[town_error?'form-control border-danger':'form-control']"
                   :placeholder="this.__('town')">
            <p v-if="town_error" class="has_error">{{ __(town_error) }}</p>
        </div>

        <div class="col-lg-6 col-12 mt-2">
            <input v-model="postal_code" type="text" :class="[postal_code_error?'form-control border-danger':'form-control']"
                   :placeholder="this.__('zip code')">
            <p v-if="postal_code_error" class="has_error">{{ __(postal_code_error) }}</p>
        </div>

        <div class=" col-12 mt-2">
            <textarea v-model="address" type="text" rows="4" :class="[address_error?'form-control w-100 border-danger h-auto':'form-control h-auto w-100']"
                      :placeholder="this.__('street and plate')"></textarea>
            <p v-if="address_error" class="has_error">{{ __(address_error) }}</p>
        </div>




        <div class="form-group d-flex justify-content-center">

            <button type="submit" class="send_btn register_btn"  v-on:click="send_data()">
                                <span class="line">
                                    <i class="fal fa-chevron-square-left"></i>
                                </span>
                <span class="title">{{ __("submit & resume") }}</span>
            </button>

        </div>

        <div class="alert alert-warning " id="spinner" v-if="send_form">
            <div class="spinner-border text-warning" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <span style="padding-right:10px">{{ __("sending information") }}</span>
        </div>

    </div>
    </div>
</template>

<script>
import myMixin from "@/myMixin";
export default {
    name: "RegisterStep2",
    mixins:[myMixin],
    props:['phone','lang_id'],
    data(){
        return{
            brand_name:null,
            brand_name_error:false,
            fname_error:false,
            fname:'',
            lname_error:false,
            lname:'',
            countries:[],
            country_id:'',
            country_id_error:false,
            cities:[],
            city_id:'',
            city_id_error:false,
            account_type:2,
            send_form:false,
            town:'',
            town_error:false,
            postal_code:'',
            postal_code_error:false,
            address:'',
            address_error:false
        }
    },
    mounted() {

        this.getCountries();
    },
    methods:{
        send_data(){
            const app=this;
            const check_brand_name=this.validate_field(this.__(':name name',{'name':this.__('brand')}),this.brand_name,'brand_name_error',3);
            const check_fname=this.validate_field(this.__('first_name'),this.fname,'fname_error',3);
            const check_lname=this.validate_field(this.__('last_name'),this.lname,'lname_error',4);
            const check_country=this.validate_field(this.__('country'),this.country_id,'country_id_error',1);
            const check_city=this.validate_field(this.__('city'),this.city_id,'city_id_error',1);
            const check_town=this.validate_field(this.__('town'),this.town,'town_error',2);
            const check_address=this.validate_field(this.__('address'),this.address,'address_error',5);
            const check_postal_code=this.validate_field(this.__('zip code'),this.postal_code,'postal_code_error',3);
            if(check_brand_name && check_fname && check_lname && check_country && check_city &&check_town && check_address&&check_postal_code){
                this.send_form=true;
                const form_data=new FormData();
                form_data.append('brand_name',this.brand_name);
                form_data.append('fname',this.fname);
                form_data.append('lname',this.lname);
                form_data.append('country_id',this.country_id);
                form_data.append('city_id',this.city_id);
                form_data.append('mobile',this.phone);
                form_data.append('account_type',this.account_type);
                form_data.append('userLang',this.locale);
                form_data.append('lang_id',this.lang_id);
                form_data.append('town',this.town);
                form_data.append('address',this.address);
                form_data.append('postal_code',this.postal_code);
                this.axios.post(this.host+'api/seller/second_step_register',form_data).then(response=>{
                    this.send_form=false
                    if(response.data.error!=undefined){
                        for (const i in response.data.error) {
                              return   this[i]=response.data.error[i];
                        }
                    }
                    else if(response.data.status=='error')
                    {
                        this.server_error=true;
                        this.$emit('setServerError',response.data.message);

                    }else{
                        this.server_error=false;
                      this.$emit('set_step',response.data.step);
                      this.$emit('set_type',this.account_type);

                    }


                }).catch(function (){
                    app.send_form=false;
                    app.$emit('setServerError',this.__('errored'));
                })


            }
        }


    },
    watch:{
        country_id(){
           this.city_id=null;
           this.get_city();
        }
    }

}
</script>

<style scoped>

</style>
