<template>
    <div>
   <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>
        <div class="panel">
            <div class="header">
                {{__('manage :name',{'name':__('packets')})}}
            </div>
            <div class="panel_content">
                <div v-if="account_status=='active'" class="row">
                    <div  class="row search_form form-inline col-md-8 col-lg-5 col-sm-12 col-12" >
                        <h6>{{__('search')}}</h6>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :placeholder="__('id :name',{name:__('packet')})" v-model="search_packet_id" >
                            <input type="datetime-local" class="form-control" v-model="date" >
                            <button class="btn btn-primary rounded-0" type="button" id="button-addon1" v-on:click="getPacket(1)">{{__("search")}}</button>
                        </div>
                    </div>

                    <table class="table table-bordered">
                        <thead>
                        <tr v-if="$parent.hasMobile">
                            <th ><small>#</small></th>
                            <th ><small>{{__("detail")}}</small></th>
                            <th ><small>{{__("see")}}</small></th>
                        </tr>
                        <tr v-else>
                            <th>#</th>
                            <th>{{__("id :name",{name:__('packet')})}}</th>
                            <th>{{__("send time")}}</th>
                            <th>{{__("count")}}</th>
                            <th>{{__("status")}}</th>
                            <th>{{__("operation")}}</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(row,key) in list.data" :key="key" v-if="$parent.hasMobile">
                            <td class="text-center">{{getRow(key)}}</td>
                            <td>
                                <div>{{__("id :name",{name:__('packet')})+': '+row.packet_id}}</div>
                                <div>{{__("send time")+': '+new Date(row.send_date).toDateString()}}</div>
                                <div>{{__("count")+': '+row.product_count}}</div>
                                <div>{{__("status")+': '+packet_status(row.status)}}</div>
                            </td>
                            <td class="text-center">
                                <span v-on:click="$router.push({name:'packet_content',params:{id:row.id}})" class="fal fa-eye"></span>
                            </td>
                        </tr>
                        <tr  v-for="(row,key) in list.data" :key="key" v-if="!$parent.hasMobile">
                            <td class="text-center">{{getRow(key)}}</td>
                            <td class="text-center">{{ row.packet_id }}</td>
                            <td class="text-center"> {{ new Date(row.send_date).toDateString()}} </td>
                            <td class="text-center">{{row.product_count}}</td>
                            <td class="text-center">{{packet_status(row.status)}}</td>
                            <td class="text-center pointer"><span v-on:click="$router.push({name:'packet_content',params:{id:row.id}})" class="fa fa-eye"></span></td>
                        </tr>
                        </tbody>
                    </table>
                    <tr v-if="list.data.length==0" class="d-block w-100">
                        <p class="alert alert-info"><i class="fa fa-exclamation-triangle mx-2"></i>{{__("There is no record to display")}}</p>
                    </tr>

                    <pagination :data="list" @pagination-change-page="getPacket"></pagination>
                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{ __("403 text") }}
                    </p>
                </div>
            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
    </div>
</template>

<script>
import myMixin from "@/myMixin";

export default {
name: "Packet",
    mixins:[myMixin] ,
   data(){
        return {
            showLoading:false,
            account_status:'active',
            server_error:false,
            BreadCrumbList:[],
            date:'',
            search_packet_id:'',
            list:{data:[]},
            page:1,
            perPage:10
        }
    },
    mounted() {
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/packets',title:this.__('manage :name',{'name':this.__('packets')})},
        ];
        this.account_status = localStorage.getItem('account_status');
        this.$emit('setSideBarItem','packets');
        this.getPacket();
    },
    methods:{
        getPacket(page=1){
            const app=this;
            this.page=page;
            this.showLoading=true;
            const url=this.host+"api/seller/packets?page="+page+"&packet_id="+this.search_packet_id+"&date="+this.date;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    this.list=response.data;
                    this.perPage=response.data.per_page;
                }
            }).catch(err=>{
                app.setCatch(err)
            })
        }
    }
}
</script>

<style scoped>

</style>
