<template>
    <div>
        <loading v-if="showLoading"></loading>
        <breadcrumb :data="BreadcrumbList"></breadcrumb>

        <div class="panel" v-if="MessageContent!=null">

            <div class="header">
                {{__(':name details',{'name':__('message')}) + '- '+ MessageContent.title}}

            </div>
            <div class="panel_content">
                <div v-if="show_alert_message" :class="'alert alert-dismissible fade show mb-1 '+(__('sent')==alert_message?'alert-success':'alert-warning')" role="alert">
                    <span>{{alert_message}}</span>
                    <button type="button" class="btn-close " data-bs-dismiss="alert" aria-label="Close"></button>
                </div>

            <div class="question_div">
                <div class="question_div_header">
                    <div>
                        {{__("sender")}} :
                        <span v-if="MessageContent.from!=null">
                            <span v-if="MessageContent.from.from_type=='App\\Models\\User'" class="from_link">
                            {{ __("shop name") }}
                            </span>
                            <span v-else class="from_link">
                            {{ __("you")}}
                            </span>
                        </span>
                        <span class="from_link" v-else>{{__("shop name")}}</span>
                    </div>
                    <div>
                        {{new Date(MessageContent.created_at).toDateString()}}
                    </div>
                </div>
                <div class="question_content" v-html="MessageContent.content"></div>
                <div v-if="MessageContent.file!=null" class="border-top p-2 mt-2 align-items-center d-flex text-dark">
                    <span>{{__("file")}} : </span>
                    <a :href="MessageContent.filePath">{{MessageContent.file}}</a>
                </div>
            </div>

                <div v-if="MessageContent">
                    <div class="question_div" v-for="answer in MessageContent.answers" :key="answer.id">
                        <div class="question_div_header">
                            <div>
                                {{__("sender")}} :
                             <span v-if="answer.from_type=='App\\Models\\Seller'" class="from_link">
                                {{__("you")}}
                            </span>
                            <span class="to_link" v-else>{{__("shop name")}}</span>
                            </div>
                            <div>
                                {{new Date(answer.created_at).toDateString()}}
                            </div>
                        </div>
                        <div class="question_content" v-html="answer.content"></div>
                        <div v-if="answer.file!=null" class="border-top p-2 mt-2 align-items-center d-flex text-dark">
                            <span>{{__("file")}} : </span>
                            <a :href="answer.filePath">{{answer.file}}</a>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <span class="text-secondary">{{__("send :name",{name:__('answer')})}}</span>

                    <div v-if="errors.length>0" class="alert alert-warning">
                        <ul class="errors_ul">
                            <li v-for="(error,key) in errors" :key="key">{{(key+1)+" - "+error}}</li>
                        </ul>
                    </div>

                    <div class="form-group my-2 align-items-center d-flex">
                        <textarea class="form-control col mx-2 w-100"  style="max-width:500px;height:250px" v-model="content" :placeholder="__('your :name',{name:__('answer')})"></textarea>
                        <span v-if="content_error" class="has_error">{{ content_error }}</span>
                    </div>
                    <div class="form-group">
                        <label class="my-2">{{__('choose :name',{name:__('file')})}}</label>
                        <input type="file" ref="fileInput" class="fileInput"  v-on:change="selectFile()">
                    </div>
                    <div class="form-group mt-2">
                        <button class="btn btn-success"  v-on:click="addAnswer()">{{__("send :name",{name:__('answer')})}}</button>
                    </div>

                </div>

            </div>
        </div>

        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
    </div>
</template>

<script>

import myMixin from "@/myMixin";
import 'bootstrap/dist/js/bootstrap'
export default {
    name: "MessageContent",
    mixins: [myMixin],
    data() {
        return {
            showLoading: true,
            MessageContent: {},
            BreadcrumbList: [],
            account_status: 'active',
            server_error: false,
            show_alert_message: false,
            alert_message: '',
            message_id: 0,
            content: null,
            content_error: false,
            file: null,
            send_form: true,
            errors: []
        }
    },
    mounted() {
        this.seller_id = localStorage.getItem('seller_id')
        this.message_id = this.$route.params.id
        this.getMessageContent();
        this.$emit('setSideBarItem', 'messages')
        this.BreadCrumbList = [
            {path: this.locale + '/messages', title: this.__('manage :name', {'name': this.__('messages')})},
            {
                path: this.locale + '/messages/' + this.$route.params.id + '/show',
                title: this.__('manage :name', {'name': this.__('messages')})
            },
        ];

    }, methods: {
        getMessageContent(page = 1) {
            this.showLoading = true;
            const app=this;
            this.page = page;
            const url = this.host + "api/seller/messages/content/" + this.message_id;
            this.axios.get(url, this.setHeader()).then(response => {
                this.showLoading = false;
                if (this.setAccountStatus(response.data)) {
                    if (response.data == 'redirect') {
                        this.$router.push({name: 'MessageList'});
                    } else {
                        this.MessageContent = response.data;
                    }
                }
            }).catch(err => {
                app.showLoading = false;
                app.setCatch(err)
            })
        }, selectFile() {
            this.file = this.$refs.fileInput.files[0];
        },
        addAnswer() {
            if (!this.send_form)
                return "";
            const check_content = this.validate_field(this.__('answer'), this.content, 'content_error', 2);
            const app=this;
            if (check_content) {
                this.errors = [];
                this.showLoading = true;
                const url = this.host + 'api/seller/message/answer/' + this.message_id;
                this.send_form = false;
                const formData = new FormData();
                formData.append('content', this.content)
                if(this.file!=null)
                    formData.append('pic',this.file)
                this.axios.post(url, formData, this.setHeader(true)).then(response => {
                    this.showLoading = false;
                    this.send_form = true;
                    if (response.data.status == "error") {
                        const errors = response.data.errors;
                        const keys = Object.keys(errors);
                        for (let i = 0; i < keys.length; i++) {
                            this.errors.push(errors[keys[i]][0])
                        }
                    } else if (response.data.status== "ok") {
                        this.content='';
                        this.file=null;
                        /* global $ */
                       $('.fileInput').val("");
                        this.show_alert_message = true;
                        this.alert_message = this.__('sent');
                        this.MessageContent.answers.push(response.data.answer)
                    } else {
                        this.show_server_error();
                    }
                }).catch(err => {
                    app.showLoading = false;
                    app.send_form = true;
                    app.setCatch(err)
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
