<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>

        <div class="panel">
            <div class="header">{{__("submit features value")}}</div>
            <div class="panel_content">
                <div id="box_alert" class="m-0 p-0">
                    <div v-if="show_alert_message" :class="'alert alert-dismissible fade show mb-1 '+(alert_message!=__('saved')?'alert-warning':'alert-success')" role="alert">
                        <span>{{alert_message}}</span>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </div>
                <div v-if="account_status=='active'">
                    <p v-if="String(title).length>0">
                        {{ _(title)}}
                    </p>
                    <div v-if="$route.params.next" class="w-100 p-2 w-100 alert alert-success alert-dismissible">
                        <p class="small mb-0">{{__("created product")}}</p>
                    </div>
                    <div class="w-100 p-2 w-100 alert alert-warning text-danger">
                       <p class="small "> 1- {{__("not checked")}}</p>
                        <p class="small mb-0"> 2- {{__("required one language")}}</p>
                    </div>
                    <form id="product_items_form">
                        <div class="item_groups mb-2" v-for="item in product_items" :key="item.id">
                            <div class="title">{{_(item.title)}}</div>
                            <div class="row">

                                <div class="col-12" v-for="childItem in item.child" :key="childItem.id">
                                    <div class="form-group my-1">
                                        <div class="item_groups" style="background-color: #e6f7fa;">
                                            <p class="title">{{ _(childItem.title) }}</p>
                                            <div class="form-group" >
                                                <div :class="'m-1 input-group child_input_group '+(filter_array[childItem.id]!=undefined?'d-none':'')" v-for="lang in languages" :key="lang.id">
                                                    <span class="input-group-text">{{lang.name}}</span>
                                                    <input :placeholder="__('val :attr to lang :name',{'name':lang.name,'attr':_(childItem.title)})" v-model="data[item.id+'_'+childItem.id+'_'+lang.id]" type="text" class="form-control item_value" :ref="'item_value_'+childItem.id" :name="'value['+childItem.id+']['+lang.sign+']'" />
                                                </div>

                                                <div v-if="filter_array[childItem.id]!=undefined">
                                                    <input type="hidden" :value="getFilterItemValue(filters[filter_array[childItem.id]].id)" class="filter_value" :name="'filter_value['+childItem.id+']['+(filters[filter_array[childItem.id]].id)+']'"  />
                                                        <ul class="ul_check form-check form-switch" :ref="'filter_box'+childItem.id" >
                                                            <li class="li_item"  v-for="(filter_item,key) in filters[filter_array[childItem.id]].child" :key="key">
                                                                <input class="form-check-input float-none mx-1"  type="checkbox" :checked="check(filter_item.id)" :value="filter_item.id" v-on:click="checkSelected(filter_item.id,childItem,filter_item.title,item.id)" />
                                                                <label class="form-check-label" > {{_(filter_item.title)}} </label>
                                                            </li>
                                                        </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>
                            </div>
                            <button class="btn btn-success w-100 btn-lg" type="button" v-on:click="addItem()">{{__("submit :field",{field:__('technical details')})}}</button>
                    </form>
                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{__("403 text")}}
                    </p>
                </div>
            </div>
        </div>


        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>

    </div>
</template>

<script>
import myMixin from "@/myMixin";
export default {
    name: "Items",
     mixins:[myMixin],
    data(){
        return {
            showLoading:true,
            account_status:'active',
            BreadCrumbList:[],
            product_id:0,
            title:'',
            server_error:false,
            error_access:false,
            filters:[],
            product_items:[],
            product_filters:[],
            filter_array:[],
            languages:[],
            show_alert_message:false,
            alert_message:'',
            data:[]
        }
    },
    mounted() {
        this.product_id=this.$route.params.id;
        this.$emit('setSideBarItem','products')
        this.BreadCrumbList=[
            {path:'/'+this.locale+'/products',title:this.__('manage :name',{'name':this.__('products')})},
            {path:'/'+this.locale+'/products/'+this.$route.params.id+"/items",title:this.__('submit :field',{'field':this.__('technical details')})},
        ];
        this.account_status=localStorage.getItem('account_status');
        this.getLanguage();
        this.getItems();
    },
    methods:{
        getItems(){
            const app=this;
            this.showLoading=true;
            const url=this.host+"api/seller/product/"+this.product_id+"/items";
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    if(response.data.title!=undefined){
                        this.product_items=response.data.product_items;
                        this.filters=response.data.filters;
                        this.product_filters=response.data.product_filters;
                        this.title=response.data.title;
                        this.filter_array=response.data.filter_array;

                        this.initData();

                    }else if(response.data=='redirect'){
                        this.$router.push({name:'product_list'})
                    }else {
                        this.show_server_error()
                    }
                }
            }).catch(err=>{
                app.showLoading=false;
                app.setCatch(err);
            })
        },
        initData(){
            for(const item of  this.product_items) {
                for(const childItem of item.child) {
                    for (const lang of this.languages){
                        this.data[item.id+'_'+childItem.id+'_'+lang.id]=this._(childItem.value==undefined?{}:childItem.value.value,lang.sign);
                    }
                }
            }
        },
        get_child_input_name(id){
            return 'item_value['+id+'][]';
        },
        addItem(){
            const form=document.querySelector('form');
            const data=new FormData(form);
            this.showLoading=true;
            const url=this.host+"api/seller/product/"+this.product_id+"/items";
            this.axios.post(url,data,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    if(response.data=="ok"){

                        this.show_alert_message=true;
                        this.alert_message=this.__('saved');
                        if(this.$route.params.next){
                            this.$router.push({name:'gallery',params:{id:this.product_id,next:true}})
                        }
                        window.scrollTo({top:document.getElementById("box_alert").offsetTop-10,behavior: 'smooth'});



                    } else if(response.data=='redirect'){
                        this.$router.push({name:'product_list'})
                    }
                    else{
                        this.show_server_error()
                    }
                }else {
                    this.show_server_error()
                }
            })
        },
        add_item_value_input(id,attribute,languages){
            for (const language of languages) {
                const div=document.createElement('div');
                div.setAttribute('class',"w-100")
                const label=document.createElement('label');
                label.append(this.__('val :attr to lang :name',{'name':language.name,'attr':attribute}));
                div.appendChild(label);
                const input=document.createElement('input');
                input.setAttribute('name',this.get_child_input_name(id)+"["+(language.sign)+"]")
                input.setAttribute('class',"form-control w-100");
                div.appendChild(input)
                document.getElementById('input_item_box_'+id).appendChild(div)
            }

        },
        getFilterItemValue(id){
            let string='';
            for (let key in this.product_filters){
                if(this.product_filters[key]==id){
                    string+='@'+key;
                }
            }
            return string;
        },
        show_filter_box(id){
            const ref='filter_box'+id;
            if(this.$refs[ref][0].style.display=="none"||this.$refs[ref][0].style.display==""){
                /* global $ */
                $(this.$refs[ref].parentNode)
                return this.$refs[ref][0].style.display="block";
            }
            return this.$refs[ref][0].style.display="none"
        },
        checkSelected(filter_value,childItem,text,itemId){
            const value=this.filters[this.filter_array[childItem.id]].id;
            const item_value=this.$refs['item_value_'+childItem.id][0];
            if(this.product_filters[filter_value]==undefined){
                this.$set(this.product_filters,filter_value,value);
                for (const lang of this.languages) {
                    if(text[lang.sign]!=undefined){
                        item_value.value+=text[lang.sign]+',';
                        let old=this.data[itemId+'_'+childItem.id+'_'+lang.id]+"";
                        if(this.data[itemId+'_'+childItem.id+'_'+lang.id]==undefined){
                            old="";
                        }else{
                            if(old!="")
                                old+=",";
                            old=old.replace(",,",",");
                        }
                        this.data[itemId+'_'+childItem.id+'_'+lang.id]=old+text[lang.sign]+',';
                    }
                }
            }else {
                item_value.value=item_value.value.replace(text+',');
                const product_filters = { ...this.product_filters };
                delete product_filters[filter_value];
                this.product_filters = product_filters;
                for (const lang of this.languages) {
                    this.data[itemId+'_'+childItem.id+'_'+lang.id]=this.data[itemId+'_'+childItem.id+'_'+lang.id].replace(text[lang.sign]+',','');
                    this.data[itemId+'_'+childItem.id+'_'+lang.id]=this.data[itemId+'_'+childItem.id+'_'+lang.id].replace(',,',',');
                }
            }
        },
        check(id){
            if(this.product_filters[id]==undefined)
                return false;
            return true;
        }
    }
}
</script>

<style scoped>

</style>
