<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>
        <div class="panel">
            <div class="header">{{__("edit :name",{'name':__('product')})}}</div>
            <div class="panel_content">

                <div v-if="account_status=='active'" id="errors_box">
                    <div v-if="errors.length>0" class="alert alert-warning">
                        <ul class="errors_ul">
                            <li v-for="(error,key) in errors" :key="key">{{(key+1)+" - "+error}}</li>
                        </ul>
                    </div>
                    <div :class="($parent.hasMobile?'':'d-flex align-items-start')+ ' border border-primary rounded my-2'">
                        <div class="mx-1" v-if="$parent.hasMobile">{{__("languages")}} : </div>
                        <div :class="($parent.hasMobile?'mx-1 ':'flex-column ')+'nav nav-pills mx-3 p-1'" id="tab_head_langs" role="tablist" aria-orientation="vertical">
                            <button v-for="(lang,key) in languages" :class="'btn shadow-sm nav-link'+ [key==0?' show active':'']" :id="'tab_head_'+lang.id" data-bs-toggle="pill"
                                    :data-bs-target="'#tab_body_'+[lang.id]" type="button" role="tab"
                                    :aria-controls="'tab_body_'+lang.id" :key="key"
                                    :aria-selected="[key==0?'true':'false']">{{lang.name}}</button>
                        </div>
                        <div class="tab-content p-2 w-100" id="tab_body_langs">
                            <div v-for="(lang,k) in languages" :key="k" :class="'tab-pane fade'+[k==0?'show active':'']" :id="'tab_body_'+lang.id" role="tabpanel"
                                 :aria-labelledby="'tab_head_'+lang.id">
                                <div class="form-group row">
                                    <div class="px-0 my-1  form-group">
                                        <label >{{__(':name name',{'name':__('products')})+" - "+lang.name}}</label>
                                        <input type="text" :class="'form-control w-100 col mx-2 '+lang.alignClass" v-model="title[lang.sign]">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="px-0 my-1  form-group">
                                        <label >{{__('subtitle')+" - "+lang.name}}</label>
                                        <input type="text" :class="'form-control w-100 col mx-2 '+lang.alignClass" v-model="subtitle[lang.sign]">
                                    </div>
                                </div>

                                <div class="px-0 my-1  form-group">
                                    <label >{{__('description')+" - "+lang.name}}</label>
                                    <vue-editor v-model="content[lang.sign]"></vue-editor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="row col col-md-6">
                        <div class="col col-sm-12 col-md-6 col-sm-12 col-12">
                            <div class="form-group">
                                <label >{{__("choose :name",{'name':__("category")})}} :</label>
                                <v-select v-model="cat"  :options="catList" label="name" index="id" :selectable="option => option.child.length<1"></v-select>
                            </div>
                        </div>
                        <div class="col col-sm-12 col-md-6 col-sm-12 col-12">
                            <div class="form-group">
                                <label >{{__("choose :name",{'name':__("brand")})}} :</label>
                                <v-select v-model="brand"  :options="brandList" label="name" index="id" ></v-select>
                            </div>
                        </div>
                            <div class="col-12 alert alert-warning py-1 align-items-center d-flex flex-warp flex-column h-max my-md-0 my-sm-2 my-2" v-if="commission_percentage>0||commission_fix>0">
                                <div class="w-100" v-if="commission_percentage>0">{{__("com perc")+' : '+commission_percentage +'%'}}</div>
                                <div class="w-100" v-if="commission_fix>0">{{__("com fix")+' : '}} <label class="ltr w-auto">{{commission_fix+ defCurrency.sign}}</label></div>
                            </div>

                        <div class="col col-sm-12 col-md-6 col-sm-12 col-12">
                            <div class="form-group my-1">
                                <label class="d-block">{{__("product weight")}} :</label>
                                <input type="number" class="form-control w-100 d-block text-left ltr" step="3" v-model="weight">
                            </div>
                        </div>

                            <label class="my-2 my-md-0">
                                <input type="checkbox" name="use_for_gift_cart" v-model="fake" v-on:click="fake=!fake" >
                                {{__("fake")}}
                            </label>

                        </div>
                        <div class="col-md-6">
                            <div class="choice_pic_box" v-on:click="$refs.fileInput.click()">
                                <span class="title" style="width: fit-content!important">
                                {{ __("choose image product") }}
                                </span>
                                <img v-bind:src="this.image_src" class="pic_tag" id="output">
                            </div>
                            <input type="file" ref="fileInput" name="pic" id="pic" class="d-none" v-on:change="showImage()">
                        </div>
                    </div>

                    <div class="alert alert-danger m-1 reject_message" v-for="message in reject_message" :key="message.id">
                        <div>
                            <span class="reject_submit">{{__("submit by :name",{'name':__('site expert')})}}</span>
                            <span class="reject_date">{{new Date(message.created_at).toLocaleString()}}</span>
                        </div>
                        <div class="fs-14 p-1 ">
                            {{message.description}}
                        </div>
                    </div>

                    <div class="form-group m-4 text-center">
                        <button class="btn btn-success" v-on:click="updateProduct()">{{__("edit :name",{'name':__('product')})}}</button>
                    </div>
                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{__("403 text")}}
                    </p>
                </div>
            </div>
        </div>
        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>

    </div>
</template>

<script>
import myMixin from "@/myMixin";
import {VueEditor} from "vue2-editor"
window.$=require('jquery')
export default {
name: "UpdateProduct",
    components:{VueEditor},
    mixins:[myMixin],
    mounted() {
        this.product_id=this.$route.params.id;

        this.$emit('setSideBarItem','products')
        this.BreadCrumbList=[
            {path:'/'+this.locale+'/products',title:this.__('manage :name',{'name':this.__('products')})},
            {path:'/'+this.locale+'/products/'+this.$route.params.id+"/edit",title:this.__('edit :name',{'name':this.__('product')})},
        ];
        this.account_status=localStorage.getItem('account_status');
        this.getLanguage();
        this.getProduct();
        this.getCommission();

    },
    data(){
        return {
            BreadCrumbList:[],
            catList:[],
            brandList:[],
            colorList:[],
            account_status:'active',
            showLoading:false,
            title:[],
            languages:[],
            subtitle:[],
            content:[],
            cat:{name:'',id:''},
            catId:0,
            brand:{name:'',id:''},
            brandId:0,
            color:[],
            colorsId:[],
            image_src:'',
            image:'',
            errors:[],
            send_form:true,
            server_error:false,
            product_id:0,
            product:[],
            reject_message:[],
            weight:'',
            commissions:[],
            commission_fix:null,
            commission_percentage:null,
            fake:false,
        }
    },
    methods:{
        showImage(){
            if(this.$refs.fileInput.files[0]!=undefined) {
                this.image = this.$refs.fileInput.files[0];
                this.image_src = URL.createObjectURL(this.image);
            }
        },
        updateProduct(){
            const app=this;
            this.errors=[];
            if(this.send_form){
                this.errors=[];
                const validateTitle=this.validateTitle();
                const validateSubTitle=this.validateSubTitle();
                const validateContent=this.validateContent();
                const validateCatId=this.validateCatId();
                const validateBrandId=this.validateBrandId();
                const validateColorsId=this.validateColorsId();
                const validateWeight=this.validateWeight();
                const validateImage= this.image_src!=''||this.validateImage();
                if( validateTitle && validateSubTitle && validateContent  && validateCatId && validateBrandId && validateColorsId && validateImage && validateWeight){
                    this.showLoading=true;
                    const url=this.host+'api/seller/product/'+this.product_id;
                    this.send_form=false;
                    const formData=new FormData();
                    formData.append('title', JSON.stringify(this.title))
                    formData.append('description',JSON.stringify(this.content))
                    formData.append('cat_id',this.catId)
                    formData.append('brand_id',this.brandId)
                    // for (let i = 0; i <this.colorsId.length; i++) {
                    //     formData.append('product_color['+i+']',this.colorsId[i])
                    // }
                    formData.append('cat_id',this.catId)
                    formData.append('subtitle',JSON.stringify(this.subtitle))
                    formData.append('pic',this.image)
                    formData.append('product_weight',this.weight)
                    formData.append('fake',this.fake==false?"0":"1");
                    this.axios.post(url,formData,this.setHeader(true)).then(response => {
                        this.send_form=true;
                        this.showLoading=false;
                        if(this.setAccountStatus(response.data)){
                            if(response.data.status=="error") {
                                const errors = response.data.errors;
                                const keys = Object.keys(errors);
                                for (let i = 0; i < keys.length; i++) {
                                    this.errors.push(errors[keys[i]][0])
                                }
                                window.scrollTo({top:document.getElementById("errors_box").offsetTop-10,behavior: 'smooth'});
                            }
                            else if(response.data=="ok"){
                                    this.$router.push({name:'product_list',params:{success:'ok',message:this.__('saved')}})
                            }else{
                                window.scrollTo({top:document.getElementById("errors_box").offsetTop-10,behavior: 'smooth'});
                            }
                        }
                    }).catch(err => {
                        app.showLoading=false;
                        app.send_form=true;
                        if(err.response!=undefined && err.response.status!=undefined){
                            const status=err.response.status;
                            switch (status) {
                                case 422:
                                    for (const key in err.response.data.errors) {
                                        app.errors.push(err.response.data.errors[key][0]);
                                    }
                                    window.scrollTo({top:document.getElementById("errors_box").offsetTop-10,behavior: 'smooth'});
                                    break;
                                case 401:
                                    app.logout();
                                    break;
                                default:
                                    app.show_server_error();
                                    break;
                            }
                        }

                    })

                }else{
                    window.scrollTo({top:document.getElementById("errors_box").offsetTop-10,behavior: 'smooth'});

                }
            }

        },
        getProduct(){
            const app=this;
            const url=this.host+'api/seller/product/'+this.product_id+'/edit'
            this.axios.get(url,this.setHeader()).then(response=>{
                this.send_form=true;
                this.showLoading=false;
                if(this.setAccountStatus(response.data)) {
                    if(response.data.product!=undefined&&response.data.product.title!=undefined){
                        this.product=response.data.product;
                        this.title=Object.assign({}, response.data.product.title);
                        this.subtitle=Object.assign({}, response.data.product.subtitle);
                        this.content=response.data.product.description;
                        this.image_src=response.data.product.imgPath;
                        this.weight=response.data.product.product_weight;
                        this.fake=response.data.product.fake;
                        this.getCatList();
                        this.getBrand();
                        // this.getColor();
                        this.reject_message=response.data.reject_message;
                    }else if(response.data==="redirect"){
                        this.$router.push({name:'product_list'})
                    }
                }
            }).catch((err)=>{
                app.showLoading=false;
                if(err.response!=undefined && err.response.status==401){
                    app.logout();
                }else{
                    app.show_server_error();
                }
            })
        },
        getCommission(){
            const app=this;
            const url=this.host+'api/getCommissions';
            this.axios.get(url,this.setHeader(true)).then(response => {
                app.commissions = response.data;
                app.setCommission();
            }).catch(err => {
                if(err.response!=undefined && err.response.status!=undefined){
                    const status=err.response.status;
                    switch (status) {
                        case 422:
                            for (const key in err.response.data.errors) {
                                app.errors.push(err.response.data.errors[key][0]);
                            }
                            window.scrollTo({top:document.getElementById("errors_box").offsetTop-10,behavior: 'smooth'});
                            break;
                        case 401:
                            app.logout();
                            break;
                        default:
                            app.show_server_error();
                            break;
                    }
                }
            });
        },
        setCommission(){
            const app=this;
            let selected=null;
            for (const commission of app.commissions) {
                if(commission.category_id==this.catId){
                    if(commission.brand_id!=undefined&&commission.brand_id==this.brandId){
                        this.commission_fix=commission.fix;
                        this.commission_percentage=commission.percentage;
                        return true;
                    }
                    if(commission.brand_id==undefined||commission.brand_id<1)
                        selected=commission
                }
            }
            if(selected!=null){
                this.commission_fix=selected.fix;
                this.commission_percentage=selected.percentage;
                return true;
            }
            this.commission_fix=null;
            this.commission_percentage=null;
        }
    },
    watch:{
        catList:function(){
            this.cat=this.findNestedObj(this.catList,'id',this.product.cat_id,["name","id"])
        },
        brandList:function (){
            this.brand=this.findNestedObj(this.brandList,'id',this.product.brand_id,["name","id"]);
        },
        colorList:function (){
            for (const color of this.product.colors) {
                const obj=this.findNestedObj(this.colorList,'id',color.color_id,["name","id"]);
                this.color.push(obj)
                }
        },
        cat:function (val){
            this.catId=val.id;
            this.setCommission();
        },
        brand:function(val){
            this.brandId=val.id;
        },
        color:function(colors){
            if(colors.id!=undefined){
                colors=[colors];
            }
            this.colorsId=[];
            for (const key in colors) {
                const color=colors[key];
                this.colorsId[ this.colorsId.length ]=color.id
            }
        }
    }
}
</script>

<style scoped>

</style>
