<template>
    <div class="loading_data_box">
        <div class="spinner">
            <div class="b1"></div>
            <div class="b2"></div>
            <div class="b3"></div>
        </div>
    </div>
</template>

<script>
export default {
name: "Loading"
}
</script>

<style scoped>

</style>
