<template>
    <div>
        <loading v-if="showLoading"></loading>
        <Breadcrumb v-bind:data="BreadCrumbList"></Breadcrumb>
        <account-status :account_status="this.account_status"></account-status>

        <div class="panel">
            <div class="header">
                {{ __("edit :name", {'name': __('product warranty')}) }}
            </div>

            <div class="panel_content p-sm-0">

                <div v-if="account_status=='active'" class="row">

                    <div class="col-12 alert alert-warning py-1 align-items-center d-flex flex-warp flex-column" v-if="commission.percentage>0||commission.fix>0">
                        <div class="w-100 pb-2" v-if="commission.percentage>0">{{__("com perc")+' : '+commission.percentage +'%'}}</div>
                        <div class="w-100" v-if="commission.fix>0">{{__("com fix")+' : '}} <label class="ltr w-auto">{{commission.fix+ defCurrency.sign}}</label></div>
                    </div>

                    <div class="col col-12 col-md-10 col-lg-6 row h-max">
                    <div class="col col-12 col-md-6">
                        <div class="form-group my-1">
                            <label class="w-100 p-1">{{__('choose :name',{'name':__('warranty')})}}</label>
                            <div class="input-group">
                                <v-select disabled="disabled" v-model="selected_warranty" :options="warranty" index="id" class="form-control style_lang border" label="name" :placeholder="__('choose :name',{'name':__('warranty')})"></v-select>
                                <div class="input-group-prepend">
                                    <div :class="warranty_error?'input-group-text border-danger':'input-group-text'">
                                        <i class="fal fa-diploma"></i>
                                    </div>
                                </div>
                            </div>
                            <p v-if="warranty_error" class="has_error">{{ warranty_error }}</p>
                        </div>
                    </div>

                    <div class="col col-12 col-md-6" v-if="colors.length>1">
                        <div class="form-group my-1">
                            <label class="w-100 p-1">{{__('choose :name',{'name':color_type=='1'?__('color'):__('size')})}}</label>
                            <div class="input-group">
                                <v-select disabled="disabled" v-if="color_type==1" v-model="selected_color" :options="colors" index="id" class="form-control style_lang border" label="name" :placeholder="__('choose :name',{'name':__('color')})">
                                    <template slot="option" slot-scope="option">
                                        <a class="color_style" :style="'background-color:'+option.code"></a>
                                        {{ option.name }}
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <span class="color_style" :style="'background-color:'+option.code"> </span>
                                        {{ option.name }}
                                    </template>
                                </v-select>
                                    <v-select v-else disabled="disabled" v-model="selected_color" :options="colors" index="id" class="form-control style_lang border" label="name" :placeholder="__('choose :name',{'name':__('size')})">
                                        <template slot="option" slot-scope="option">
                                            <a class="color_style" :style="'background-color:'+option.code"></a>
                                            {{ option.name }}
                                        </template>
                                    </v-select>
                                    <div class="input-group-prepend">
                                    <div :class="color_error?'input-group-text border-danger':'input-group-text'">
                                        <i class="fal fa-palette"></i>
                                    </div>
                                </div>
                            </div>


                            <p v-if="color_error" class="has_error">{{ color_error }}</p>
                        </div>
                    </div>

                    <div class="col col-12 col-md-6">
                        <div class="form-group my-1">
                            <label class="w-100 p-1">{{__('product price')+' ('+defCurrency.sign+')'}}</label>
                            <div class="input-group">
                                <Cleave v-model="price1" :options="options" class="form-control to-left price_input"></Cleave>
                                <div class="input-group-prepend">
                                    <div :class="price1_error?'input-group-text border-danger':'input-group-text'">
                                        <i class="fal fa-money-bill-1-wave"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p v-if="price1_error" class="has_error">{{ price1_error }}</p>
                    </div>

                    <div class="col col-12 col-md-6">
                        <div class="form-group my-1">
                            <label class="w-100 p-1">{{__('price for sell')+' ('+defCurrency.sign+')'}}</label>
                            <div class="input-group">
                                <Cleave v-model="price2" :options="options" class="form-control to-left price_input"></Cleave>
                                <div class="input-group-prepend">
                                    <div :class="price2_error?'input-group-text border-danger':'input-group-text'">
                                        <i class="fal fa-money-check-dollar"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p v-if="price2_error" class="has_error">{{ price2_error }}</p>
                    </div>

                    <div class="col col-12 col-md-6">
                        <div class="form-group my-1">
                            <label class="w-100 p-1">{{__('product number')}}</label>
                            <div class="input-group">
                                <Cleave v-model="product_number" :options="options" class="form-control to-left price_input"></Cleave>
                                <div class="input-group-prepend">
                                    <div :class="product_number_error?'input-group-text border-danger':'input-group-text'">
                                        <i class="fal fa-input-numeric"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p v-if="product_number_error" class="has_error">{{ product_number_error }}</p>
                    </div>

                    <div class="col col-12 col-md-6">
                        <div class="form-group my-1">
                            <label class="w-100 p-1">{{__('product number cart')}}</label>
                            <div class="input-group">
                                <Cleave v-model="product_number_cart" :options="options" class="form-control to-left price_input"></Cleave>
                                <div class="input-group-prepend">
                                    <div :class="product_number_cart_error?'input-group-text border-danger':'input-group-text'">
                                        <i class="fal fa-cart-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p v-if="product_number_cart_error" class="has_error">{{ product_number_cart_error }}</p>
                    </div>

                        <div class="col col-12 col-md-6" v-if="category.send_free!='true'">
                            <div class="form-group my-1">
                                <label class="w-100 p-1">{{__('count one packet')}}</label>
                                <div class="input-group">
                                    <Cleave v-model="count_packet" :options="options" class="form-control to-left price_input"></Cleave>
                                    <div class="input-group-prepend">
                                        <div :class="count_packet_error?'input-group-text border-danger':'input-group-text'">
                                            <i class="fal fa-cart-arrow-down"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p v-if="count_packet_error" class="has_error">{{ count_packet_error }}</p>
                        </div>

                        <div class="col col-12">
                            <div :class="'form-check form-switch py-2 border-top border-bottom mt-2 '+(returnBack==true?'border-primary':'')">
                                <input class="form-check-input float-none mx-2" type="checkbox" v-model="returnBack">
                                <label :class="'form-check-label w-auto '+(returnBack==true?'text-primary':'')" >{{returnBack==false?__('not return back product'):__('return back product')}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col col-12 col-md-10 col-lg-6"  v-bind:style="show_border()?'border-right:1px solid #ccc':''" >

                        <div class="col col-12 bg-light mt-2 p-1 rounded shadow-sm" v-if="countries.length>0" v-for="(shippingCost,key) in determinedCountries" :key="key">
                        <span>
                            <i class="fa fa-xs fa-circle"></i>
                            {{ __('country :name',{'name':getCountryName(shippingCost.country_id)})}}
                        </span>
                            <div class="row">
                                <div class="form-group my-1 col col-md-6 col-12">
                                    <label class="w-100 p-1 w-100">{{ __("shipping cost")+' ('+defCurrency.sign+')'}}</label>
                                    <div class="input-group">
                                        <Cleave v-model="shippingCost.shipping_cost" v-if="category.send_free=='false'" :options="options"
                                                class="form-control to-left price_input"></Cleave>
                                        <Cleave value="0" disabled="disabled" v-else :options="options"
                                                class="form-control to-left price_input"></Cleave>
                                    </div>
                                </div>
                                <div class="form-group my-1 col col-md-6 col-12">
                                    <label class="w-100 p-1 w-100">{{ __("send time")+" ("+__('day')+")"}}</label>
                                    <div class="input-group">
                                        <Cleave v-model="shippingCost.shipping_time" :options="options"
                                                class="form-control to-left price_input"></Cleave>
                                        <div class="input-group-prepend pointer mx-1" @click="deleteFrom(key)">
                                            <div class="input-group-text bg-danger text-white">
                                                <i class="fal fa-trash"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mt-3 d-flex justify-content-center" style="max-width:450px">
                            <label class="p-1 text-white" v-if="!$parent.hasMobile">.</label>
                            <div class="input-group">
                                <b-button ref="addShippingCost" v-b-modal.sendPriceModal type="button"
                                          class="btn bg-white border-primary rounded-0 text-primary w-100 py-2">
                                    <i class="fal fa-location-plus"></i>
                                    {{ __("add country & determine shipping cost") }}
                                </b-button>
                            </div>
                        </div>

                        <div class="form-group mt-3 d-flex justify-content-center" style="max-width:450px">
                            <label class="p-1 text-white" v-if="!$parent.hasMobile">.</label>
                            <div class="input-group">
                                <b-button ref="copyShippingCost" v-b-modal.copySendPriceModal type="button"
                                          class="btn bg-white border-success rounded-0 text-success w-100 py-2">
                                    <i class="fal fa-location-pen"></i>
                                    {{ __("copy shipping cost") }}
                                </b-button>
                            </div>
                        </div>

                    </div>

                    <div class="col col-12 mt-3">
                        <div class="justify-content-center d-flex">
                        <div class="col col-12 col-md-8 col-lg-6">
                        <p v-if="warning!=false" class="mt-2 alert alert-warning text-center"> <i class="fal fa-triangle-exclamation"></i>
                            <span v-html="warning"></span>
                        </p>
                        </div>
                        </div>
                        <div class=" justify-content-center d-flex">
                        <div class="col col-12 col-md-8 col-lg-6">
                            <div class="form-group">
                                <div class="input-group">
                                    <button class="btn btn-primary w-100 py-2" v-on:click="update()"><i class="fal fa-save mx-2"></i>{{__("submit info")}}</button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                </div>
                <div v-else>
                    <p class="py-5 text-center h2">
                        <i class="far fa-exclamation-circle"></i>
                        {{ __("403 text") }}
                    </p>
                </div>
            </div>
        </div>

        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>

        <div>
            <b-modal id="sendPriceModal">
                <template #modal-title>
                    <small style="font-size:15px!important;">
                        {{ __("add country & determine shipping cost") }}
                    </small>
                </template>
                <div class="justify-content-center text-center mb-3">
                    <label style="width:300px" class="p-1">{{ __('add :field', {field: __('country')}) }}</label>
                    <v-select autocomplete="chrome-off" :name="'name'" :aria-autocomplete="'none'"
                              v-model="countryShippingCost" :options="undefinedCountries" index="id"
                              class="form-control style_lang border mw-100" label="name"
                              :placeholder="__('choose :name',{'name':__('country')})"></v-select>
                    <div class="justify-content-center d-flex mt-1">
                        <div class="form-group my-1" style="max-width:300px">
                            <label class="w-100 p-1">{{ __('shipping cost')+' ('+defCurrency.sign+')' }}</label>
                            <div>
                                <Cleave :placeholder="__('enter :field',{'field':__('shipping cost')})"
                                        v-model="priceShippingCost" :disabled="category.send_free=='true'||countryShippingCost==null" :options="options"
                                        class="form-control to-left price_input"></Cleave>
                            </div>
                            <label class="w-100 p-1">{{ __('send time') }}</label>
                            <div class="mt-1">
                                <Cleave :disabled="countryShippingCost==null" :placeholder="__('enter :field',{'field':__('send time')})"
                                        v-model="sendTimeShippingCost" :options="options"
                                        class="form-control to-left price_input"></Cleave>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert alert-danger p-1 mx-2 mt-3 mb-0" v-if="errorShippingCost">
                    <small>
                        <i class="fal fa-exclamation-circle"></i>
                        {{ errorShippingCost }}
                    </small>
                </div>
                <template #modal-footer>
                    <div class="w-100 row justify-content-between">
                        <b-button class="col-7" variant="primary" size="sm" v-on:click="addCountryPrice()">
                            <i class="fal fa-check"></i>
                            {{ __("confirmation") }}
                        </b-button>
                        <b-button  @click="countryShippingCost=null;$bvModal.hide('sendPriceModal')" class="col-4"
                                   variant="secondary" size="sm">
                            {{ __("cancel") }}
                        </b-button>
                    </div>
                </template>

            </b-modal>
            <b-modal id="copySendPriceModal">
                <template #modal-title>
                    <small style="font-size:15px!important;">
                        {{ __("copy shipping cost") }}
                    </small>
                </template>
                <div class="justify-content-center text-center ">
                    <div class="px-1 py-2  position-relative" >
                        <input @input="debounceSearch" v-model="searchText" type="text" class="w-100 rounded px-2 pt-1 border-1 border-primary text-bold" :placeholder="__('search')" style="outline:none" />
                        <div class="text-center mt-1 text-primary" v-if="isSearching">
                            <i class=" mx-1 fal fa-pulse ">O</i>
                            <i class=" mx-1 fal fa-pulse ">O</i>
                            <i class=" mx-1 fal fa-pulse ">O</i>
                        </div>
                    </div>

                    <table class="table table-bordered table-responsive table-striped copyShippingTable">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{{ __('product') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(pw,key) in similarShippingCost" :key="key" @click="selectedSimilarShippingCost=pw" :class="selectedSimilarShippingCost!=null&&selectedSimilarShippingCost.id==pw.id?'selected':''" >
                            <td class="text-center">{{key+1}}</td>
                            <td style="max-width:90%" class="text-truncate text-center">{{_(pw.product.title)}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="text-center" v-if="!similarShippingCost||similarShippingCost.length<1">
                        {{ __('not found products') }}
                    </div>
                </div>
                <template #modal-footer>
                    <div class="w-100 row justify-content-between">
                        <b-button class="col-7" variant="primary" size="sm" v-on:click="pasteCountryPrice()"  :disabled="selectedSimilarShippingCost==null" >
                            <i class="fal fa-check"></i>
                            {{ __("confirmation") }}
                        </b-button>
                        <b-button @click="selectedSimilarShippingCost=null;$bvModal.hide('copySendPriceModal')" class="col-4"
                                  variant="secondary" size="sm">
                            {{ __("cancel") }}
                        </b-button>
                    </div>
                </template>

            </b-modal>
        </div>

    </div>
</template>

<script>
import myMixin from "@/myMixin";
import Cleave from 'vue-cleave-component';
import {debounce} from "lodash";
export default {
    name: "UpdateProductWarranty",
    components: {Cleave},
    mixins: [myMixin],
    data() {
        return {
            account_status: 'active',
            showLoading: false,
            BreadCrumbList: [
            ],
            title:'',
            server_error:false,
            colors:[],
            warranty:[],
            selected_warranty:0,
            warranty_error:false,
            selected_color:0,
            color_error:false,
            options:{
                delimiter:',',
                numeral:true,
                numeralDecimalScale:3
            },
            price1:'',
            price1_error:false,
            price2:'',
            price2_error:false,
            product_number:'',
            product_number_error:false,
            product_number_cart_error:false,
            product_number_cart:'',
            count_packet:'1',
            count_packet_error:false,
            send_form:true,
            warning:false,
            color_type:1,
            countries:[],
            determinedCountries:[],
            countryShippingCost:null,
            priceShippingCost:0,
            sendTimeShippingCost:'',
            errorShippingCost:false,
            undefinedCountries:[],
            returnBack:false,
            commission:{fix:0,percentage:0},
            category:{},
            similarShippingCost:{},
            selectedSimilarShippingCost:null,
            baseSimilarShippingCost:null,
            searchText:null,
            isSearching:false,
            oldSearchText :null

        }
    }
    , mounted() {
        this.getCountries();
        this.getWarrantyData(this.$route.params.product_id,this.$route.params.id,"determinedCountries");
        this.account_status = localStorage.getItem('account_status');
        this.BreadCrumbList=[
            {path:"/"+this.locale+'/products/warranty/'+(this.$route.params.product_id),title:this.__('manage :name',{'name':this.__('product warranties')})},
            {path:"/"+this.locale+'/products/warranty/'+(this.$route.params.product_id)+"/edit/"+this.$route.params.id,title:this.__('edit :name',{'name':this.__('product warranty')})},
        ];
        this.$emit('setSideBarItem','products');
    },
    methods:{
        update(){
            if(this.send_form==false)
                return ;
            this.send_form=false;
            const app=this;
            const checkWarranty=this.validate_field(this.__('warranty'),this.selected_warranty.id,'warranty_error',1)
            let checkColor=false;
            if(this.colors.length>0)
                checkColor=this.validate_field(this.__('color'),this.selected_color.id,'color_error',1);
            else
                checkColor=true;

            const checkPrice1=this.validate_field(this.__('product price'),this.price1,'price1_error',1)
            const checkPrice2=this.validate_field(this.__('price for sell'),this.price2,'price2_error',1)
            const product_number=this.validate_field(this.__('product number'),this.product_number,'product_number_error',1)
            const product_number_cart=this.validate_field(this.__('product number cart'),this.product_number_cart,'product_number_cart_error',1)
            const checkCountPacket=this.validate_field(this.__('count one packet'),this.count_packet,'count_packet_error',1)
            const shippingCost= this.validateShippingCost();
            if(checkWarranty&& checkColor&& checkPrice1&& checkPrice2&& product_number && product_number_cart && shippingCost && checkCountPacket){
                const formData=new FormData();
                formData.append('product_id',this.$route.params.product_id);
                formData.append('price1',this.price1);
                formData.append('price2',this.price2);
                formData.append('product_number',this.product_number);
                formData.append('product_number_cart',this.product_number_cart);
                formData.append('return_back',this.returnBack);
                formData.append('count_one_packet',this.count_packet);
                for (const key in this.determinedCountries) {
                    const data = this.determinedCountries[key];
                    formData.append('shippingCost[' + data['country_id'] + ']', data['shipping_cost'])
                    formData.append('shippingTime[' + data['country_id'] + ']', data['shipping_time'])
                }
                formData.append('_method','PUT');
                const url=this.host+"api/seller/getWarrantyData/add/"+this.$route.params.id+"/update";
                this.axios.post(url,formData,this.setHeader()).then(response=>{
                    if(this.setAccountStatus(response.data)){
                        if(response.data=='ok'){
                            this.$router.push({name:'product_warranty',params:{success:'ok',id:this.$route.params.product_id,'message':this.__('success :action :name',{'action':this.__('edit'),'name':this.__('product warranty')})}});
                        }else if(response.data=="repetitive"){
                            this.warning=this.__('price variation with these selected specifications has already been recorded');
                        }else{
                            this.show_server_error();
                        }
                    }
                    this.send_form=true
                }).catch(err=>{
                    const data=err.response;
                    app.send_form = true
                    if (data.status == 422) {
                        app.warning=app.__('errored')+"<br>";
                        let i=1;
                        for (const errorsKey in data.data.errors) {
                            if(!app.warning.toString().includes(data.data.errors[errorsKey])){
                                app.warning+=i+"- "+data.data.errors[errorsKey]+"<br>";
                                i++;
                            }
                        }
                        return "";
                    }
                    app.setCatch(err);
                })

            }
            else{
                this.send_form = true
            }
        },
        getCountryName(id){
            const result= this.findNestedObj(this.countries,'id',id,['name'])
            if(result.name!=undefined)
                return result.name;
        },
        deleteFrom(key){
            this.$delete(this.determinedCountries,key);
            this.getUndefinedCountries();
        },
        show_border(){
            if(window.innerWidth>990){
                return true;
            }
            return false
        },
        getUndefinedCountries(){
            this.undefinedCountries=[];
            for (const country of this.countries) {
                let has=true;
                for (const shippingCost of this.determinedCountries) {
                    if(shippingCost.country_id==country.id){
                        has=false;
                        break;
                    }
                }
                if(has==true){
                    this.undefinedCountries.push(country);
                }

            }
            this.$refs.addShippingCost.style.display='block'
            if(this.undefinedCountries.length==0)
                this.$refs.addShippingCost.style.display='none'

        },
        validateShippingCost(){
            this.warning=false;
            if(this.determinedCountries.length>0){
                for (const shippingCost of this.determinedCountries) {
                    const countryName=this.__('country :name',{name:this.getCountryName(shippingCost.country_id)});
                    if(shippingCost.shipping_cost<0||shippingCost.shipping_cost===null||shippingCost.shipping_cost===''){
                        this.warning=this.__('v.gte.numeric',{attribute:countryName +" | "+this.__('shipping cost'),value:0});
                        return false;
                    }
                    if(shippingCost.shipping_time<0||shippingCost.shipping_time===null||shippingCost.shipping_time==''){
                        this.warning=this.__('v.gte.numeric',{attribute:countryName +" | "+this.__('send time'),value:0});
                        return false;
                    }
                }
                return true;
            }
            this.warning=this.__('not submit without shipping cost');
            return false;
        },
        addCountryPrice(){
            const app=this;
            this.errorShippingCost=false;
            const found = Object.keys(app.countries).filter(function(key) {
                return app.countries[key].id === app.countryShippingCost.id;
            });
            if (found.length==0) {
                return this.errorShippingCost=this.__('not choose :name',{name:this.__('country')});
            }
            if((this.priceShippingCost).toString().trim().length==0){
                return this.errorShippingCost=this.__('v.gte.numeric',{attribute:this.__('shipping cost'),value:'0'});
            }
            if((this.sendTimeShippingCost).toString().trim().length==0){
                return this.errorShippingCost=this.__('v.gte.numeric',{attribute:this.__('send time'),value:'0'});
            }
            this.determinedCountries.push({
                id:null,
                product_warranty_id:null,
                country_id:this.countryShippingCost.id,
                shipping_cost:this.priceShippingCost,
                shipping_time:this.sendTimeShippingCost,
                country:{name:this.getCountryName(this.countryShippingCost.id),id:this.countryShippingCost.id}
            });
            this.getUndefinedCountries();
            this.priceShippingCost=0;
            this.countryShippingCost=null;
            this.sendTimeShippingCost='';
            this.$bvModal.hide('sendPriceModal')
        },
        pasteCountryPrice(){
            this.determinedCountries=this.selectedSimilarShippingCost.shipping_cost;
            this.selectedSimilarShippingCost=null;
            this.$bvModal.hide('copySendPriceModal')
        },
        debounceSearch(){
            const self=this;
            if(self.baseSimilarShippingCost!=null&&self.searchText.toString().trim().length<2){
                self.similarShippingCost=self.baseSimilarShippingCost;
            }
            if(self.baseSimilarShippingCost==null){
                self.baseSimilarShippingCost=self.similarShippingCost;
            }
             debounce(()=> {
                const url = this.host + "api/seller/getWarrantyData/search/" + self.searchText;
                 if(self.searchText.toString().trim().length<2)
                   return self.baseSimilarShippingCost!=null?self.similarShippingCost=self.baseSimilarShippingCost:null;
                 if(self.oldSearchText==self.searchText)
                     return
                self.isSearching=true
                self.oldSearchText=self.searchText;
                self.axios.get(url,self.setHeader()).then(response => {
                    if(response.data.status=="ok"){
                        self.similarShippingCost=response.data.data
                    }
                    self.isSearching=false

                })

            }, 3000)();
        }


    },
    watch:{
        countries(){
            this.getUndefinedCountries();
        }
    }
}
</script>

<style scoped>

</style>
