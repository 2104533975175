<template>
    <div>
        <div class="modal-backdrop fade show"></div>
        <div class="modal fade product_list show d-block" id="product_list" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="text-center py-2 alert-info align-items-center d-flex justify-content-between">
                        <span></span>
                       <span>
                        <i class="fal fa-search"></i> {{__("search in shop products")}}
                           </span>
                        <button type="button" class="close btn rounded bg-white mx-2"  data-dismiss="modal" aria-label="Close" v-on:click="close()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-header">
                        <div class="box_border">
                            <div class="input_div">
                                <input type="text" class="form-control" v-model="search_text" :placeholder="__(':name name',{'name':__('product')})" />
                                <a class="btn btn-primary" v-on:click="getProduct(1)">{{__("search")}}</a>
                            </div>
                        </div>


                    </div>
                    <div class="modal-body p-0">

                        <div class="loading_box2" v-if="showLoading">
                            <div class="spinner">
                                <div class="b1"></div>
                                <div class="b2"></div>
                                <div class="b3"></div>
                            </div>
                        </div>

                        <div class="table table-bordered">
                            <div class="row border-bottom align-items-center" v-for="(product,key) in productList.data" :key="key" :ref="'row_'+key">
                                <div class="text-center col-lg-2 col-6 d-flex align-items-center">
                                    {{getRow(key)}}-
                                    <img :src="product.thumbPath" class="product_pic stockroom_img_pic">
                                </div>
                                <div class="text-center col-lg-4 col-6">
                                    <ul class="mb-0">
                                        <li>
                                            {{_(product.title)}}
                                        </li>
                                        <li>
                                            {{__(':name count',{'name':__('seller')}) +" : "+ product.active_sellers_count}}
                                        </li>
                                        <li>
                                            {{__(':name count',{'name':__('product warranty')}) +" : "+ product.active_warranties_count}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="text-center col-lg-3 col-6">
                                    <div v-if="product.active_warranties_count>0">
                                        <span>{{__("lowest :field",{'field':__('price')})}} : </span>
                                        <span>{{ priceFormat({},product.price)}}</span>
                                    </div>
                                    <div v-else style="opacity:0">not active warranty</div>
                                </div>
                                <div class="col-lg-2 col-6" >
                                    <button class="btn btn-success bg-success btn-i-sell text-nowrap" v-on:click="add(product.id)">{{__('i sell')}}</button>
                                </div>

                            </div>
                        </div>
                        <pagination :data="productList" @pagination-change-page="getProduct"></pagination>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import myMixin from "@/myMixin";

export default {
name: "TotalProductList",
    mixins:[myMixin],
    data(){
    return {
        productList:{data:[]},
        showLoading:true,
        search_text:'',
        page:1,
        perPage:10
    }
    },
    mounted() {
        this.getProduct()
    }
    ,methods:{
        getProduct(page=1){
            const app=this;
            this.page=page;
            this.showLoading=true
            const url=this.host+"api/seller/total_product?page="+page+'&search_text='+this.search_text;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    this.perPage=response.data.per_page
                    this.productList=response.data;
                }
            }).catch(err=>{
                app.showLoading=false;
                app.setCatch(err)
            })
        },
        add(pId){
            this.$router.push({name:'add_product_warranty',params:{id:pId}})
        },close(){
            this.$parent.close_modal();
        }
    }
}
</script>

<style scoped>

</style>
