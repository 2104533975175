<template>
    <div>
        <loading v-if="showLoading"></loading>
        <breadcrumb :data="BreadcrumbList"></breadcrumb>
        <account-status :account_status="account_status"></account-status>

        <div class="panel">

            <div class="header">
                {{__('manage :name',{'name':__('messages')})}}
                <div class="dropdown">
                    <button  class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" >
                        {{__('options')}}
                    </button>
                    <ul  class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li>
                            <router-link :to="'/'+this.locale+'/messages/add'" class="dropdown-item" >
                                <span class="btn btn-sm btn-outline-success rounded-circle"><i class="fal fa-add"></i></span>
                                {{__("add new :name",{'name':__('message')})}}
                            </router-link>
                        </li>

                    </ul>
                </div>


            </div>
            <div class="panel_content">
                <form method="get" class="row search_form form-inline col-md-8 col-lg-5 col-sm-12 col-12" >
                    <h6>{{__('search part')}}</h6>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" :placeholder="__('title :name',{name:__('message')})" v-model="search_title"  aria-label="Example text with button addon" aria-describedby="button-addon1">
                        <button class="btn btn-primary rounded-0" type="button" id="button-addon1" v-on:click="getMessageList(1)">{{__("search")}}</button>
                    </div>

                </form>

                <div v-if="show_alert_message" class="alert alert-success alert-dismissible fade show mb-1" role="alert">
                    <span>{{alert_message}}</span>
                    <button type="button" class="btn-close " data-bs-dismiss="alert" aria-label="Close"></button>
                </div>

                <table class="table table-bordered table-striped" >
                    <thead>
                    <tr v-if="$parent.hasMobile">
                        <th><small>#</small></th>
                        <th><small>{{__("detail")}}</small></th>
                        <th><small>{{__("operation")}}</small></th>
                    </tr>
                    <tr v-else>
                        <th>#</th>
                        <th>{{__("title")}}</th>
                        <th>{{__("sender")}}</th>
                        <th>{{__("receiver")}}</th>
                        <th>{{__("time")}}</th>
                        <th>{{__("operation")}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(message,key) in MessageList.data" :key="message.id" v-if="$parent.hasMobile">
                        <td> {{getRow(key,perPage)}} </td>
                        <td>
                            <div class="max-1line">
                                <div>{{__("title :name",{name:__('message')})+": "+message.title}}</div>
                                <div class="d-flex my-1">
                                    {{ __("sender") + ": " }}
                                    <span v-if="message.from!=null">
                                <span v-if="message.from_type=='App\\Models\\User'" >
                                    {{ __("shop name") }}
                                </span>
                                <span v-else >
                                    {{ message.from.brand_name }}
                                </span>

                            </span>
                                    <span  v-else>{{ __("shop name") }}</span>
                                </div>
                                <div>
                                    {{__("time")+": "+new Date(message.created_at).toDateString()}}
                                </div>
                            </div>
                            <div class="more-1line mt-1" v-if="$parent.hasMobile" :data-more="__('more')" :data-less="__('read less')">
                                <i class="fal fa-chevron-circle-up"></i> <span>{{__("more")}}</span>
                            </div>

                        </td>
                        <td class="text-center">
                            <i :class="'fal fa-eye pointer '+(message.status==-1?'text-danger':'')" v-on:click="$router.push({name:'message-content',params:{id:message.id}})"></i>
                        </td>
                    </tr>
                    <tr v-for="(message,key) in MessageList.data" :key="message.id" v-if="!$parent.hasMobile">
                        <td class="text-center">  {{getRow(key,perPage)}} </td>
                        <td class="text-center">  {{message.title}} </td>
                        <td class="text-center">
                            <span v-if="message.from!=null">
                                <span v-if="message.from_type=='App\\Models\\User'" class="from_link">
                                    {{__("shop name")}}
                                </span>
                                <span v-else class="from_link">
                                    {{message.from.brand_name}}
                                </span>

                            </span>
                            <span class="from_link" v-else>{{__("shop name")}}</span>
                        </td>

                        <td class="text-center">
                            <span v-if="message.to!=null">
                                <span v-if="message.to_type=='App\\Models\\User'" class="to_link">
                                    {{__("shop name")}}
                                </span>
                                <span v-else class="to_link">
                                    {{message.to.brand_name}}
                                </span>
                            </span>
                            <span class="from_link" v-else>{{__("shop name")}}</span>
                        </td>

                        <td class="text-center">{{new Date(message.created_at).toDateString()}}</td>
                        <td class="text-center">
                            <i :class="'fal fa-eye pointer '+(message.status==-1?'text-danger':'')" v-on:click="$router.push({name:'message-content',params:{id:message.id}})"></i>
                        </td>
                    </tr>

                    </tbody>
                </table>
                <tr v-if="MessageList.data.length==0" class="d-block w-100">
                    <p class="alert alert-info"><i class="fa fa-exclamation-triangle mx-2"></i>{{__("There is no record to display")}}</p>
                </tr>


                <pagination :data="MessageList" @pagination-change-page="getMessageList"></pagination>
            </div>
        </div>

        <ServerErrorMessage v-if="server_error"></ServerErrorMessage>
    </div>
</template>

<script>

import myMixin from "@/myMixin";
import 'bootstrap/dist/js/bootstrap'
export default {
    name: "MessageList",
    mixins:[myMixin],
    data(){
        return {
            showLoading:true,
            MessageList:{data:[]},
            BreadcrumbList:[],
            account_status:'active',
            page:1,
            perPage:10,
            seller_id:-1,
            showMessageDialog:false,
            server_error:false,
            show_alert_message:false,
            alert_message:'',
            search_title:'',
            showOptions:false
        }
    },
    mounted() {
        this.account_status=localStorage.getItem('account_status')
        this.seller_id=localStorage.getItem('seller_id')
        if(this.$route.params.success=="ok"){
            this.show_alert_message=true;
            this.alert_message=this.__('sent')
            if(this.$route.params.message!=undefined)
                this.alert_message=this.$route.params.message

        }
        this.getMessageList();
        this.$emit('setSideBarItem','messages')
        this.BreadCrumbList=[
            {path:this.locale+'/products',title:this.__('manage :name',{'name':this.__('messages')})},
        ];

    },methods:{
        getMessageList(page=1){
            const app=this;
            this.showLoading=true;
            this.page=page;
            const url=this.host+"api/seller/messages/list?page="+page+"&title="+this.search_title;
            this.axios.get(url,this.setHeader()).then(response=>{
                this.showLoading=false;
                if(this.setAccountStatus(response.data)){
                    this.perPage=response.data.per_page?response.data.per_page:this.perPage;
                    this.MessageList=response.data;
                }
            }).catch(err=>{
                app.showLoading=false;
                if(err.response!=undefined && err.response.status==401){
                    app.logout();
                }else{
                    app.show_server_error();
                }
            })
        }

    }
}
</script>

<style scoped>

</style>
