export default {
    methods:{
        validate_email(email) {
            const reg = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
            const result = reg.test(email);
            if (result == false) {
                this.email_error = this.__('v.email', {'attribute': ''})
            } else {
                this.email_error = "";
            }
            return result;
        },
        validate_password(password) {
            password = "" + password;
            if (password.toString().trim().length > 7 && password != undefined) {
                this.password_error = "";
                return true;
            }
            this.password_error = this.__('v.gt.string', {'attribute': this.__('password'), 'value': 7})
            return false;
        },
        validate_mobile(mobile) {
            if (mobile == "") {
                this.mobile_error = this.__('v.required', {'attribute': this.__('mobile')});
                return false;
            }
            let mo=mobile.trim().replace('+','00')
            if(isNaN(parseInt(mo))){
                this.mobile_error = this.__('v.not_regex', {'attribute': this.__('mobile')});
                return false;
            }
            if(this.mobile)
                this.mobile=mo;
            this.mobile_error = "";
            return true;
        },
        getCountries() {
            this.axios.get(this.host + 'api/get_countries').then(response => {
                this.countries = response.data;

            }).catch(function () {
                this.server_error = true;
                this.error_message = this.__('errored')
            })
        },
        get_city() {
            if (parseInt(this.country_id) > 0) {
                this.axios.get(this.host + 'api/get_cities/' + this.country_id).then(response => {
                    this.cities = response.data;
                }).catch(function () {
                    this.server_error = true;
                    this.error_message = this.__('errored')
                })
            }

        },
        validate_field(label, field_name, field_error, field_length) {

            const len = (field_name + "").trim().length;
            if (len == 0 || field_name == undefined) {
                const text = this.__('v.required', {'attribute': label});
                this[field_error] = text;
            } else if (field_name != undefined && len < field_length) {
                const text = this.__('v.gt.string', {'attribute': label, 'value': field_length});
                this[field_error] = text;
            } else {
                this[field_error] = false;
                return true
            }
            return false;
        },
        logout(msg=null) {
            localStorage.removeItem('token');
            localStorage.removeItem('expire_in');
            localStorage.removeItem('brand_name');
            this.$router.push({name: 'login',params:{msg:msg}});
        },
        show_server_error() {
            this.server_error = true;
            const app = this;
            setTimeout(function () {
                app.server_error = false;
            }, 5000)
        },
        getSellerInfo() {
            const url = this.host + "api/seller/profile"
            this.axios.get(url,this.setHeader()).then(response => {
                this.show_loading = false;
                this.showLoading=false
                if (response.data.seller!=undefined&&response.data.seller.account_status != undefined) {
                    localStorage.setItem('account_status', response.data.seller.account_status);
                    localStorage.setItem('brand_name', response.data.seller.brand_name);
                    this.brand_name = response.data.seller.brand_name;
                    localStorage.setItem('seller_id',response.data.seller.id)
                    localStorage.setItem('bank_status',response.data.bank_status);
                }
            }).catch(() => {
                this.getSellerInfo();
            })
        },
        getCatList() {
            const url = this.host + "api/seller/category"
            this.axios.get(url, this.setHeader()).then(response => {
                this.show_loading = false;
                    if(this.setAccountStatus(response.data)){
                        const result = this.exportCatChilds(response.data, [], ".")
                        this.catList = result;
                        for (const cat of result) {
                            if(cat.child.length <1){
                                this.cat =cat;
                                break;
                            }
                        }
                    }
            }).catch((err) => {
                this.show_loading = false;
                if (err.response != undefined && err.response.status == 401) {
                    this.logout();
                } else {
                    this.show_server_error();
                }
            })
        },
        getBrand() {

            const url = this.host + "api/seller/brand";
            this.axios.get(url,this.setHeader()).then(response => {
                this.show_loading = false;
                if(this.setAccountStatus(response.data)){
                    this.brandList = this.findTrans(response.data, "name");
                    this.brand = response.data[0];
                }
            }).catch((err) => {
                this.show_loading = false;
                if (err.response.status == 401) {
                    this.logout();
                } else {
                    this.show_server_error();
                }
            })

        },
        getColor() {
            const url = this.host + "api/seller/color"
            this.axios.get(url, this.setHeader()).then(response => {
                this.show_loading = false;
                if(this.setAccountStatus(response.data)){
                    this.colorList = this.findTrans(response.data, "name");
                }
            }).catch((err) => {
                console.log(err)
                this.show_loading = false;
                if (err.response.status == 401) {
                    this.logout();
                } else {
                    this.show_server_error();
                }
            })
        },
        getLanguage() {
            const url = this.host + "api/seller/language"
            this.axios.get(url,this.setHeader()).then(response => {
                this.show_loading = false;
                if(this.setAccountStatus(response.data)){
                        this.languages = response.data;
                }
            }).catch((err) => {
                this.show_loading = false;
                if (err.response.status == 401) {
                    this.logout();
                } else {
                    this.show_server_error();
                }
            })
        },
        findTrans(obj, column,column2) {

            for (const item in obj) {
                if(column2==undefined){
                    obj[item][column] = this._(obj[item][column])
                }
                else
                    obj[item][column][column2] = this._(obj[item][column][column2])
            }
            return obj;
        },
        exportCatChilds(categories, obj = [], sign, tf = true) {
            const app = this;
            for (const index in categories) {
                const child = categories[index];
                child.name = (tf == true ? "" : sign) + " " + this._(categories[index].name);
                obj.push(child)
                if (child.child != undefined && child.child.length > 0) {
                    app.exportCatChilds(child.child, obj, sign + sign, false)
                }
            }
            return obj;
        },
        checkLenObj(obj, minLen = 2, maxLen = 65) {
            let size = 0;
            for (const objKey in obj) {
                const len = (obj[objKey] + "").toString().trim().length;
                if (len > minLen && len < maxLen) {
                    return 1; // is true
                }
                if (len > size)
                    size = len;
            }
            if (size != 0)
                return 2; // is false and between len min and max
            return 0; //is false and required
        },
        validateTitle() {
            const res = this.checkLenObj(this.title,5,100);
            this.title=Object.assign({},this.title)
            if (res != 1) {
                if (res == 0)
                    this.errors.push(this.__('v.required', {'attribute': this.__('title')}));
                else
                    this.errors.push(this.__('v.between.string', {
                        'attribute': this.__('title'),
                        'min':5,
                        'max': 100
                    }));
                return false
            }
            return true;
        },
        validateWeight(){
            let result=false;
            const split= (this.weight+"").split(".");
            let err="v.numeric";
            if(this.weight>0 &&this.is_number(this.weight)){
                result=true;
            }
            if(split[1]!=undefined&&split[1].length>3){
                result=false;
                err="v.lte.float";
            }
            if(result==true)
                return true;

            if(this.weight<=0)
                err="v.required";
            this.errors.push(this.__(err, {
                'attribute': this.__('product weight'),
                'value':'3'
            }));
            return false
        },
        validateSubTitle() {
            const res = this.checkLenObj(this.subtitle,5,100);
            this.subtitle=Object.assign({},this.subtitle)
            if (res != 1) {
                if (res == 0)
                    this.errors.push(this.__('v.required', {'attribute': this.__('subtitle')}));
                else
                    this.errors.push(this.__('v.between.string', {
                        'attribute': this.__('subtitle'),
                        'min': 5,
                        'max': 100
                    }));
                return false
            }
            return true;
        },
        validateContent() {
            const res = this.checkLenObj(this.content, 20, 4000);
            this.content=Object.assign({},this.content)
            if (res != 1) {
                if (res == 0)
                    this.errors.push(this.__('v.required', {'attribute': this.__('description')}));
                else
                    this.errors.push(this.__('v.between.string', {
                        'attribute': this.__('description'),
                        'min': 20,
                        'max': 4000
                    }));
                return false
            }
            return true;
        },
        validateCatId() {
            if (this.catId > 0)
                return true;
            this.errors.push(this.__('v.exists', {'attribute': this.__('category')}));
            return false;
        },
        validateBrandId() {
                if (this.brandId > 0)
                    return true;
                this.errors.push(this.__('v.exists', {'attribute': this.__('brand')}));
                return false;
        },
        validateColorsId() {
            if (typeof this.colorsId == "object")
                return true;
            this.errors.push(this.__('v.exists', {'attribute': this.__('colors')}));
            return false;
        },
        validateImage() {
            if (this.image == '') {
                this.errors.push(this.__('v.required', {'attribute': this.__('product image')}));
                return false
            } else if (this.image.size >5000000) {
                this.errors.push(this.__('v.lte.file.mg', {'attribute': this.__('product image'), 'value':5}));
                return false;
            }
            return true;
        },
        setAccountStatus(data){
            if(data.error_access!=undefined && data.error_access=='ok'){
                this.account_status=data.account_status;
                localStorage.setItem('account_status',data.account_status);
                return false;
            }else{
                this.account_status='active';
                localStorage.setItem('account_status','active');
                return true;
            }
        },
        getRow(n){
            let row=n+1;
            row=row+(this.page-1)*this.perPage;
            return row;
        },
        getRowDesc(n,perPage,total){
            return total-(n+((this.page-1)*this.perPage));
        },
        findNestedObj(entireObj, keyToFind, valToFind,keys=[]) {
            for (const entireObjKey in entireObj) {
                if(entireObj[entireObjKey][keyToFind]==valToFind){
                    let obj=entireObj[entireObjKey];
                    for (const i in keys) {
                        if(i==0)
                            obj={};
                        obj[keys[i]]=entireObj[entireObjKey][keys[i]]
                    }
                    return obj;
                }
            }
            return entireObj[0]? entireObj[0]:{};
        },
        getWarrantyData(pId,w_id,shippingCost){
            this.show_loading = true;
            let url = this.host + "api/seller/getWarrantyData/"+pId;
            if(w_id!=undefined){
                url+="?warranty_id="+w_id;
            }
            this.axios.get(url,this.setHeader()).then(response => {
                this.show_loading = false;
                if(response.data=="redirect"){
                    return this.$router.push({name:'product_warranty',id:pId,params:{id:pId,error:this.__('wait product state')}});
                }else{
                    this.title=response.data.title;
                    this.warranty=this.findTrans( response.data.warranty,'name');
                    if(response.data.color.length>0){
                        this.colors=this.findTrans( response.data.color,"name");
                        this.color_type=response.data.color[0].type;
                    }
                    if(response.data.productWarranty!=undefined){
                        const productWarranty=response.data.productWarranty;
                        this.price1=productWarranty.price1;
                        this.price2=productWarranty.price2;
                        this.product_number=productWarranty.product_number;
                        this.product_number_cart=productWarranty.product_number_cart;
                        this.send_time=productWarranty.send_time;
                        this.returnBack=productWarranty.return_back=="true"?true:false;
                        this.selected_warranty=this.findNestedObj(this.warranty,"id",productWarranty.warranty_id);
                        this.selected_color=this.findNestedObj(this.colors,"id",productWarranty.color_id);
                        if(response.data.color.length>0){
                            if(this.colors[0].code==null){
                                this.color_type=2
                            }
                        }
                    }
                    if(response.data.shippingCost!=undefined){
                        this[shippingCost]=response.data.shippingCost;
                        this.getUndefinedCountries();
                    }
                    if(response.data.commission!=undefined){
                        this.commission=response.data.commission;
                    }
                    if(response.data.category!=undefined){
                        this.category=response.data.category;
                        if(this.category.send_free!='true'&&response.data.productWarranty!=undefined)
                            this.count_packet=response.data.productWarranty.count_one_packet;
                    }
                    if(response.data.similarShippingCost!=undefined){
                        this.similarShippingCost=response.data.similarShippingCost;
                    }
                }
            }).catch((err) => {
                console.log(err)
                this.show_loading = false;
                if(err.response){
                    if (err.response.status == 401) {
                        this.logout();
                    } else {
                        this.show_server_error();
                    }
                }else{
                    this.show_server_error();
                }
            })
        },
        setCatch(err){
            this.show_loading=false;
            this.showLoading=false;
            if(err.response){
                if (err.response.status == 401) {
                    this.logout();
                } else {
                    this.show_server_error();
                }
            }else{
                this.show_server_error();
            }
        },
        packet_status(status){
            if(status==0)
                return this.__('ready to send');
            if(status==1)
                return this.__('sent');
            if(status==-1)
                return this.__('not sent');
            if(status==-2)
                return this.__('incomplete sent');
        },
        is_number(input) {
            if (input === '')
                return false;
            let regex = new RegExp(/^[+-]?\d+(\.\d+)?$/, 'g');
            return (String(input).match(regex) !== null);
        }
    }
}

