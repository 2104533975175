<template>
  <div id="app">

      <router-view></router-view>
  </div>
</template>

<script>
/* global $*/
export default {
  name: 'App',
    data(){
      return {
        learns:[]

      }
    },
    mounted() {
        this.getConfig();

    },
    methods:{

    },
    watch:{
      $route(){
          if($('.active_li').length>0)
           document.querySelector('.active_li').classList.remove('active_li')
          if(document.querySelector('.child_menu'))
           document.querySelector('.child_menu').style.display = 'none';
      }
    }


}
</script>


